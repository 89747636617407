import { useEffect, useState } from "react";
import Header from "./components/Home/Header";
import Container from "./components/Container";
import TopHeader from "./components/Home/TopHeader";
import Footer from "./components/Home/Footer";
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";
import HomePage from "./components/Home/HomeIndex";
import PersonaIndex from "./components/Persona/PersonaIndex";
import DetailIndex from "./components/ReceipeDetail/DetailIndex";
// import Cart from "./components/ReceipeDetail/Cart";
import ShopScreens from "./components/Shop/ShopScreens";
import ProductPage from "./components/ShopDetail/productPage";
import BlogPage from "./components/Blog/blogPage";
import ProfilePage from "./components/Profile/profileIndex";
import Blog from "./components/Blog/blog";
import Aboutus from "./components/AboutUs/Aboutus";
import PrivacyPolicy from "./components/CompanyFooter/PrivacyPolicy";
import OurStory from "./components/OurStory/OurStory";
import BlogPost from "./components/Blog/blogPost";
import ShopExplore from "./components/Shop/shopExplore";
import PricingPlans from "./components/Subscription/Subscription";
import SearchBar from "./components/Home/SeachBar";
import DeliveryDetails from "./components/Profile/Order/orderDetails";
import RefundScreens from "./components/Profile/Order/orderCancellation";
import ProtectedRoute from "./components/ProtectedRoute";
import ChristmasSpecial from "./components/Home/ChristmasSpecial";
import shopviewall from "./components/Shop/shopExplore";
import IngredientShop from "./components/Shop/shopExplore";
import ShippingPolicy from "./components/CompanyFooter/ShippingPolicy";
import CancellationRefunds from "./components/CompanyFooter/CancellationRefunds";
import TermsConditions from "./components/CompanyFooter/TermsConditions";
import OurStoryTwo from "./components/OurStory/ourstorytwo";
import OrderConfirmation from "./components/CheckOut/OrderConfirmation";

import DeliveryChecker from "./components/OnBoarding/subs";
import PreferencesPage from "./components/OnBoarding/onb2";
import SignupPage from "./components/OnBoarding/onb3";
import CheckoutForm from "./components/OnBoarding/onb4";
import PaymentMethod from "./components/OnBoarding/onb5Payment";
import MealPlanner from "./components/OnBoarding/onb6";
import DeliveryPopupFlow from "./components/OnBoarding/onb7popups";

import PlanSettings from "./components/OnBoarding/Plansettings";
// import MealPlanSelector from "../../Web/src/components/OnBoarding/mealselection";

import india from "./assets/images/india.png";
import uk from "./assets/images/uk.png";
import { getGuestUserId, injectClarityScript } from "./utils/common";

import ContactUs from "./components/OurStory/ContactUs";
import ConfirmCancelPopup from "./components/Profile/Order/orderCancelledConfirm";
import VendorExplore from "./components/Vendor/VendorExplore";
import RecipeCuisine from "./components/ReceipeDetail/RecipeCuisine";
import AI from "./components/CompanyFooter/AI";
import ShopCuisineRedirect from "./components/Shop/ShopCuisineRedirect";

import MiniCart from "./components/ReceipeDetail/Cart_2/MiniCart";
import CartPage from "./components/ReceipeDetail/Cart_2/CartRework";
import CartCheckoutPage from "./components/ReceipeDetail/Cart_2/CartCheckout";


import { HelmetProvider } from "react-helmet-async";
import Canonical from "./components/Canonical";
import ErrorPage from "./components/Home/404ErrorPage";

interface Country {
  name: string;
  flag: string;
}

const AppContainer = () => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const location = useLocation();

  const countries: Country[] = [
    { name: "UK", flag: uk },
    { name: "India", flag: india },
  ];

  const staticFiles = new Set(["/.well-known/assetlinks.json"]);
  const [clickedCountry, setClickedCountry] = useState<string | null>();
  const [countryCode, setCountryCode] = useState<string | null>();
  const storedCountry = localStorage.getItem("selectedCountry");
  const storedCode = localStorage.getItem("countryCode");
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(
    countries[0]
  );

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    // if(storedCode==null && storedCountry==null){
    //   const hostname = window.location.hostname;
    //   if (hostname.endsWith('.in')) {
    //     setSelectedCountry(countries.find((country) => country.name === 'India') || null);
    //     setCountryCode('IN');
    //     localStorage.setItem('selectedCountry', 'India');
    //     localStorage.setItem('countryCode', 'IN');
    //   } else {
    //     setSelectedCountry(countries.find((country) => country.name === 'UK') || null);
    //     setCountryCode('UK');
    //     localStorage.setItem('selectedCountry', 'UK');
    //     localStorage.setItem('countryCode', 'UK');
    //   }
    // }

    // setIsAuthenticated(accessToken !== null);
  }, []);
  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await fetch("https://ipinfo.io?token=2aadf73cb3df29");
        const data = await response.json();
        const userCountry = data.country;
        if (userCountry === "IN") {
          setSelectedCountry(
            countries.find((country) => country.name === "India") || null
          );
          setCountryCode("IN");
          localStorage.setItem("selectedCountry", "India");
          localStorage.setItem("countryCode", "IN");
        } else {
          setSelectedCountry(
            countries.find((country) => country.name === "UK") || null
          );
          setCountryCode("UK");
          localStorage.setItem("selectedCountry", "UK");
          localStorage.setItem("countryCode", "UK");
        }
      } catch (error) {
        console.error("Error fetching IP location:", error);
      }
    };

    if (storedCountry && storedCode) {
      const country = countries.find(
        (country) => country.name === storedCountry
      );
      if (country) {
        setSelectedCountry(country);
        setCountryCode(storedCode);
      }
    } else {
      fetchUserCountry();
    }
  }, [storedCountry, storedCode]);
  let location = useLocation();
  // console.log(location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    injectClarityScript();
  }, []);

  useEffect(() => {
    if (window._hsq) {
      // Track page view for the current path
      window._hsq.push(["setPath", location.pathname]);
      window._hsq.push(["trackPageView"]);
    }
  }, [location]);

  const [guestId, setGuestId] = useState<string>("");
  localStorage.setItem("guestId", guestId);
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    if (userId === null) {
      setGuestId(getGuestUserId());
    } else {
      setGuestId(userId);
    }
  }, [userId]);

  return (
    <Container>
      <div>
        {/* <Router> */}

        {!staticFiles.has(location.pathname) &&
          !location.pathname.endsWith("/checkout") && (
            <>
              {/* <TopHeader /> */}
              <Header />
            </>
          )}
        <HelmetProvider>
          <Canonical />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/:countryCode" element={<HomePage />} />

            <Route path="/:countryCode/shop" element={<ShopScreens />} />

            <Route
              path="/:countryCode/holi-festive"
              element={<ChristmasSpecial />}
            />
            <Route
              path="/:countryCode/shop/cuisine"
              element={<ShopCuisineRedirect />}
            />
            <Route
              path="/:countryCode/shop/cuisine/:cuisine"
              element={<ShopExplore />}
            />

            <Route
              path="/:countryCode/holi-special"
              element={<ChristmasSpecial />}
            />
            <Route
              path="/:countryCode/shop/cuisine"
              element={<ShopCuisineRedirect />}
            />
            <Route
              path="/:countryCode/shop/cuisine/:cuisine"
              element={<ShopExplore />}
            />

            <Route path="/:countryCode/holi-festive" element={<ChristmasSpecial />} />
            <Route path="/:countryCode/shop/cuisine" element={<ShopCuisineRedirect />} />
            <Route path="/:countryCode/shop/cuisine/:cuisine" element={<ShopExplore />} />


            <Route path="/:countryCode/holi-special" element={<ChristmasSpecial />} />
            <Route path="/:countryCode/shop/cuisine" element={<ShopCuisineRedirect />} />
            <Route path="/:countryCode/shop/cuisine/:cuisine" element={<ShopExplore />} />

            <Route path="/:countryCode/recipe/cuisine/:cuisine" element={<RecipeCuisine />} />

            <Route path="/:countryCode/cart" element={<CartPage />} />

            <Route path="/:countryCode/checkout" element={<CartCheckoutPage />} />

            <Route
              path="/:countryCode/shop/cuisine/:cuisine/:url"
              element={<ProductPage />}
            />

            <Route path="/:countryCode/vendor/:city/:z1/:z2/:url" element={<VendorExplore />} />


            <Route path="/:countryCode/persona/:id" element={<PersonaIndex />} />
            <Route path="/:countryCode/recipe/cuisine/:cuisine/persona/:id" element={<PersonaIndex />} />
            <Route path="/:countryCode/recipe/:consumer/:url" element={<DetailIndex />} />
            <Route path="/:countryCode/recipe/cuisine/:cuisine/:consumer/:url" element={<DetailIndex />} />
            {/* <Route path="/:countryCode/cart" element={<Cart />} /> */}

            <Route path="/:countryCode/shop/cuisine/:cuisine/:url" element={<ProductPage />} />

            <Route path="/:countryCode/profile" element={<ProfilePage />} />

            <Route path="/:countryCode/order/:id" element={<DeliveryDetails />} />

            <Route path="/:countryCode/order-cancel/:id" element={<RefundScreens />} />

            <Route path="/:countryCode/blog" element={<BlogPage />} />
            <Route path="/:countryCode/blog/:title" element={<Blog />} />
            {/* <Route path="/.well-known/assetlinks.json" element={<AssetLink />} /> */}

            {/* <Route path="/Aboutus" element={<Aboutus />} /> */}

            <Route path="/:countryCode/PrivacyPolicy" element={<PrivacyPolicy />} />

            <Route path="/:countryCode/ShippingPolicy" element={<ShippingPolicy />} />
            <Route path="/:countryCode/AI" element={<AI />} />
            <Route path="/:countryCode/CancellationRefunds" element={<CancellationRefunds />} />
            <Route path="/:countryCode/OrderCancelled/:id" element={<ConfirmCancelPopup />} />

            <Route path="/:countryCode/TermsConditions" element={<TermsConditions />} />

            <Route path="/:countryCode/OurStory" element={<OurStoryTwo />} />
            <Route path="/:countryCode/OrderConfirm/:id" element={<OrderConfirmation />} />

            {/* Onboarding Routes */}
            {/* <Route path="/onb1" element={<DeliveryChecker/>} />
          <Route path="/onb2" element={<PreferencesPage/>} />
          <Route path="/onb3" element={<SignupPage/>} />
          <Route path="/onb4" element={<CheckoutForm/>} />
          <Route path="/onb5" element={<PaymentMethod />} />
          <Route path="/onb6" element={<MealPlanner/>} />
          <Route path="/PlanSettings" element={<PlanSettings/>} />
          <Route path="/selectmeal" element={<MealPlanSelector/>}/> */}
            {/* <Route path="/onb7" element={<DeliveryPopupFlow/>} /> */}

            <Route path="/:countryCode/subscription" element={<PricingPlans />} />
            <Route path="/:countryCode/viewall" element={<IngredientShop />} />
            <Route path="/:countryCode/contact" element={<ContactUs />} />
            <Route path="/:countryCode/404" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />


            {/* <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/profile" element={<ProfilePage />} />
          </Route>

          
          <Route path="*" element={<Navigate to="/home" />} /> */}
          </Routes>
        </HelmetProvider>
        {(!staticFiles.has(location.pathname)) &&
          <Footer />
        }
        {/* <ToastContainer /> */}
        {/* </Router> */}
      </div>
    </Container>
  );
};

export default AppContainer;
