import React from "react";

const TermsConditions: React.FC = () => {
  const storedCode = localStorage.getItem("countryCode");
  return (
    <div className=" text-greytext  p-6 lg:p-12 rounded-lg bg-white max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">Terms and Conditions</h1>
      <div className="space-y-6">
        <section className="space-y-6">
          <div className="prose prose-lg mx-auto">

            <h2 className="text-2xl font-semibold font-sans mb-4">1. Introduction</h2>
            <p className="font-sans mb-4">This agreement applies as between you, the User of the AnyFeast website and our iOS and Android application (collectively, the “Platform”) currently provided or which will be provided by <b>AnyFeast Limited</b>, a company registered at <b>{storedCode === "IN" ? "Office No 1210, 12th Floor, Ghanshyam Enclave, Near Lalji Pada Police Station, New Link Road, Kandivali (W), Mumbai 400067" : "15 College Road, Harrow, England, HA1 1BA," } </b>trading as “AnyFeast” (“AnyFeast,” “we,” “us,” or “our”). Your agreement to comply with and be bound by these Terms and Conditions is deemed to occur upon your first use of our services. If you do not agree, you must discontinue use immediately.</p>

            <h2 className="text-2xl font-semibold font-sans my-4">2. Definitions</h2>
            <ul className="list-disc pl-5">
              <li><strong>User:</strong> Any individual who accesses or uses the Platform.</li>
              <li><strong>Vendor:</strong> Any seller, retailer, or partner providing goods or services on the Platform.</li>
              <li><strong>Services:</strong> Includes AI-based recipe suggestions, grocery recommendations, and Marketplace Services.</li>
              <li><strong>Marketplace Services:</strong> The online platform connecting users with vendors for purchasing meal kits and related products.</li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">3. Eligibility</h2>
            <ul className="list-disc pl-5">
              <li>You must be at least <strong>18 years old</strong> to use the Platform. If you are under 18, your guardian must accept these Terms on your behalf.</li>
              <li>You agree to provide <strong>true, accurate, and up-to-date registration information</strong> and update them upon any change.</li>
              <li>Services may be limited to specific <strong>geographic locations.</strong></li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">4. Account Registration & Management</h2>
            <ul className="list-disc pl-5">
              <li>You must provide a valid phone number and/or email for account registration.</li>
              <li>By providing your phone number or email, you consent to be contacted via calls, SMS notifications, or emails regarding service updates.</li>
              <li>AnyFeast reserves the right to suspend or terminate accounts for fraudulent activity, misuse, or violations of these Terms.</li>
              <li>Users must ensure their login credentials remain confidential and notify AnyFeast of any unauthorized access.</li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">5. Services & AI Features</h2>
            <ul className="list-disc pl-5">
              <li>AnyFeast provides AI-powered meal planning, grocery recommendations, and other digital services.</li>
              <li>AI-generated meal suggestions are for informational purposes only and should not be considered medical, nutritional, or professional advice.</li>
              <li>Some services may be provided by third parties, and AnyFeast is not responsible for third-party offerings.</li>
              <li>The Platform may change features or services without prior notice.</li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">6. Acceptable Use</h2>
            <p>Users may not:</p>
            <ul className="list-disc pl-5">
              <li>Violate any applicable laws or regulations.</li>
              <li>Provide false or misleading information.</li>
              <li>Engage in fraudulent transactions or misuse AI-generated suggestions.</li>
              <li>Disrupt the Platform’s functionality.</li>
              <li>Use the Platform for unauthorized commercial purposes.</li>
              <li>Attempt to reverse-engineer, copy, or extract data from AnyFeast’s proprietary AI models or software.</li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">7. Marketplace Services</h2>
            <ul className="list-disc pl-5">
              <li>The Marketplace is a platform that allows consumers to transact with third-party sellers, merchants, and retailers.</li>
              <li>Vendors are solely responsible for the quality, delivery, and fulfillment of their products.</li>
              <li>AnyFeast does not provide any services other than providing the Marketplace as a platform to transact at users' own risk.</li>
              <li>AnyFeast is not liable for any disputes between users and vendors.</li>
              <li>Vendors must comply with food safety, hygiene, and packaging standards as per local regulations.</li>
              <li>AnyFeast reserves the right to remove or ban vendors who fail to meet quality and compliance standards.</li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">8. Orders, Cancellations & Refunds</h2>
            <p>All orders are final. However, refunds or replacements may be offered for:</p>
            <ul className="list-disc pl-5">
              <li>Damaged, defective, or incorrect items.</li>
              <li>Failed deliveries due to vendor error.</li>
              <li>Technical issues in AI-based recommendations.</li>
            </ul>
            <ul className="list-disc pl-5">
              <li>Refunds are subject to vendor discretion.</li>
              <li>Users must report any refund requests within 24 hours of delivery and provide photo evidence.</li>
              <li>Refunds, if approved, will be processed within 5-7 business days.</li>
              <li>Cancellations may be subject to an administrative fee, depending on the stage of order processing.</li>
              <li>Any disputes regarding refunds shall be first mediated through AnyFeast’s customer resolution process before legal escalation.</li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">9. Intellectual Property Rights</h2>
            <ul className="list-disc pl-5">
              <li>AnyFeast owns or licenses all intellectual property on the Platform.</li>
              <li>Users grant AnyFeast a non-exclusive, royalty-free license to use any content submitted (e.g., reviews, recipes) for marketing or other purposes.</li>
              <li>Unauthorized reproduction, scraping, or redistribution of AnyFeast’s proprietary content is strictly prohibited.</li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">10. Privacy & Data Protection</h2>
            <ul className="list-disc pl-5">
              <li>Personal data is collected and processed in accordance with our Privacy Policy.</li>
              <li>AnyFeast may use first-party and third-party cookies to improve user experience.</li>
              <li>Users have the right to request the deletion, correction, or modification of their personal data in compliance with data protection laws.</li>
              <li>AnyFeast is committed to compliance with UK GDPR and other applicable privacy regulations.</li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">11. Limitation of Liability</h2>
            <p>AnyFeast is not liable for damages arising from:</p>
            <ul className="list-disc pl-5">
              <li>User misuse of recipe kits.</li>
              <li>Food allergies or dietary preferences.</li>
              <li>Third-party services, vendor issues, or delivery failures.</li>
              <li>Temporary Platform downtime or maintenance.</li>
            </ul>
            <p>AnyFeast’s maximum liability under any claim shall not exceed the amount paid by the user for the specific order or service in dispute.</p>

            <h2 className="text-2xl font-semibold font-sans my-4">12. Indemnification</h2>
            <p>Users and Vendors agree to indemnify and hold AnyFeast harmless from any claims, damages, or liabilities arising due to their actions, including but not limited to:</p>
            <ul className="list-disc pl-5">
              <li><strong>Improper use of the Platform.</strong></li>
              <li><strong>Contractual disputes with vendors.</strong></li>
              <li><strong>Misrepresentation of information.</strong></li>
              <li><strong>Failure to adhere to applicable food safety and regulatory requirements.</strong></li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">13. Events Beyond Our Control</h2>
            <p>Neither AnyFeast nor users will be held liable for delays or failures due to unforeseen events, including but not limited to natural disasters, government actions, regulatory changes, or technical failures.</p>

            <h2 className="text-2xl font-semibold font-sans my-4">14. Changes to Terms</h2>
            <ul className="list-disc pl-5">
              <li>AnyFeast reserves the right to modify these Terms at any time.</li>
              <li>Users should check for updates periodically.</li>
              <li>Continued use of the Platform after modifications constitutes acceptance of the updated Terms.</li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">15. Governing Law & Jurisdiction</h2>
            <ul className="list-disc pl-5">
              <li>These Terms shall be <strong>governed by and construed</strong> in accordance with the <strong>{storedCode === "IN" ? "laws of India." : "laws of England and Wales." }</strong></li>
              <li>Disputes will be subject to the <strong>exclusive jurisdiction of the {storedCode === "IN" ? "Courts of Mumbai, India" : "Courts of London, United Kingdom." }</strong></li>
            </ul>

            <h2 className="text-2xl font-semibold font-sans my-4">16. Contact Information</h2>
            <p>For questions about these Terms, please contact <a href="mailto:contact@anyfeast.com" className="text-blue-600">contact@anyfeast.com</a>.</p>
          </div>
        </section>

      </div>
    </div>
  );
};

export default TermsConditions;
