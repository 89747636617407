import React from "react";

const AI: React.FC = () => {
    const storedCode = localStorage.getItem("countryCode");
    return (
        <div className="text-greytext  p-6 lg:p-12 rounded-lg bg-white max-w-7xl mx-auto">
            <h1 className="text-3xl font-bold mb-6 text-center">AI Policy</h1>
            {storedCode === "IN" && (<>
                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-black">1. Introduction</h2>
                    <p className="text-base leading-relaxed mb-4">
                        This AI Policy outlines how AnyFeast Limited (“AnyFeast,” “we,” “us,” or “our”) integrates and governs the use of Artificial Intelligence (AI) technologies within its website and mobile applications (collectively, the "Platform"). By accessing and using the Platform, users agree to the principles and safeguards set forth in this AI Policy.
                    </p>
                    <p className="text-base leading-relaxed">
                        This policy ensures that AI technologies used within AnyFeast operate within ethical, legal, and transparent frameworks, while also protecting AnyFeast's intellectual property, liability, and business interests.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-black">2. AI Usage & Functionality</h2>
                    <ul className="list-disc list-inside">
                        <li>Provide personalized meal planning and grocery recommendations based on user preferences, dietary restrictions, and historical choices.</li>
                        <li>Optimize user experience through smart search, image recognition, and ingredient analysis.</li>
                        <li>Enhance customer service through AI-powered chatbots and automated responses to improve query resolution.</li>
                        <li>Enable predictive analytics for inventory management and operational efficiencies.</li>
                        <li>Assist in minimizing food waste through AI-driven surplus food recommendations.</li>
                        <li>Improve fraud detection and security by analyzing user behavior patterns and transactional activities.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-black">3. Transparency, Explainability & User Awareness</h2>
                    <ul className="list-disc list-inside">
                        <li>AI-generated recommendations are based on publicly available data, user input, machine learning models, and proprietary algorithms.</li>
                        <li>AnyFeast ensures that AI-driven decisions are explainable and provides users the ability to understand how and why AI makes specific recommendations.</li>
                        <li>AnyFeast regularly audits AI models to prevent bias, ensure accuracy, and maintain fairness.</li>
                        <li>Users will be explicitly informed when interacting with AI-generated content or decisions.</li>
                        <li>Where AI plays a role in automated decision-making (e.g., AI-driven personalized offers), users have the right to request human intervention.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-black">4. Data Privacy, Security & Compliance</h2>
                    <ul className="list-disc list-inside">
                        <li>AI processes personal data in compliance with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 (under the IT Act, 2000), and other applicable privacy laws in India.</li>
                        <li>AnyFeast does not sell, rent, or share user data with third parties for AI training purposes without explicit consent.</li>
                        <li>AI systems operate in secure environments with encrypted data handling and restricted access controls.</li>
                        <li>Users have the right to opt out of AI-driven personalization where applicable.</li>
                        <li>AI-powered features only process necessary user data and do not make assumptions beyond provided inputs.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-black">5. Ethical AI Commitments</h2>
                    <ul className="list-disc list-inside">
                        <li><strong>Fairness & Non-Discrimination:</strong> AI models are trained to avoid biases in food recommendations and service provisions.</li>
                        <li><strong>User Autonomy:</strong> AI suggestions are optional, and users retain full control over their choices and decision-making.</li>
                        <li><strong>Human Oversight:</strong> AI-powered decisions that have a significant impact on users (e.g., pricing, promotions, or eligibility for discounts) will always include human review mechanisms.</li>
                        <li><strong>Continuous Improvement:</strong> AI models are refined based on user feedback, market changes, and evolving ethical standards.</li>
                        <li><strong>Responsible AI Governance:</strong> AI deployment within AnyFeast aligns with the National Strategy for Artificial Intelligence by the Government of India and the OECD AI Principles.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-black">6. Limitations & User Responsibility</h2>
                    <ul className="list-disc list-inside">
                        <li>AI-generated content should not be considered a substitute for professional medical, nutritional, or dietary advice.</li>
                        <li>Users are responsible for verifying AI-generated ingredient suggestions, allergens, and nutritional values before making purchasing decisions.</li>
                        <li>AI meal recommendations are based on algorithmic predictions and may not always be fully accurate.</li>
                        <li>AnyFeast is not liable for adverse health effects resulting from AI-generated food recommendations.</li>
                        <li>AI-generated pricing, discounts, or promotions may be subject to change based on dynamic pricing algorithms.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-black">7. User Control & AI Opt-Out</h2>
                    <ul className="list-disc list-inside">
                        <li>Users can manage AI-powered features via the settings section in the app.</li>
                        <li>AI-driven recommendations, such as personalized shopping suggestions, can be disabled or customized.</li>
                        <li>Users may request a copy of their AI-processed data and can opt out of AI personalization features where legally applicable under the Data Protection Bill, 2023 (or any future amendments).</li>
                        <li>AI decision-making related to account eligibility, transactions, or fraud detection may be subject to human intervention upon request.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-black">8. Intellectual Property & AI Usage Rights</h2>
                    <ul className="list-disc list-inside">
                        <li>All AI models, datasets, and proprietary AI-driven algorithms belong exclusively to AnyFeast.</li>
                        <li>Users and third parties do not have rights to AnyFeast’s AI models, training data, or decision-making frameworks.</li>
                        <li>Any unauthorized modification, reverse-engineering, or extraction of AnyFeast’s AI-related content or recommendations is strictly prohibited.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-black">9. Compliance & Governing Law</h2>
                    <ul className="list-disc list-inside">
                        <li>This AI Policy is governed by and construed in accordance with the laws of India.</li>
                        <li>Any disputes related to AI governance will be subject to the exclusive jurisdiction of the Courts of Mumbai, India.</li>
                        <li>AI-related services comply with the Information Technology Act, 2000, National Strategy for Artificial Intelligence by the Government of India, and relevant international AI ethics guidelines.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-black">10. Changes to the AI Policy</h2>
                    <p className="text-base leading-relaxed">
                        AnyFeast reserves the right to update this AI Policy periodically.
                    </p>
                    <p className="text-base leading-relaxed">
                        Changes will be notified to users via email, app notifications, or website announcements.
                    </p>
                    <p className="text-base leading-relaxed">
                        Continued use of AI-powered services after updates constitutes acceptance of the revised AI Policy.
                    </p>
                    <p className="text-base leading-relaxed">
                        For further inquiries regarding AI governance at AnyFeast, contact us at:
                   
                    <p className="font-sans mb-4"><a href="mailto:contact@anyfeast.com" className="text-blue-600">contact@anyfeast.com</a></p> </p>
                </section>
            </>)}
            {storedCode === "UK" && (
                <section className="space-y-6">
                    <div className="prose prose-lg mx-auto">
                        <h2 className="text-2xl font-semibold font-sans mb-4">1. Introduction</h2>
                        <p className="font-sans mb-4">This AI Policy outlines how <strong>AnyFeast Limited</strong> (“AnyFeast,” “we,” “us,” or “our”) integrates and governs the use of Artificial Intelligence (AI) technologies within its website and mobile applications (collectively, the "Platform"). By accessing and using the Platform, users agree to the principles and safeguards set forth in this AI Policy.</p>
                        <p className="font-sans mb-4">This policy ensures that AI technologies used within AnyFeast operate within <strong>ethical, legal, and transparent frameworks,</strong> while also protecting <strong>AnyFeast's intellectual property, liability, and business interests.</strong></p>

                        <h2 className="text-2xl font-semibold font-sans mb-4">2. AI Usage & Functionality</h2>
                        <p className="font-sans mb-4">AnyFeast employs AI technologies to:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Provide personalized meal planning and grocery recommendations based on user preferences, dietary restrictions, and historical choices.</li>
                            <li>Optimize user experience through smart search, image recognition, and ingredient analysis.</li>
                            <li>Enhance customer service through AI-powered chatbots and automated responses to improve query resolution.</li>
                            <li>Enable predictive analytics for inventory management and operational efficiencies.</li>
                            <li>Assist in minimizing food waste through AI-driven surplus food recommendations.</li>
                            <li>Improve fraud detection and security by analyzing user behavior patterns and transactional activities.</li>
                        </ul>
                        <h2 className="text-2xl font-semibold font-sans mb-4">3. Transparency, Explainability & User Awareness</h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li>AI-generated recommendations are based on publicly available data, user input, machine learning models, and proprietary algorithms.</li>
                            <li>AnyFeast ensures that AI-driven decisions are explainable and provides users the ability to understand how and why AI makes specific recommendations.</li>
                            <li>AnyFeast regularly audits AI models to prevent bias, ensure accuracy, and maintain fairness.</li>
                            <li>Users will be explicitly informed when interacting with AI-generated content or decisions.</li>
                            <li>Where AI plays a role in automated decision-making (e.g., AI-driven personalized offers), users have the right to request human intervention.</li>
                        </ul>

                        <h2 className="text-2xl font-semibold font-sans mb-4">4. Data Privacy, Security & Compliance</h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li>AI processes personal data in compliance with UK GDPR, CCPA, and other applicable privacy laws.</li>
                            <li>AnyFeast does not sell, rent, or share user data with third parties for AI training purposes without explicit consent.</li>
                            <li>AI systems operate in secure environments with encrypted data handling and restricted access controls.</li>
                            <li>Users have the right to opt out of AI-driven personalization where applicable.</li>
                            <li>AI-powered features only process necessary user data and do not make assumptions beyond provided inputs.</li>
                        </ul>

                        <h2 className="text-2xl font-semibold font-sans mb-4">5. Ethical AI Commitments</h2>
                        <ul className="font-sans mb-4 list-disc pl-6">
                            <li><strong>Fairness & Non-Discrimination:</strong> AI models are trained to avoid biases in food recommendations and service provisions.</li>
                            <li><strong>User Autonomy:</strong> AI suggestions are optional, and users retain full control over their choices and decision-making.</li>
                            <li><strong>Human Oversight:</strong> AI-powered decisions that have a significant impact on users (e.g., pricing, promotions, or eligibility for discounts) will always include human review mechanisms.</li>
                            <li><strong>Continuous Improvement:</strong> AI models are refined based on user feedback, market changes, and evolving ethical standards.</li>
                            <li><strong>Responsible AI Governance:</strong> AI deployment within AnyFeast aligns with the OECD AI Principles and UK AI Regulatory Frameworks.</li>
                        </ul>

                        <h2 className="text-2xl font-semibold font-sans mb-4">6. Limitations & User Responsibility</h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li>AI-generated content should not be considered a substitute for professional medical, nutritional, or dietary advice.</li>
                            <li>Users are responsible for verifying AI-generated ingredient suggestions, allergens, and nutritional values before making purchasing decisions.</li>
                            <li>AI meal recommendations are based on algorithmic predictions and may not always be fully accurate.</li>
                            <li>AnyFeast is not liable for adverse health effects resulting from AI-generated food recommendations.</li>
                            <li>AI-generated pricing, discounts, or promotions may be subject to change based on dynamic pricing algorithms.</li>
                        </ul>

                        <h2 className="text-2xl font-semibold font-sans mb-4">7. User Control & AI Opt-Out</h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Users can manage AI-powered features via the settings section in the app.</li>
                            <li>AI-driven recommendations, such as personalized shopping suggestions, can be disabled or customized.</li>
                            <li>Users may request a copy of their AI-processed data and can opt out of AI personalization features where legally applicable.</li>
                            <li>AI decision-making related to account eligibility, transactions, or fraud detection may be subject to human intervention upon request.</li>
                        </ul>

                        <h2 className="text-2xl font-semibold font-sans mb-4">8. Intellectual Property & AI Usage Rights</h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li>All AI models, datasets, and proprietary AI-driven algorithms belong exclusively to AnyFeast.</li>
                            <li>Users and third parties do not have rights to AnyFeast’s AI models, training data, or decision-making frameworks.</li>
                            <li>Any unauthorized modification, reverse-engineering, or extraction of AnyFeast’s AI-related content or recommendations is strictly prohibited.</li>
                        </ul>

                        <h2 className="text-2xl font-semibold font-sans mb-4">9. Compliance & Governing Law</h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li>This AI Policy is governed by and construed in accordance with the <strong>laws of England and Wales.</strong></li>
                            <li>Any disputes related to AI governance will be subject to the <strong>exclusive jurisdiction of the Courts of London, United Kingdom.</strong></li>
                            <li>AI-related services comply with <strong>UK GDPR, the UK AI Strategy, and relevant international AI ethics guidelines.</strong></li>
                        </ul>

                        <h2 className="text-2xl font-semibold font-sans mb-4">10. Changes to the AI Policy</h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li>AnyFeast reserves the right to update this AI Policy periodically.</li>
                            <li>Changes will be notified to users via email, app notifications, or website announcements.</li>
                            <li>Continued use of AI-powered services after updates constitutes acceptance of the revised AI Policy.</li>
                        </ul>


                        <h2 className="text-2xl font-semibold font-sans mb-4">For further inquiries regarding AI governance at AnyFeast, contact us at</h2>
                        <p className="font-sans mb-4"><a href="mailto:contact@anyfeast.com" className="text-blue-600">contact@anyfeast.com</a></p>
                    </div>
                </section>
            )}
        </div>
    );
};

export default AI;
