import '../../App.css';
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch } from '../../hooks';
import { addAddressAsync, getAdressByUserIdAsync, updateAddressAsync } from '../../services/reducers/reciepeReducer';
import { Country, State, City } from 'country-state-city';
import Select from 'react-select'; // Import react-select
import { useLocation } from 'react-router-dom';
import im1 from "../../assets/images/cross.png";
import { InputStyle } from '../Home/LoginModal';
import PhoneInput from "react-phone-number-input";

interface AddressModalProps {
  isOpen: boolean;
  onClose: () => void;
  getAddress: any;
  addressId?: string;
  cartAddress?: any;
  address?: any;
  type: string;
}

interface OptionType {
  label: string;
  value: string;
}

type CountryCode = 'IN' | 'GB';

const AddressModal: React.FC<AddressModalProps> = ({ isOpen, onClose, getAddress, addressId, cartAddress, address, type }) => {
  const [selectedCountry, setSelectedCountry] = useState<OptionType | null>(null);
  const [cityOptions, setCityOptions] = useState<OptionType[]>([]);
  const [customCity, setCustomCity] = useState('');
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId");
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [phone, setPhone] = useState("");
  const userPhone = localStorage.getItem("userPhone") || "";
  const userEmail = localStorage.getItem("Email") || "";
  const userName = localStorage.getItem("firstName") || "";
  const storedCode = localStorage.getItem("countryCode");
  const countryCode = storedCode === "IN" ? "IN" : storedCode === "UK" ? "GB" : storedCode;
  

  const validationSchema = Yup.object().shape({
    street_address: Yup.string().required('Street address is required'),
    // firstName: Yup.string().required('First Name is required'),
    // lastName: Yup.string().required('Last Name is required'),
    postal_code: Yup.string().required('Postal code is required'),
    // city: Yup.string().required('City is required'),
    //country: Yup.string().required('Country is required'),
    address_type: Yup.string().required('Address type is required'),
  });

  useEffect(() => {
    const savedPhoneNumber = localStorage.getItem("userPhone");
    if (savedPhoneNumber) {
      setPhone(savedPhoneNumber);
    }
  }, []);

  const handlePhoneChange = (value: string | undefined) => {
    if (value && value.length > 13) {
      setPhoneError("Phone number cannot exceed 10 digits.");
      setPhone(value.substring(0, 13));
      return;
    }
    setPhone(value || "");
    // setPhoneError(null);

    if (value) {
      localStorage.setItem("userPhone", value);
    } else {
      localStorage.removeItem("userPhone");
    }
  };

  useEffect(() => {
    if (addressId) {
      dispatch(getAdressByUserIdAsync(addressId))
    }
  }, [addressId, dispatch]);

  const countryOptions = [
    { value: 'IN', label: 'India' },
    { value: 'GB', label: 'United Kingdom' },
  ];

  useEffect(() => {
    if (type === "cart") {
      if (countryCode === 'IN') {
        setSelectedCountry({ value: 'IN', label: 'India' });
      } else if (countryCode === 'GB') {
        setSelectedCountry({ value: 'GB', label: 'United Kingdom' });
      }
    }
  }, [countryCode]);
  const selectedCartCountry = countryOptions.find(option => option.value === countryCode) || null;

  const citySelect = () => {
    if (selectedCountry) {
      const cities = City.getCitiesOfState(selectedCountry.value, selectedCountry.value).map((city: any) => ({
        label: city.name,
        value: city.name,
      }));
      setCityOptions(cities);
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      citySelect();
    }
  }, [selectedCountry]);

  const initialValues = {
    street_address: address?.street_address || '',
    postal_code: address?.postal_code || '',
    address_type: address?.address_type || '',
    country: address?.country || '',
    city: address?.city || '',
    firstName: '',
    lastName: '',
    email: '',
    details: {
      uid: userId,
      phone: address?.phone || userPhone || '',
      name: address?.name || userName || '',
      email: address?.email || userEmail || ''
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-xl w-full max-w-md sm:max-w-lg lg:max-w-3xl mx-4 relative overflow-y-auto max-h-[90vh]">
        <div className="flex justify-between items-center -ml-8 -mr-8 -mt-8 bg-[#FAFCFE] pt-4 pb-4 pl-4 pr-1 rounded-md mb-6 ">
          <h2 className="text-xl font-semibold text-black ml-4 ">
            {addressId ? "Update Address" : "Add New Address"}
          </h2>
          <button onClick={onClose} className="mr-6">
            <img
              src={im1}
              alt="Close"
              className="w-4 h-4 ml-0"
            />
          </button>
        </div>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const request = {
                user_id: userId,
                street_address: values.street_address,
                city: customCity || values.city,
                postal_code: values.postal_code,
                country: selectedCountry?.value || values.country,
                address_type: values.address_type,
                details: {
                  uid: userId,
                  phone: userPhone || '',
                  name: values.firstName,
                  email: userEmail || values.email
                }
              };

              setSubmitting(true);

              if (addressId) {
                await dispatch(updateAddressAsync({ id: addressId, reqBody: request })).unwrap();
              } else {
                const response = await dispatch(addAddressAsync(request)).unwrap();
                if (response?.data.user?.email) {
                  localStorage.setItem('userEmail', response.data.user.email);
                  localStorage.setItem("firstName", response.data.user.name);
                }
              }

              getAddress();
              onClose();
            } catch (error) {
              console.error('Error during API call:', error);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, setFieldValue, values, isSubmitting, isValid }) => (
            <Form>
              {!addressId && (
                <div className="mb-6">
                  <label className="block text-sm text-gray-600 mb-1 font-bold">Username</label>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div className="w-full">
                      <Field
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                      />
                      {errors.firstName && touched.firstName && typeof errors.firstName === 'string' && (
                        <div className="text-red text-sm mt-1">{errors.firstName}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                      />
                      {errors.lastName && touched.lastName && typeof errors.lastName === 'string' && (
                        <div className="text-red text-sm mt-1">{errors.lastName}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* {type === "profile" && ( */}
              <div className="mb-6">
                <label className="block text-sm text-gray-600 mb-1 font-bold">Phone</label>
                <InputStyle>
                  <PhoneInput
                    className="w-full outline-none  bg-none border-2 !border-gray-300 !border-opacity-50 rounded-lg p-3 px-4 text-450 text-black placeholder:text-gray-300 font-normal disabled:bg-gray-200"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry={countryCode === "IN" ? "IN" : "GB"}
                    countries={["IN", "GB"]}
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="enter phone number"
                  />
                  {phoneError && (
                    <div className="text-red text-200 mt-1 font-sans">{phoneError}</div>
                  )}
                </InputStyle>
              </div>
              {/* )} */}

              <div className="mb-6">
                <label className="block text-sm text-gray-600 mb-1 font-bold">Email</label>
                <Field
                  type="email"
                  name="email"
                  value={userEmail || values.email}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                  disabled={userEmail}
                />
              </div>

              <div className="mb-6">
                <label className="block text-sm text-gray-600 mb-1 font-bold">Address</label>
                <Field
                  type="text"
                  name="street_address"
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                />
                {errors.street_address && touched.street_address && typeof errors.street_address === 'string' && (
                  <div className="text-red text-sm mt-1">{errors.street_address}</div>
                )}
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                <div>
                  <label className="block text-sm text-gray-600 mb-1 font-bold">Country</label>
                  <Select
                    options={countryOptions}
                    value={selectedCountry}
                    onChange={(option) => {
                      setSelectedCountry(option);
                      setCityOptions([]);
                      setCustomCity('');
                      setFieldValue('country', option?.label || values.country);
                    }}
                    placeholder="Select Country"
                    isDisabled={type === "cart" && !!selectedCountry}
                  />

                </div>

                {/* City Dropdown with Search */}
                {/* {(selectedCountry || cityOptions.length > 0) && ( */}
                <div>
                  <label className="block text-sm text-gray-600 mb-1 font-bold">City</label>
                  <input
                    type="text"
                    name='city'
                    value={customCity || values.city}
                    onChange={(e) => setCustomCity(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="Enter your city"
                  />
                  {errors.city && touched.city && typeof errors.city === 'string' && (
                    <div className="text-red text-sm mt-1">{errors.city}</div>
                  )}
                </div>
                {/* )} */}

                {/* Postal Code Field */}
                <div>
                  <label className="block text-sm text-gray-600 mb-1 font-bold">Zip Code</label>
                  <Field
                    type="text"
                    name="postal_code"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                  />
                  {errors.postal_code && touched.postal_code && typeof errors.postal_code === 'string' && (
                    <div className="text-red text-sm mt-1">{errors.postal_code}</div>
                  )}
                </div>
              </div>

              <div className="mb-6">
                <label className="block text-sm text-gray-600 mb-1 font-bold">Address Type</label>
                <Field
                  as="select"
                  name="address_type"
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                >
                  <option value="" label="Select address type" />
                  <option value="Home" label="Home" />
                  <option value="Office" label="Office" />
                </Field>
                {errors.address_type && touched.address_type && typeof errors.address_type === 'string' && (
                  <div className="text-red text-sm mt-1">{errors.address_type}</div>
                )}
              </div>

              <button
                type="submit"
                className="w-full bg-green text-white py-2 rounded-md hover:bg-green-hover transition duration-200"
                disabled={addressId ? !phone : isSubmitting || !isValid}
              >
                {addressId ? "Update Address" : "Save Address"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddressModal;