import { useEffect, useState } from 'react';
import { ChevronDown } from 'lucide-react';
import india from '../../assets/images/india.png';
import uk from '../../assets/images/uk.png';
import placeholder from '../../assets/images/placeholderImage.png';

interface Country {
  name: string;
  flag: string;
}

const CountryToggle = () => {
  const countries: Country[] = [
    { name: 'UK', flag: uk },
    { name: 'India', flag: india }
  ];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(countries[0]);
  const [clickedCountry, setClickedCountry] = useState<string | null>(null);
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const storedCountry = localStorage.getItem('selectedCountry');
  const storedCode = localStorage.getItem('countryCode');

  useEffect(() => {
    // const hostname = window.location.hostname;
    // const storedCountry = localStorage.getItem('selectedCountry');
    // const storedCode = localStorage.getItem('countryCode');

    // if (storedCountry && storedCode) {
    //   const country = countries.find((country) => country.name === storedCountry);
    //   if (country) {
    //     setSelectedCountry(country);
    //     setCountryCode(storedCode);
    //     return; 
    //   }
    // }

    // if (hostname.endsWith('.in')) {
    //   setSelectedCountry(countries.find((country) => country.name === 'India') || null);
    //   setCountryCode('IN');
    //   localStorage.setItem('selectedCountry', 'India');
    //   localStorage.setItem('countryCode', 'IN');
    // } else {
    //   setSelectedCountry(countries.find((country) => country.name === 'UK') || null);
    //   setCountryCode('UK');
    //   localStorage.setItem('selectedCountry', 'UK');
    //   localStorage.setItem('countryCode', 'UK');
    // }
    const fetchUserCountry = async () => {
      try {
        const response = await fetch('https://ipinfo.io?token=2aadf73cb3df29'); 
        const data = await response.json();
        const userCountry = data.country;
        if (userCountry === 'IN') {
          setSelectedCountry(countries.find((country) => country.name === 'India') || null);
          setCountryCode('IN');
          localStorage.setItem('selectedCountry', 'India');
          localStorage.setItem('countryCode', 'IN');
        } else {
          setSelectedCountry(countries.find((country) => country.name === 'UK') || null);
          setCountryCode('UK');
          localStorage.setItem('selectedCountry', 'UK');
          localStorage.setItem('countryCode', 'UK');
        }
      } catch (error) {
        console.error('Error fetching IP location:', error);
      }
    };

    if (storedCountry && storedCode) {
      const country = countries.find((country) => country.name === storedCountry);
      if (country) {
        setSelectedCountry(country);
        setCountryCode(storedCode);
      }
    } else {
      fetchUserCountry();
    }
  }, [storedCountry, storedCode]);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleCountryClick = (country: Country) => {
    const code = country.name === 'India' ? 'IN' : 'UK';
    setClickedCountry(country.name);
    setClickedCountry(null);
    setSelectedCountry(country);
    setIsDropdownOpen(false);
    localStorage.setItem('selectedCountry', country.name);
    localStorage.setItem('countryCode', code);
    window.location.reload();
  };

  return (
    <div className="relative bg-white border border-[#F1F1F1] px-1 py-2 rounded-md">
      <button
        onClick={toggleDropdown}
        className="flex items-center text-black text-bold hover:text-gray-900 justify-between"
      >
        <div className="flex items-center justify-center w-full lg:justify-start">
          {selectedCountry && (
            <>
              <img
                src={selectedCountry.flag || placeholder}
                alt={selectedCountry.name}
                className="w-6 h-6 rounded-full"
              />
              <span className="font-bold hidden sm:inline sm:ml-2">{selectedCountry.name}</span>
            </>
          )}
        </div>
        <ChevronDown size={16} className="ml-2 hidden sm:inline-block" />
      </button>
      {isDropdownOpen && (
        <div className="absolute right-0 mt-5 w-40 z-[9999] lg:z-50 rounded-md shadow-md bg-white ring-1 border border-[#F1F1F1] ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {countries.map((country) => (
              <button
                key={country.name}
                onClick={() => handleCountryClick(country)}
                className={`flex items-center px-4 py-3 text-sm text-[#101828] w-full text-left
                          ${clickedCountry === country.name ? 'bg-[#FEF5F5]' : ''} 
                          active:bg-[#FEF5F5] md:hover:bg-[#FEF5F5] transition-colors duration-150`}
                role="menuitem"
              >
                <img
                  src={country.flag || placeholder}
                  alt={country.name}
                  className="w-6 h-6 rounded-full mr-2"
                />
                {country.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryToggle;
