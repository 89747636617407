import React, { useEffect, useState } from "react";
import { createByRazorpayAsync, createOrderAsync, deleteCartAsync, subScribeBasicAsync } from "../../services/reducers/reciepeReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import logo from "../../../src/assets/images/logo.png";
import p5 from "../../../src/assets/images/p5.png";

declare global {
  interface Window {
    Razorpay: any;
  }
}

interface RazorpayOptions {
  key: string;
  amount: number;
  currency: string;
  name: string;
  description: string;
  image: string;
  order_id: string;
  handler: (response: RazorpayResponse) => void;
  theme: {
    color: string;
  };
  prefill?: {
    name?: string;
    email?: string;
    contact?: string;
    method?: string;
  };
  method?: {
    upi?: boolean;
  };
}

interface RazorpayResponse {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
}

interface Props {
  cartTotal: any;
  price: any;
  cartIngredients: any;
  ipAddress: any;
  address: any;
  selectedAddress: any;
  detail: any;
  countryCode: any;
  isPaymentEnabled: boolean;
}

const RazorpayPayment: React.FC<Props> = ({ cartTotal, price, cartIngredients, ipAddress, address, selectedAddress, detail, countryCode, isPaymentEnabled }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.receipe.user);
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("firstName") || "User";
  const expectedDelivery = localStorage.getItem('deliveryDateTime')

  // const [showMessage, setShowMessage] = useState(false);

  const loadRazorpayScript = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    // if (!selectedAddress) {
    //   setShowMessage(true);
    //   setTimeout(() => setShowMessage(false), 3000);
    //   return;
    // }
    if (!isPaymentEnabled) return;

    const scriptLoaded = await loadRazorpayScript();

    if (!scriptLoaded) {
      console.error("Razorpay SDK failed to load. Are you online?");
      return;
    }

    try {
      if (selectedAddress) {
        const products = Object.keys(cartIngredients).flatMap(
          (dishKey: string) => {
            const dishId = Number(dishKey.split("-")[1]);
            const ingredients = cartIngredients[dishKey];

            return ingredients.map((ingredient: any) => ({
              ingredient_id: ingredient.id,
              name: ingredient.name,
              recipe_id: dishId,
              quantity: ingredient.selected,
            }));
          }
        );

        const requestBody = {
          order: {
            order_date: new Date().toISOString(),
            paid_date: new Date().toISOString(),
            phone: "",
            name: userName ?? "",
            status: "pending",
            currency: "INR",
            ip_address: ipAddress,
            user_agent: "web",
            user_id: userId,
            shipping_address: address?.street_address,
            shipping_postcode: address?.postal_code,
            shipping_city: address?.city,
            shipping_state: "",
            shipping_country: address?.country,
            shipping_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
            order_subtotal: parseFloat(cartTotal?.cart_value.toFixed(2)),
            order_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
            discount_total: detail.discount,
            coupon_id: detail.id,
            expected_delivery: expectedDelivery,
          },
          products: products,
        };

        const serializedRequest = JSON.stringify(requestBody);

        const response = await dispatch(
          createOrderAsync(serializedRequest)
        ).unwrap();

        if (response.success && response.data && response.data.orderId) {
          const cartOrderId = response.data.orderId;
          // console.log("Order created successfully. Order ID:", cartOrderId);

          const request = {
            currency: "INR",
            amount: price * 100,
            orderId: cartOrderId,
          };

          const result = await dispatch(createByRazorpayAsync(request));
          const orderId = unwrapResult(result).orderId;

          const options: RazorpayOptions = {
            key: "rzp_live_XSAC9SasdOEX81",
            // key: "rzp_test_mLYpnlz3ljIJ1f",
            amount: request.amount,
            currency: "INR",
            name: "AnyFeast",
            description: "Test Transaction",
            image: logo,
            order_id: orderId,
            handler: function (response: RazorpayResponse) {
              // console.log("Payment Success:", response);
              try {
                if (userId) {
                  const request = {
                    id: userId,
                    subscription_type: user
                  };
                  dispatch(subScribeBasicAsync(request));

                  const reqBody = {
                    country: countryCode
                  };

                  const deleteResponse = dispatch(deleteCartAsync({ id: userId, reqBody })).unwrap();
                  window.location.reload();
                }
              } catch (error) {
                console.error("Failed to delete address:", error);
              }
            },
            theme: {
              color: "red",
            },
            prefill: {
              method: "upi",
            },
            // method: {
            //   upi: true,
            // },
          };

          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        } else {
          console.error("Failed to create the order:", response.message);
        }
      }
    } catch (error) {
      console.error("Payment initiation failed:", error);
    }
  };

  return (
    <>
      <button
        className={`w-full py-2 ${isPaymentEnabled
          ? "border-green hover:bg-green-hover hover:text-white text-green"
          : "border-gray-400 text-gray-400 cursor-not-allowed"
          } border rounded-lg text-sm font-semibold`}
        onClick={handlePayment}
        disabled={!isPaymentEnabled}
      >
        PAY NOW (PREPAID)
      </button>
    </>
    // {/* {showMessage && (
    //   <div
    //     style={{
    //       position: "fixed",
    //       bottom: "20px",
    //       left: "50%",
    //       transform: "translateX(-50%)",
    //       backgroundColor: "red",
    //       color: "white",
    //       padding: "10px 20px",
    //       borderRadius: "5px",
    //       zIndex: 1000,
    //       boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    //     }}
    //   >
    //     Please select an address.
    //   </div>
    // )} */}
  );
};

export default RazorpayPayment;
