import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import error from "../../../src/assets/images/errorImage.png";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-red p-10 px-14 xl:p20 xl:px-24 lg:p-20 lg:px-24">
      <div className="flex lg:flex-row xl:flex-row flex-col items-center justify-between">

        {/* responsive image */}
        <div className="block lg:hidden xl:hidden mb-10">
          <img src={error} alt="404" className="md:w-[16rem] lg:[24rem]" />
        </div>

        {/* Oops text */}
        <div className="flex flex-col">
          <span className="text-6xl text-white font-bold">Ooops...</span>
          <span className="text-4xl text-white font-thin mt-4">
            Page Not Found
          </span>
          <span className="mt-6 text-white text-xs md:text-sm lg:text-md xl:text-md">
            Sorry. the content you’re looking for doesn’t exist. <br />
            Either it was removed, or you mistyped the link.
          </span>
          <button className="text-white font-medium bg-green rounded-md py-2 mt-16"
            onClick={() => navigate('/')}>
            Explore Recipes 
          </button>
        </div>

        {/* image */}
        <div className="hidden lg:block xl:block">
          <img src={error} alt="404" className="w-[36rem]" />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
