import Persona from './Persona'
import Filter from './Filter'
import ReceipeKit from '../Home/ReceipeKit'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { getByPersonaAsync } from '../../services/reducers/reciepeReducer';

const PersonaIndex = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const getInitialSlide = (id: string | undefined) => {
    switch (id) {
      case 'united-family':
        return 0;
      case 'busy-professional':
        return 1;
      case 'solution-seeker':
        return 2;
      case 'fitness-enthusiasts':
        return 3;
      default:
        return 4;
    }
  };
  const [selectedPersona, setSelectedPersona] = useState(getInitialSlide(id));
  const dispatch = useAppDispatch();
  const [personaDetails, setPersonaDetails] = useState<any>([]);
  const [displayedIds, setDisplayedIds] = useState<string[]>([]);
  const [selectedCuisines, setSelectedCuisines] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isVeg, setIsVeg] = useState(false);

  const personaMap: Record<number, string> = {
    0: "united family",
    1: "busy professional",
    2: "solution seeker",
    3: "fitness enthusiasts"
  };
  const sortOrder = selectedOption === "High to Low" ? "desc" : "asc";

  const storedCode = localStorage.getItem('countryCode');
  const storedCountry = localStorage.getItem("selectedCountry");

  // useEffect(() => {
  //   getPersonaRecipeDetails();
  // }, [selectedPersona, selectedCuisines, sortOrder, isVeg, selectedOption]);

  useEffect(() => {
    if (selectedPersona === 4) {
      navigate(`/${storedCountry}/404`); 
    } else {
      getPersonaRecipeDetails();
    }
  }, [selectedPersona, selectedCuisines, sortOrder, isVeg, selectedOption]);


  const getPersonaRecipeDetails = () => {
    if (selectedPersona !== undefined) {
      const persona = personaMap[selectedPersona];

      const request = {
        persona: persona,
        country: storedCode,
        veg: isVeg,
        sort: sortOrder,
        cuisines: selectedCuisines.length > 0 ? selectedCuisines.map(cuisine => cuisine.toLowerCase()) : [],
        page:1,
        pageSize:500,
        max_time: selectedOption === "Less than 15 Mins" ? "15 minutes" : selectedOption === "Less than 30 Mins" ? "30 minutes" : selectedOption === "Less than 45 Mins" ? "45 minutes" : selectedOption === "Less than 60 Mins" ? "60 minutes" : undefined
      };

      dispatch(getByPersonaAsync(request)).then((res) => {
        if (res.payload) {
          setPersonaDetails(res.payload);
          const ids = res.payload?.data?.map((item: any) => item.id);
          setDisplayedIds(ids);
        }
      });
    }
  };
console.log("selectedCuisines",selectedCuisines);
  return (
    <div>
      <Persona id={id} getInitialSlide={getInitialSlide} setSelectedPersona={setSelectedPersona} />
      <Filter setSelectedCuisines={setSelectedCuisines} selectedCuisines={selectedCuisines} selectedOption={selectedOption} setSelectedOption={setSelectedOption} getPersonaRecipeDetails={getPersonaRecipeDetails} setIsVeg={setIsVeg} isVeg={isVeg} persona={ personaMap[selectedPersona]}/>
      <ReceipeKit type={"Persona"} selectedPersona={selectedPersona} personaDetails={personaDetails} displayedIds={displayedIds} selectedCuisines={selectedCuisines}/>
      {/* <GallerySection/>  */}
    </div>
  )
}

export default PersonaIndex
