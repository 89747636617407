import { FC, useEffect, useState } from "react";
import placeholder from "../../../../src/assets/images/placeholderImage.png";
import snack from "../../../assets/images/snacks1.png";
import deleteIcon from "../../../assets/images/delete.png";
import { useNavigate } from "react-router-dom";
import CouponPopUp from "../Coupons";
import p9 from "../../../../src/assets/images/p-9.png";
import emptyCart from "../../../assets/images/EmptyCart.png";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import logo from "../../../../src/assets/images/footerLogo.png";
import axios from "axios";
import {
  addUpdateItemAsync,
  deleteCartAsync,
  getItemsByIdAsync,
  getSubscriptionInfoAsync,
} from "../../../services/reducers/reciepeReducer";
import LoginModal from "../../Home/LoginModal";
import { max } from "moment";

interface CartProps { }

const CartPage: FC<CartProps> = ({ }) => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [isCouponOpen, setIsCouponOpen] = useState(false);
  const [detail, setDetail] = useState<any>([]);
  const [wmartCouponCode, setWmartCouponCode] = useState("");
  const [cartTotal, setCartTotal] = useState<any>([]);
  const handleCheckoutView = () => {
    navigate(`/${storedCountry}/cart2`);
  };
  const handleCouponOpen = () => {
    setIsCouponOpen(true);
  };
  const handleCouponClose = () => {
    setIsCouponOpen(false);
  };
  const [cartTotalCount, setCartTotalCount] = useState(0);
  const carts = useAppSelector((state) => state.receipe.itemsList);
  const [ipAddress, setIpAddress] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  // const cartIngredients = useAppSelector((state) => state.cart);itemsList
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  //const cartIngredients = useAppSelector((state) => state.receipe.itemsList);
  const [cartIngredients, setCartIngredients] = useState<any>([]);
  const [cartAddress, setCartAddress] = useState<any>([]);
  const [extra, setExtra] = useState<any>([]);
  const userName = localStorage.getItem("firstName") || "User";
  const [loading, setLoading] = useState(true);
  const storedCountry = localStorage.getItem("selectedCountry");
  const storedCode = localStorage.getItem("countryCode");
  const guestId = localStorage.getItem("guestId");
  const [total, setTotal] = useState(0);
  let itemCount = 0;
  const keys = Object.keys(cartIngredients);
  const firstKey = keys[0];
  //const result = firstKey?.split("-")[0];
  //const ids = Number(firstKey?.split("-")[1]);
  if (keys.length > 0) {
    itemCount = cartIngredients[keys[0]].length;
  }
  const [selectedAddress, setSelectedAddress] = useState(null);
  const address = cartAddress.find(
    (addr: any) => addr.address_id === selectedAddress
  );
  const [isSubscription, setIsSubscription] = useState(false);
  const [isSubscriptionStatus, setIsSubscriptionStatus] = useState<number | null>(null);

  useEffect(() => {
    const country = storedCode ?? "";

    if (guestId) {
      dispatch(getItemsByIdAsync({ id: guestId, country })).then((res) => {
        if (res.payload) {
          setCartIngredients(res.payload?.data?.cart?.cart || {});
          setCartTotal(res.payload?.data?.cart || 0);
          setExtra(res.payload?.data?.extras || []);
        }
        new Promise((resolve) => setTimeout(resolve, 2000));
        setLoading(false);
      });
    }

  }, [guestId]);


  const clearCart = () => {
    if (userId) {
      const reqBody = {
        country: storedCode,
      };
      dispatch(deleteCartAsync({ id: userId, reqBody })).unwrap();
    }

    setCartIngredients({});
    setCartTotal({ cart_value: 0 });
    console.log("Cart has been cleared");
  };
  const calculateTotal = () => {
    const discount =
      detail?.discount_type === "percent"
        ? -(cartTotal?.cart_value * detail.discount) / 100
        : -detail.discount;

    const extraCharges = extra.reduce(
      (acc: any, curr: any) => acc + curr.charge,
      0
    );

    return (cartTotal?.cart_value || 0) + (discount || 0) + extraCharges;
  };

  useEffect(() => {
    setTotal(calculateTotal().toFixed(2));
  }, [cartTotal, detail, extra]);

  useEffect(() => { }, [detail]);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIpAddress();
  }, []);

  useEffect(() => {
    if (carts && carts.data.cart) {
      const cartCount = Object.values(carts.data.cart.lookup).flat().length;
      setCartTotalCount(cartCount);
      setCartIngredients(carts.data.cart.cart);
      setCartTotal(carts.data.cart);
      setExtra(carts.data.extras);
      // console.log(cartCount, "cartCount");
    }
  }, [carts]);

  const handleCartClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleUpdateItemQuantity = (
    dishKey: string,
    ingredient: any,
    change: number
  ) => {
    // if (!userId) {
    //   console.error("User ID is not available");
    //   return;
    // }
    const newQuantity = ingredient.selected + change;
    if (newQuantity < 0) return;

    const dishId = Number(dishKey.split("-")[1]);
    const dishName = dishKey.split("-")[0];
    // console.log("dishkey", dishKey);
    const request = {
      group: `${dishName}-${dishId}`,
      // group:`${groupName}-${groupId}`,
      // group:group,
      // group: `Shop- -1`,
      country: storedCode,
      items:
        newQuantity === 0
          ? [
            {
              ...ingredient,
              selected: 0,
            },
          ]
          : [
            {
              ...ingredient,
              selected: newQuantity,
            },
          ],
    };

    // console.log("req sent", request);
    const userOrGuestId = userId === null ? guestId : userId;
    if (userOrGuestId) {
      dispatch(addUpdateItemAsync({ id: userOrGuestId, request }))
        .then((res) => {
          if (res.payload && res.payload.success) {
            const updatedCart = { ...cartIngredients };
            // const updatedCart = res.payload.cart;
            // updatedCart[dishKey] = updatedCart[dishKey].map((item: any) =>
            //     item.id === ingredient.id ? { ...item, selected: newQuantity } : item
            // );
            // seCartIngredients(updatedCart);
            //const shopKey = `Shop- -${dishId}`;
            // console.log(shopKey);
            if (newQuantity === 0) {
              updatedCart[dishKey] = updatedCart[dishKey].filter(
                (item: any) => item.id !== ingredient.id
              );
              if (updatedCart[dishKey].length === 0) {
                delete updatedCart[dishKey];
              }
            } else {
              updatedCart[dishKey] = updatedCart[dishKey] || [];
              updatedCart[dishKey] = updatedCart[dishKey].map((item: any) =>
                item.id === ingredient.id
                  ? { ...item, selected: newQuantity }
                  : item
              );
            }
            for (const shopKey in updatedCart) {
              if (updatedCart[shopKey].length === 0) {
                delete updatedCart[shopKey];
              }
            }

            const country = storedCode ?? "";
            if (userOrGuestId) {
              dispatch(getItemsByIdAsync({ id: userOrGuestId, country })).then(
                (res) => {
                  if (res.payload) {
                    setCartIngredients(res.payload?.data?.cart?.cart || {});
                    setCartTotal(res.payload?.data?.cart || 0);
                    setExtra(res.payload?.data?.extras || []);
                  }
                }
              );
            }
          } else {
            console.error("failed tp update");
          }
        })
        .catch((err) => console.error("Error updating:", err));
    }
  };
  const handleView = () => {
    navigate(`/${storedCountry}/subscription`);
  };
  console.log("Cart", cartIngredients);

  const handleShopView = () => {
    navigate(`/${storedCountry}/shop`);
  };

  const userOrGuestId = userId === null ? guestId : userId;

  const handleCheckoutsView = () => {
    if (userId === null) {
      console.error("User ID is not available");
      setIsModalOpen(true);
      return;
    }

    navigate(`/${storedCountry}/checkout`);
  };
  const user = useAppSelector((state) => state.receipe.user);
  console.log(user, "user");

  useEffect(() => {
    if (userId) {
      dispatch(getSubscriptionInfoAsync(userId)).then((res) => {
        if (res.payload) {
          setIsSubscription(res.payload.data.defaultSubscription)
          setIsSubscriptionStatus(res.payload.data.subscription_status);
          // const subscriptionDateTimestamp = new Date(
          //   res.payload.data.subscription_date
          // ).getTime();
          //   const currentDateTimestamp = res.payload.data.current_date;
          //   //const status = res.payload.data.subscription_status;
          //   // console.log(subscriptionDateTimestamp, "subscriptionDateTimestamp");
          //   // Calculate the difference in days
          //   const differenceInTime =
          //     currentDateTimestamp - subscriptionDateTimestamp;
          //   const differenceInDays = differenceInTime / (1000 * 3600 * 24);

          //   if (differenceInDays > 3650) {
          //     // console.log("Difference in days is greater than 3650.");
          //     navigate(`/${storedCountry}/subscription`);
          //   }

          //   // if (subscriptionDateTimestamp > currentDateTimestamp) {
          //   //     console.log("Subscription date is greater than current date.");
          //   //   } else if (status === 0 && subscriptionDateTimestamp < currentDateTimestamp) {
          //   //     console.log("Current date is greater than subscription date.");
          //   //     navigate(`/subscription`);
          //   //   }
        }
      });
    }
  }, []);
  console.log(isSubscription, "isSubscription");
  console.log(isSubscriptionStatus, "isSubscriptionStatus");
  return (
    <div>
      {loading ? (
        <div className="min-h-screen flex justify-center items-center">
          <div role="status">
            <svg
              aria-hidden="true"
              className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="red"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="gray"
              />
            </svg>
          </div>
          {/* <div>
          <p className="font-medium mt-6 font-sans text-xl">Loading...</p>
          </div> */}
        </div>
      ) : Object.keys(cartIngredients).length === 0 ? (
        <>
          <center>
            <div className="min-h-screen flex justify-center items-center pb-32 flex-col bg-white">
              <div className="flex flex-col justify-center items-center h-full text-center">
                <img
                  src={emptyCart}
                  alt="Main Banner"
                  className="w-3/4 sm:w-2/3 md:w-1/2 h-auto"
                />
                {/* <p className="font-medium mt-6 font-sans text-xl">
                  Your cart is empty
                </p>
                <span className="font-sans text-gray-600 mt-2 block text-lg">
                  You can go to shop page to add ingredients
                </span> */}
              </div>
              <button
                className="px-10 py-2 bg-green text-white rounded-lg hover:bg-green-hover font-sans mt-6"
                onClick={() => handleShopView()}
              >
                ADD ITEMS FOR YOUR FEAST
              </button>
            </div>
          </center>
        </>
      ) : (
        <>
          <div className="lg:p-10 p-6">
            {/* Premium Section */}
            <div className="bg-white rounded-sm border font-sans mb-4 lg:hidden">
              <div className="bg-[#440508] text-white text-center py-2 rounded-t-sm">
                <span className="text-sm font-medium">
                  Unlock Total Benefits Upto 20% off on this order
                </span>
              </div>

              <div className="flex justify-between items-center p-2 border-dashed border-b font-sans">
                <div>
                  <h2 className="text-red-700 font-bold text-md">
                    AnyFeast Premium
                  </h2>
                </div>
                <div onClick={() => handleView()}>
                  <a
                    href="#"
                    className="text-red-700 font-medium flex items-center text-sm"
                  >
                    Know more
                    <svg
                      className="ml-2"
                      width="7"
                      height="10"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1.26074L6 5.99992L1 10.7391"
                        stroke="red"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>

              {/* Benefits Section */}
              <div className="px-4 pt-2 font-sans text-sm">
                <h3 className="font-bold underline">Benefits</h3>
                <ul className="list-disc pl-5 mt-2">
                  <li>20% discount on all meal kit orders.</li>
                  <li>Free Delivery.</li>
                </ul>
              </div>

              {/* See All Link */}
              <div className="flex justify-end px-4 pb-2 font-sans">
                <a
                  href="#"
                  className="text-red-700 font-medium flex items-center text-sm"
                >
                  See All
                  <svg
                    className="ml-2"
                    width="7"
                    height="10"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.26074L6 5.99992L1 10.7391"
                      stroke="red"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>

            <span className="text-xl font-bold">My Cart</span>

            <div className="flex">
              {/* Left Side -  Cart Items */}
              <div className="lg:w-[70%] lg:mr-4 w-full md:w-full">
                {/* <div className="flex flex-row items-center mt-4">
            <span>2 item(s) for Taco Salad</span>
            <div className="flex-grow border-b border-gray-300 ml-4"></div>
          </div> */}

                <div className="flex-grow overflow-y-auto">
                  {/* Item Card */}
                  <div className="w-full">
                    {Object.keys(cartIngredients).map((dishKey: string) => {
                      const dishName = dishKey.split("-")[0];
                      //const dishId = Number(dishKey.split("-")[1]);
                      const ingredients = cartIngredients[dishKey];

                      return (
                        <div key={dishKey} className="mb-6 w-full">
                          <div className="flex flex-row items-center mt-4">
                            <span>
                              {ingredients.length} item(s) for {dishName}{" "}
                            </span>
                            <div className="flex-grow border-b border-gray-300 ml-4"></div>
                          </div>
                          <ul className="flex-grow overflow-y-auto w-full">
                            {ingredients.map(
                              (ingredient: any, index: number) => (
                                <li
                                  key={index}
                                  className="flex items-center justify-between w-full"
                                >
                                  <div className="border lg:p-4 p-2 mt-6 w-full">
                                    <div className="flex flex-row justify-between items-center">
                                      {/* Image and Details Section */}
                                      <div className="flex flex-row flex-1 items-center">
                                        {/* Image */}
                                        <div className="w-10 h-10 lg:w-16 lg:h-16 xl:w-16 xl:h-16 md:w-16 md:h-16">
                                          <img
                                            src={ingredient?.image && ingredient?.image !== "NaN" ? ingredient?.image : placeholder}
                                            alt={ingredient.name}
                                            className="w-full h-full object-cover rounded-lg"
                                          />
                                        </div>

                                        {/* Details */}
                                        <div className="ml-4 flex flex-col">
                                          <span className="font-medium lg:text-lg md:text-lg text-sm">
                                            {ingredient?.name.charAt(0).toUpperCase() + ingredient?.name.slice(1)}
                                          </span>
                                          <div className="flex flex-row items-center mt-1">
                                            <span className="lg:text-[15px] md:text-[15px] text-xs">
                                              Variant:{" "}
                                            </span>
                                            <span className="font-medium lg:text-lg md:text-[16px] text-xs ml-1">
                                              {ingredient.quantity}
                                            </span>
                                          </div>
                                          <span className="lg:text-lg text-xs lg:hidden md:hidden mt-1">
                                            You pay:{" "}
                                            <span className="font-medium">
                                              {storedCode === "IN" ? "₹" : "£"}
                                              {ingredient.price}
                                            </span>
                                          </span>
                                        </div>
                                      </div>

                                      {/* Amount Section */}
                                      <div className="w-[80px] text-center lg:block hidden md:block">
                                        <span className="font-medium lg:text-lg md:text-lg text-sm">
                                          {storedCode === "IN" ? "₹" : "£"}
                                          {ingredient.price}
                                        </span>
                                      </div>

                                      {/* Quantity Selector */}
                                      <div className="lg:ml-20 md:ml-20 flex flex-row items-center justify-end lg:text-md text-sm">
                                        <div className="flex items-center bg-[green] text-white lg:rounded-lg rounded-md px-2 py-1 font-sans">
                                          <button
                                            className="px-1 md:px-2 lg:px-2 py-1"
                                            aria-label="Decrease servings"
                                            onClick={() =>
                                              handleUpdateItemQuantity(
                                                dishKey,
                                                ingredient,
                                                -1
                                              )
                                            }
                                          >
                                            -
                                          </button>
                                          <span className="mx-4 md:px-1">
                                            {ingredient.selected}
                                          </span>
                                          <button
                                            className="px-1 md:px-2 lg:px-2 py-1"
                                            aria-label="Increase servings"
                                            onClick={() =>
                                              handleUpdateItemQuantity(
                                                dishKey,
                                                ingredient,
                                                1
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                        <button
                                          className="ml-4"
                                          onClick={() =>
                                            handleUpdateItemQuantity(
                                              dishKey,
                                              ingredient,
                                              -ingredient.selected
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 50 50"
                                            className="w-5 h-4"
                                          >
                                            <path
                                              stroke="black"
                                              strokeWidth={1}
                                              d="M 21 2 C 19.354545 2 18 3.3545455 18 5 L 18 7 L 10.154297 7 A 1.0001 1.0001 0 0 0 9.984375 6.9863281 A 1.0001 1.0001 0 0 0 9.8398438 7 L 8 7 A 1.0001 1.0001 0 1 0 8 9 L 9 9 L 9 45 C 9 46.645455 10.354545 48 12 48 L 38 48 C 39.645455 48 41 46.645455 41 45 L 41 9 L 42 9 A 1.0001 1.0001 0 1 0 42 7 L 40.167969 7 A 1.0001 1.0001 0 0 0 39.841797 7 L 32 7 L 32 5 C 32 3.3545455 30.645455 2 29 2 L 21 2 z M 21 4 L 29 4 C 29.554545 4 30 4.4454545 30 5 L 30 7 L 20 7 L 20 5 C 20 4.4454545 20.445455 4 21 4 z M 11 9 L 18.832031 9 A 1.0001 1.0001 0 0 0 19.158203 9 L 30.832031 9 A 1.0001 1.0001 0 0 0 31.158203 9 L 39 9 L 39 45 C 39 45.554545 38.554545 46 38 46 L 12 46 C 11.445455 46 11 45.554545 11 45 L 11 9 z M 18.984375 13.986328 A 1.0001 1.0001 0 0 0 18 15 L 18 40 A 1.0001 1.0001 0 1 0 20 40 L 20 15 A 1.0001 1.0001 0 0 0 18.984375 13.986328 z M 24.984375 13.986328 A 1.0001 1.0001 0 0 0 24 15 L 24 40 A 1.0001 1.0001 0 1 0 26 40 L 26 15 A 1.0001 1.0001 0 0 0 24.984375 13.986328 z M 30.984375 13.986328 A 1.0001 1.0001 0 0 0 30 15 L 30 40 A 1.0001 1.0001 0 1 0 32 40 L 32 15 A 1.0001 1.0001 0 0 0 30.984375 13.986328 z"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      );
                    })}
                    {(isSubscription === true && user === 0) && (
                      <div className="mb-6 w-full">
                        <div className="flex flex-row items-center mt-4">
                          <span>
                            1 item for Subscription{" "}
                          </span>
                          <div className="flex-grow border-b border-gray-300 ml-4"></div>
                        </div>
                        <ul className="flex-grow overflow-y-auto w-full">

                          <li

                            className="flex items-center justify-between w-full"
                          >
                            <div className="border lg:p-4 p-2 mt-6 w-full">
                              <div className="flex flex-row justify-between items-center">
                                {/* Image and Details Section */}
                                <div className="flex flex-row flex-1 items-center">
                                  {/* Image */}
                                  <div className="w-10 h-10 lg:w-16 lg:h-16 xl:w-16 xl:h-16 md:w-16 md:h-16">
                                    <img
                                      src={logo ||
                                        placeholder
                                      }
                                      alt={"AnyFeast Logo"}
                                      style={{
                                        maxWidth: "400%",

                                      }}
                                    //className="w-full h-full object-cover rounded-lg"
                                    />
                                  </div>

                                  {/* Details */}
                                  <div className="ml-4 flex flex-col">
                                    <span className="font-medium lg:text-lg md:text-lg text-sm">
                                      Subscription
                                    </span>
                                    <div className="flex flex-row items-center mt-1">
                                      <span className="lg:text-[15px] md:text-[15px] text-xs">
                                        Item:{" "}
                                      </span>
                                      <span className="font-medium lg:text-lg md:text-[16px] text-xs ml-1">
                                        1
                                      </span>
                                    </div>
                                    <span className="lg:text-lg text-xs lg:hidden md:hidden mt-1">
                                      You pay:{" "}
                                      <span className="font-medium">
                                        {storedCode === "IN" ? "₹" : "£"}
                                        {user === 0 ? 0 : user === 1 ? (storedCode === "UK" ? 10 : 149) : (storedCode === "UK" ? 20 : 599)}
                                      </span>
                                    </span>
                                  </div>
                                </div>

                                {/* Amount Section */}
                                <div className="w-[80px] text-center lg:block hidden md:block">
                                  <span className="font-medium lg:text-lg md:text-lg text-sm">
                                    {storedCode === "IN" ? "₹" : "£"}
                                    {user === 0 ? 0 : user === 1 ? (storedCode === "UK" ? 10 : 149) : (storedCode === "UK" ? 20 : 599)}
                                  </span>
                                </div>

                                {/* Quantity Selector */}




                              </div>
                            </div>

                          </li>

                        </ul>
                      </div>
                    )}
                    {(isSubscription === false && user !== null && (isSubscriptionStatus === null || isSubscriptionStatus < user)) && (
                      <div className="mb-6 w-full">
                        <div className="flex flex-row items-center mt-4">
                          <span>
                            1 item for Subscription{" "}
                          </span>
                          <div className="flex-grow border-b border-gray-300 ml-4"></div>
                        </div>
                        <ul className="flex-grow overflow-y-auto w-full">

                          <li

                            className="flex items-center justify-between w-full"
                          >
                            <div className="border lg:p-4 p-2 mt-6 w-full">
                              <div className="flex flex-row justify-between items-center">
                                {/* Image and Details Section */}
                                <div className="flex flex-row flex-1 items-center">
                                  {/* Image */}
                                  <div className="w-10 h-10 lg:w-16 lg:h-16 xl:w-16 xl:h-16 md:w-16 md:h-16">
                                    <img
                                      src={logo ||
                                        placeholder
                                      }
                                      alt={"AnyFeast Logo"}
                                      style={{
                                        maxWidth: "400%",

                                      }}
                                    //className="w-full h-full object-cover rounded-lg"
                                    />
                                  </div>

                                  {/* Details */}
                                  <div className="ml-4 flex flex-col">
                                    <span className="font-medium lg:text-lg md:text-lg text-sm">
                                      Subscription
                                    </span>
                                    <div className="flex flex-row items-center mt-1">
                                      <span className="lg:text-[15px] md:text-[15px] text-xs">
                                        Item:{" "}
                                      </span>
                                      <span className="font-medium lg:text-lg md:text-[16px] text-xs ml-1">
                                        1
                                      </span>
                                    </div>
                                    <span className="lg:text-lg text-xs lg:hidden md:hidden mt-1">
                                      You pay:{" "}
                                      <span className="font-medium">
                                        {storedCode === "IN" ? "₹" : "£"}
                                        {user === 0 ? 0 : user === 1 ? (storedCode === "UK" ? 10 : 149) : (storedCode === "UK" ? 20 : 599)}
                                      </span>
                                    </span>
                                  </div>
                                </div>

                                {/* Amount Section */}
                                <div className="w-[80px] text-center lg:block hidden md:block">
                                  <span className="font-medium lg:text-lg md:text-lg text-sm">
                                    {storedCode === "IN" ? "₹" : "£"}
                                    {user === 0 ? 0 : user === 1 ? (storedCode === "UK" ? 10 : 149) : (storedCode === "UK" ? 20 : 599)}
                                  </span>
                                </div>

                                {/* Quantity Selector */}




                              </div>
                            </div>

                          </li>

                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="flex flex-row items-center justify-end w-full cursor-pointer"
                  onClick={clearCart}
                >
                  {/* <img src={deleteIcon} className="w-4 h-4" /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    color="red"
                    className="w-5 h-5 text-red pr-1"
                  >
                    <path
                      stroke="red"
                      strokeWidth={1}
                      d="M 21 2 C 19.354545 2 18 3.3545455 18 5 L 18 7 L 10.154297 7 A 1.0001 1.0001 0 0 0 9.984375 6.9863281 A 1.0001 1.0001 0 0 0 9.8398438 7 L 8 7 A 1.0001 1.0001 0 1 0 8 9 L 9 9 L 9 45 C 9 46.645455 10.354545 48 12 48 L 38 48 C 39.645455 48 41 46.645455 41 45 L 41 9 L 42 9 A 1.0001 1.0001 0 1 0 42 7 L 40.167969 7 A 1.0001 1.0001 0 0 0 39.841797 7 L 32 7 L 32 5 C 32 3.3545455 30.645455 2 29 2 L 21 2 z M 21 4 L 29 4 C 29.554545 4 30 4.4454545 30 5 L 30 7 L 20 7 L 20 5 C 20 4.4454545 20.445455 4 21 4 z M 11 9 L 18.832031 9 A 1.0001 1.0001 0 0 0 19.158203 9 L 30.832031 9 A 1.0001 1.0001 0 0 0 31.158203 9 L 39 9 L 39 45 C 39 45.554545 38.554545 46 38 46 L 12 46 C 11.445455 46 11 45.554545 11 45 L 11 9 z M 18.984375 13.986328 A 1.0001 1.0001 0 0 0 18 15 L 18 40 A 1.0001 1.0001 0 1 0 20 40 L 20 15 A 1.0001 1.0001 0 0 0 18.984375 13.986328 z M 24.984375 13.986328 A 1.0001 1.0001 0 0 0 24 15 L 24 40 A 1.0001 1.0001 0 1 0 26 40 L 26 15 A 1.0001 1.0001 0 0 0 24.984375 13.986328 z M 30.984375 13.986328 A 1.0001 1.0001 0 0 0 30 15 L 30 40 A 1.0001 1.0001 0 1 0 32 40 L 32 15 A 1.0001 1.0001 0 0 0 30.984375 13.986328 z"
                    />
                  </svg>
                  <span className="text-red">Remove all</span>
                </div>

                {/* Coupons Code */}
                {userId && (
                  <div
                    className="bg-white p-2 rounded-sm border font-sans cursor-pointer lg:hidden my-4"
                    onClick={handleCouponOpen}
                  >
                    <div className="flex items-center justify-between ">
                      <div className="flex items-center">
                        <img
                          src={p9}
                          alt="Add UPI ID"
                          className="w-4 h-4 mr-2"
                        />
                        <h3 className="text-sm">Apply coupons & Offers</h3>
                      </div>
                      <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L6 6L1 11"
                          stroke="#313131"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                <CouponPopUp
                  isOpen={isCouponOpen}
                  onClose={handleCouponClose}
                  setDetail={setDetail}
                  total={cartTotal?.cart_value}
                  setWmartCouponCode={setWmartCouponCode}
                />

                <div className="lg:hidden my-10">
                  <button
                    className="w-full py-2 bg-green hover:bg-green-hover text-white rounded-lg"
                    onClick={handleCheckoutsView}
                  >

                    Proceed to Checkout
                  </button>
                </div>
              </div>

              {/* Right Side - Order Summary */}
              <div className="lg:w-[30%] p-4 ml-6 hidden md:hidden lg:block">
                <div className="flex flex-col gap-y-4">
                  {/* Premium Section */}
                  <div className="rounded-lg border font-sans">
                    <div className="bg-[#440508] text-white text-center py-2 rounded-t-lg">
                      <span className="text-sm font-medium px-1">
                        Unlock Total Benefits Upto 20% off on this order
                      </span>
                    </div>

                    <div className="flex justify-between items-center p-2 font-sans">
                      <div>
                        <h2 className="text-red-700 font-bold text-md">
                          AnyFeast Premium
                        </h2>
                      </div>
                      <div onClick={() => handleView()}>
                        <a
                          href="#"
                          className="text-red-700 font-medium flex items-center text-sm"
                        >
                          Know more
                          <svg
                            className="ml-2"
                            width="7"
                            height="10"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1.26074L6 5.99992L1 10.7391"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>

                    {/* Benefits Section */}
                    {/* <div className="p-2 font-sans">
                <h3 className="font-bold text-md underline">Benefits</h3>
                <ul className="list-disc pl-5 mt-2">
                  <li>20% discount on all meal kit orders.</li>
                  <li>Free Delivery.</li>
                </ul>
              </div> */}

                    {/* See All Link */}
                    {/* <div className="flex justify-end px-4 pb-2 font-sans text-sm">
                <a
                  href="#"
                  className="text-red-700 font-medium flex items-center"
                >
                  See All
                  <svg
                    className="ml-2"
                    width="7"
                    height="10"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.26074L6 5.99992L1 10.7391"
                      stroke="red"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div> */}
                  </div>

                  {userId && (
                    <div
                      className="bg-white p-2 rounded-lg border font-sans cursor-pointer"
                      onClick={handleCouponOpen}
                    >
                      <div className="flex items-center justify-between ">
                        <div className="flex items-center">
                          <img
                            src={p9}
                            alt="Add UPI ID"
                            className="w-5 h-5 mr-2"
                          />
                          <h3 className="text-md">Apply coupons & Offers</h3>
                        </div>
                        <svg
                          width="7"
                          height="10"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L6 6L1 11"
                            stroke="#313131"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                  <CouponPopUp
                    isOpen={isCouponOpen}
                    onClose={handleCouponClose}
                    setDetail={setDetail}
                    total={cartTotal?.cart_value}
                    setWmartCouponCode={setWmartCouponCode}
                  />

                  {/* Offers */}
                  {/* <div className="py-2 bg-yellow-100 rounded-lg w-full px-4 items-end justify-center flex">
              <span className="text-xs">
                {" "}
                🎂 All offer(s) will be applied on checkout
              </span>
            </div> */}

                  {/* Price Summary */}
                  <div className="flex flex-col border rounded-lg">
                    {/* <div className="flex flex-row justify-between border-b p-4">
                      <span className="text-lg font-medium">Price Summary</span>
                      <span className="font-semibold text-black">
                        {storedCode === "IN" ? "₹" : "£"} {total}
                      </span>
                    </div> */}
                    {wmartCouponCode === "WMART75" ? (
                      detail && detail.discount ? (
                        <div className="flex flex-row justify-between border-b p-4">
                          <span className="text-lg font-medium">Price Summary</span>
                          <span className="font-semibold text-black">
                            {storedCode === "IN" ? "₹" : "£"} {total + (user === 0 ? 0 : user === 1 ? (storedCode === "UK" ? 10 : 149) : user === 2 ? (storedCode === "UK" ? 20 : 599) : 0)}
                          </span>
                        </div>
                      ) : (
                        <div className="flex flex-row justify-between border-b p-4">
                          <span className="text-lg font-medium">Price Summary</span>
                          <span className="font-semibold text-black">
                            {storedCode === "IN" ? "₹" : "£"}
                            {cartTotal?.cart_value +
                              extra.reduce(
                                (acc: any, curr: any) => acc + curr.charge,
                                0
                              ) + (user === 0 ? 0 : user === 1 ? (storedCode === "UK" ? 10 : 149) : user === 2 ? (storedCode === "UK" ? 20 : 599) : 0)}
                          </span>
                        </div>
                      )
                    ) : detail &&
                      detail.discount &&
                      parseFloat(detail.discount) < cartTotal?.cart_value ? (
                      <div className="flex flex-row justify-between border-b p-4">
                        <span className="text-lg font-medium">Price Summary</span>
                        <span className="font-semibold text-black">
                          {storedCode === "IN" ? "₹" : "£"} {total + (user === 0 ? 0 : user === 1 ? (storedCode === "UK" ? 10 : 149) : user === 2 ? (storedCode === "UK" ? 20 : 599) : 0)}
                        </span>
                      </div>
                    ) : (
                      <div className="flex flex-row justify-between border-b p-4">
                        <span className="text-lg font-medium">Price Summary</span>
                        <span className="font-semibold text-black">
                          {storedCode === "IN" ? "₹" : "£"}
                          {cartTotal?.cart_value +
                            extra.reduce(
                              (acc: any, curr: any) => acc + curr.charge,
                              0
                            ) + (user === 0 ? 0 : user === 1 ? (storedCode === "UK" ? 10 : 149) : user === 2 ? (storedCode === "UK" ? 20 : 599) : 0)}
                        </span>
                      </div>
                    )}

                    <div className="flex flex-col px-4">
                      <div className="flex flex-row justify-between py-4">
                        <span className="text-md text-greytext">Subtotal</span>
                        <span className="font-semibold">
                          {storedCode === "IN" ? "₹" : "£"}{" "}
                          {cartTotal?.cart_value}.00
                        </span>
                      </div>

                      {extra.map((extra: any, index: number) => (
                        <div
                          key={index}
                          className="flex flex-row justify-between py-4 border-t"
                        >
                          <span className="text-md text-greytext">
                            {extra.name}
                          </span>
                          <span className="font-semibold text-red">
                            + {storedCode === "IN" ? "₹" : "£"}
                            {extra.charge}
                          </span>
                        </div>
                      ))}
                      {extra && extra.length > 0 ? null : (
                        <div className="flex flex-row justify-between py-4 border-t">
                          <span className="text-md text-greytext">
                            Delivery Charges
                          </span>
                          <span className="font-semibold text-red">
                            You get Free delivery!
                          </span>
                        </div>
                      )}

                      {isSubscription === true && user === 0 && (
                        <div className="flex flex-row justify-between py-4 border-t">
                          <span className="text-md text-greytext">
                            Subscription Charges
                          </span>
                          <span className="font-semibold text-red">
                            You get Free Plan!
                          </span>
                        </div>
                      )}

                      {/* {(isSubscription === false && user !== null && isSubscriptionStatus && isSubscriptionStatus < user) && ( */}
                      {(isSubscription === false && user !== null && (isSubscriptionStatus === null || isSubscriptionStatus < user)) && (
                        <div

                          className="flex flex-row justify-between py-4 border-t"
                        >
                          <span className="text-md text-greytext">
                            Subscription Charges
                          </span>
                          <span className="font-semibold text-red">
                            + {storedCode === "IN" ? "₹" : "£"}
                            {user === 0 ? 0 : user === 1 ? (storedCode === "UK" ? 10 : 149) : user === 2 ? (storedCode === "UK" ? 20 : 599) : null}
                          </span>
                        </div>
                      )}
                      {detail &&
                        detail.discount &&
                        (wmartCouponCode === "WMART75" ? (
                          <div className="flex flex-col">
                            <div className="flex flex-row justify-between py-4 border-t">
                              <span className="text-md text-greytext">Discount</span>
                              <span className="font-semibold text-[#28A745]">
                                - {storedCode === "IN" ? "₹" : "£"}
                                {detail.discount_type === "percent"
                                  ? (cartTotal?.cart_value * detail.discount) / 100
                                  : detail.discount}
                              </span>
                            </div>
                          </div>
                        ) : (
                          parseFloat(detail.discount) < cartTotal?.cart_value && (
                            <div className="flex flex-col">
                              <div className="flex flex-row justify-between py-4 border-t">
                                <span className="text-md text-greytext">Discount</span>
                                <span className="font-semibold text-[#28A745]">
                                  - {storedCode === "IN" ? "₹" : "£"}
                                  {detail.discount_type === "percent"
                                    ? (cartTotal?.cart_value * detail.discount) / 100
                                    : detail.discount}
                                </span>
                              </div>
                            </div>
                          )
                        ))}
                    </div>

                    {/* Tax info */}
                    {/* <div className="bg-blue-100 p-2">
                <span className="text-xs text-greytext">
                  Tax of <span className="font-semibold"> ₹ 29.14</span> has
                  been included in the total amount
                </span>
              </div> */}
                  </div>

                  {/* Checkout Button */}
                  <div>
                    <button
                      className="w-full py-2 bg-green hover:bg-green-hover text-white rounded-lg"
                      // onClick={() => navigate(`/${storedCountry}/checkout`)}
                      onClick={handleCheckoutsView}
                    >
                      Proceed to Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isModalOpen && (
              <LoginModal
                onClose={() => {
                  setIsModalOpen(false);
                }}
                isOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setGetPhone={setAddressModalOpen}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CartPage;
