import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner: React.FC = () => {
  const [showDeclineButton, setShowDeclineButton] = useState(false);

  return (
    <CookieConsent
      location="bottom"
      buttonText="I Accept"
      cookieName="user_accepted_cookies"
      style={{ background: "Orange", padding: "0" }}
      buttonStyle={{
        backgroundColor: 'green', 
        color: 'white',
        fontSize: '16px',
        // padding: '10px 20px',
        // borderRadius: '5px',
        marginRight: '100px',
      }}
      declineButtonText="Decline"
      enableDeclineButton
      onAccept={() => {
        console.log('Cookies accepted');
        localStorage.setItem('cookies_accepted', 'true');
      }}
      onDecline={() => {
        console.log('Cookies declined');
        localStorage.setItem('cookies_accepted', 'false');
      }}
    >
      <div className="text-white text-center font-sans text-lg font-bold">
      AnyFeast uses cookies to enhance the user experience . By continuing to use this website, you accept our cookie policy.
      </div>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
