import React, { useEffect, useState } from "react";
import image1 from "../../assets/images/b2.png";
import image2 from "../../assets/images/b3.png";
import image3 from "../../assets/images/b4.png";
import image4 from "../../assets/images/b5.png";
import image6 from "../../assets/images/b6.png";
import image7 from "../../assets/images/b7.png";
import image8 from "../../assets/images/b8.png";
import logo from "../../../src/assets/images/logoo.png";
import LikeCommentShare from "./LikeCommentShare";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { getAllTagAsync, getBlogByIdAsync, getViewCountAsync } from "../../services/reducers/reciepeReducer";
import moment from "moment";
import DOMPurify from 'dompurify';
import placeholder from '../../../src/assets/images/placeholderImage.png';
import { truncateText } from "../../utils/common";
import MetaTags from "../MetaTags";

const Blog: React.FC = () => {
  const { title } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getBlog, setGetBlog] = useState<any>([]);
  const [getPopularBlog, setGetPopularBlog] = useState<any>([]);
  const [getRelatedBlog, setRelatedBlog] = useState<any>([]);
  const [visibleBlogs, setVisibleBlogs] = useState(5);
  const storedCountry = localStorage.getItem("selectedCountry");

  const convertDateWithMoment = (isoDate: string): string => {
    return moment(isoDate).format("MMMM D, YYYY");
  };

  useEffect(() => {
    getDetails();
  }, [title]);
  // console.log(title, "tit")
  const getDetails = () => {
    if (title) {
      const decodedTitle = decodeURIComponent(title);
      dispatch(getBlogByIdAsync(title)).then((res) => {
        if (res.payload) {
          setGetBlog(res.payload?.data);
        }
        else{
          navigate(`/${storedCountry}/404`); 
        }
      });
    }
  }

  const sanitizedContent = DOMPurify.sanitize(getBlog.content);

  useEffect(() => {
    dispatch(getViewCountAsync()).then((res) => {
      if (res.payload) {
        setGetPopularBlog(res.payload?.data);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getAllTagAsync(getBlog?.tag)).then((res) => {
      if (res.payload) {
        setRelatedBlog(res.payload?.data);
      }
    });
  }, [getBlog?.tag]);

  const handleShowMore = () => {
    setVisibleBlogs(visibleBlogs + 5);
  };

  const handleShowLess = () => {
    setVisibleBlogs(5);
  };

  return (
    <div className="p-4 sm:p-8 lg:p-10">
       <MetaTags
        title={`${getBlog.title?.replace(/!¬†¬†|¬†/g, '')}`}
        description={getBlog.slug
          
        }
      />
      <h2 className="text-base sm:text-3xl font-semibold mb-4 mt-15">
        Blog
      </h2>
      <h3 className="text-[22px] lg:text-[46px] text-[#172B4D] lg:font-medium font-semibold sm:mt-10 lg:mr-10">
        {getBlog.title?.replace(/!¬†¬†|¬†/g, '')}
      </h3>

      <div className="flex items-center space-x-2 pt-5 sm:mt-6 mb-8">
        <img src={logo} alt="Author" className="w-10 h-8" />
        <div className="flex flex-col justify-center">
          <p className="lg:text-[15px] text-xs font-normal text-[#172B4D]">
            By Anyfeast
            <span className="block sm:inline">
              <span className="hidden sm:inline"> | </span>
              Published on {convertDateWithMoment(getBlog.
                post_date)
              }
              {/* |{getBlog.reading_time_minutes
              } min read */}
            </span>
          </p>
        </div>
      </div>

      <img
        src={getBlog?.image1 && getBlog?.image1 !== "NaN" ? getBlog?.image1 : placeholder}
        alt="Main Blog"
        className="w-full lg:h-[550px] h-[200px] mb-6 object-cover"
      />

      <div className="lg:flex lg:space-x-8">

        <div className="content prose lg:prose-lg flex-1" dangerouslySetInnerHTML={{ __html: sanitizedContent }} />

        <div className="lg:w-1/4 mt-12 mr-8 lg:mt-0">
          <h4 className="text-md font-normal text-[#42526E] mb-4">Popular Posts</h4>
          <div className="space-y-6">
           
            {getPopularBlog?.slice(0, visibleBlogs)?.map((blog: any) => (
              <div key={blog.id}>
                <Link to={`/${storedCountry}/blog/${blog.slug}`}>
                  <div className="flex items-center space-x-4 mb-4">
                    <img
                      src={blog?.featured_image_url && blog?.featured_image_url !== "NaN" ? blog?.featured_image_urlitem?.info?.image : placeholder}
                      alt={blog.title}
                      className="w-20 h-20 rounded-md"
                    />
                    <div className="pr-3">
                      <p className="text-[10px] font-extralight text-[#42526E]">BLOG</p>
                      <p className="text-md font-light text-[#172B4D]">{blog.title}</p>
                    </div>
                  </div>
                </Link>
                <hr className="border-t-2 border-[#8797B1]" />
              </div>
            ))}

            {visibleBlogs < 10 && (
              <button
                onClick={handleShowMore}
                className="text-green font-semibold mt-4"
              >
                Show More
              </button>
            )}
            {visibleBlogs > 5 && (
              <button
                onClick={handleShowLess}
                className="text-green font-semibold mt-4"
              >
                Show Less
              </button>
            )}

          </div>
        </div>
      </div>

      {/* Full-width Section for large screens */}
      <div className="hidden lg:block font-normal leading-8 lg:text-[20px]  space-y-4 lg:space-y-4 lg:font-normal">
        {/* <p className="">
          <h5 className="font-bold text-2xl py-2">
            4. Sustainable and Waste-Free Cooking
          </h5>
          The holidays often lead to food waste, but not with AnyFeast. Each
          Christmas Box is designed with sustainability in mind. Portions are
          pre-measured to minimize waste, and the packaging is eco-friendly. By
          choosing AnyFeast, you’re not just making your holiday easier—you’re
          also contributing to a greener planet.
          <h5 className="font-bold text-2xl py-2">
            5. Unforgettable Culinary Experience
          </h5>
          Cooking an AnyFeast Christmas Box is more than just preparing a
          meal—it’s an experience. From discovering new recipes to creating
          restaurant-quality dishes in your kitchen, it brings joy back to
          cooking. Plus, our step-by-step guides and pre-measured ingredients
          make even complex dishes approachable. Your Christmas feast will be
          the highlight of the day, filled with flavors your loved ones will
          remember for years to come.
          <h5 className="font-bold text-3xl pt-5 pb-2 text-red-600">
            Why Choose AnyFeast This Christmas?
          </h5>
          The AnyFeast Christmas Box is perfect for busy families, novice cooks,
          or anyone who wants a memorable meal without the holiday stress. With
          a focus on quality, customization, and sustainability, we’re
          redefining how you celebrate the holidays.
          <h5 className="font-bold text-2xl pt-3 pb-2">
            {" "}
            Order Your AnyFeast Christmas Box Today!
          </h5>
          Don’t let the hustle of the holidays keep you from enjoying the
          season. Place your order now and look forward to a stress-free,
          delicious, and joyous Christmas celebration.
        </p> */}
        <LikeCommentShare blogId={getBlog.id} />

        {/* <h3 className="text-base sm:text-md lg:text-[28px] text-[#172B4D] font-semibold mt-4">
          Order your Luchi and Cholar Dal recipe kit from AnyFeast today and
          experience the rich history and flavors of Bengali cuisine in your own
          home!
        </h3> */}
      </div>

      <div className="mt-8 px-4 lg:px-0">
        <h4 className="text-lg text-[#42526E] mb-4 font-sans">
          HERE ARE SOME RELATED ARTICLES YOU MAY FIND INTERESTING:
        </h4>

        <div className="space-y-6">
          {getRelatedBlog?.slice(0, 10)?.map((tag: any) => (
            <>
              <div>
                <Link to={`/${storedCountry}/blog/${tag.slug}`} key={tag.id}>

                  <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
                    <img
                      src={tag?.featured_image_url && tag?.featured_image_url !== "NaN" ? tag?.featured_image_url : placeholder}
                      alt={tag.title}
                      className="w-full h-[250px] lg:w-36 lg:h-36 object-cover rounded-md"
                    />
                    <div>
                      <p className="text-xs mt-2 mb-2 text-[#42526E]">
                        Blog
                        {/* - 4 MINUTE READ */}
                      </p>
                      <p className="text-xl mb-2 sm:text-2xl text-[#172B4D]">
                        {tag.title}
                      </p>
                      <p className="text-xs sm:text-base text-[#172B4D]">
                        {truncateText(tag?.content)}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          ))}

        </div>
      </div>
    </div>
  );
};

export default Blog;
