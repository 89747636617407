import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Package from '../../assets/images/Package.png';
import UserList from '../../assets/images/UserList.png';
import UserCircle2 from '../../assets/images/UserCircle2.png';
import SmileyNervous from '../../assets/images/SmileyNervous.png';
import BellRinging from '../../assets/images/BellRinging.png';
import SignOut from '../../assets/images/SignOut.png';
import UserAvatar from '../../assets/images/UserCircle2.png'; // Replace with your avatar image
import PiggyBank from '../../assets/images/piggybankicon.png';

interface SidebarProps {
  onSidebarItemClick: (itemName: string) => void;
  isSidebarOpen?: boolean;
  setIsSidebarOpen?: (open: boolean) => void;
}
const Sidebar: React.FC<SidebarProps> = ({ 
  onSidebarItemClick, 
  isSidebarOpen: controlledIsSidebarOpen, 
  setIsSidebarOpen: controlledSetIsSidebarOpen 
}) => {
  // Use local state if no props are provided
  const [localIsSidebarOpen, setLocalIsSidebarOpen] = useState<boolean>(true);
  
  // Determine which state to use
  const isSidebarOpen = controlledIsSidebarOpen ?? localIsSidebarOpen;
  const setIsSidebarOpen = controlledSetIsSidebarOpen ?? setLocalIsSidebarOpen;
  const userName = localStorage.getItem("firstName") || "User";
  const [activeButton, setActiveButton] = useState<string>('Orders');
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const [highlightStyle, setHighlightStyle] = useState({
    top: 0,
    height: 0,
  });
  const buttonRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({});


  useEffect(() => {
    const handleResize = () => {
      const isNowMobile = window.innerWidth <= 640;
      setIsMobile(isNowMobile);
      if (!isNowMobile) {
        setIsSidebarOpen(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsSidebarOpen]);

  const handleClick = (buttonName: string) => {
    setActiveButton(buttonName);
    onSidebarItemClick(buttonName);
    
    if (isMobile) {
      setIsSidebarOpen(false);
    }
   
    if (buttonName === 'Logout') {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userEmail');
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('userPhone');
      localStorage.removeItem('guestId');
      localStorage.removeItem('userId');
      navigate('/');
    }
  };
  useEffect(() => {
    const activeButtonEl = buttonRefs.current[activeButton];
    if (activeButtonEl) {
      setHighlightStyle({
        top: activeButtonEl.offsetTop,
        height: activeButtonEl.offsetHeight,
      });
    }
  }, [activeButton]);

  const menuItems = [
    { title: 'Orders', subtitle: 'See All your Orders', icon: Package },
    { title: 'Addresses', subtitle: 'Edit Addresses', icon: UserList },
    { title: 'Profile', subtitle: 'Make Changes To Your Account', icon: UserCircle2 },
    { title: 'Subscription', subtitle: 'Get Notified on all Exciting Offers', icon: PiggyBank },
    { title: 'Allergies & Dislikes', subtitle: 'Help us with your Allergies & Dislikes', icon: SmileyNervous },
    // { title: 'Notifications', subtitle: 'Get notified on all exciting offers', icon: BellRinging },
    { title: 'About Us', subtitle: 'Know More About Us', icon: BellRinging },
    { title: 'Logout', subtitle: 'Further secure your account for safety', icon: SignOut }
  ];

  return (
    <>
      {isSidebarOpen && (
        <div
          className={`bg-white ${
            isMobile ? 'w-full h-screen fixed inset-0 z-50' : 'w-96 relative'
          }`}
          style={{ marginTop: isMobile ? '176px' : '0px' }}
        >
          {/* Close Button for Mobile */}
          {isMobile && (
            <button
              className="absolute top-4 right-4 text-gray-600"
              onClick={() => setIsSidebarOpen(false)}
            >
              ✖
            </button>
          )}

          <div className="h-full overflow-auto">
            {/* Mobile Header */}
            {isMobile && (
              <div className="flex items-center p-4 bg-[#FFE2E2]">
                <div className="rounded-full w-12 h-12 bg-[#FFE2E2] flex items-center justify-center mr-4">
                  <img src={UserAvatar} alt="User" className="w-10 h-10 rounded-full" />
                </div>
                <div>
                  <h2 className="text-sm font-semibold text-red">
                    Welcome, {userName}
                  </h2>
                  <p className="text-xs text-gray-500">Your personalized experience starts here.</p>
                </div>
              </div>
            )}

            {/* Highlight Elements */}
            <div
              className="absolute left-0 w-[3px] bg-red transition-all duration-1000 ease-in-out"
              style={{
                top: highlightStyle.top,
                height: highlightStyle.height,
              }}
            />
            <div
              className="absolute left-[3px] right-0 bg-pink transition-all duration-1000 ease-in-out"
              style={{
                top: highlightStyle.top,
                height: highlightStyle.height,
              }}
            />

            {/* Menu Items */}
            {menuItems.map(({ title, subtitle, icon }) => (
              <button
                key={title}
                ref={(el) => (buttonRefs.current[title] = el)}
                onClick={() => {
                  if (title === 'Logout') {
                    setShowLogoutPopup(true);
                  } else {
                    handleClick(title);
                  }
                }}
                className="block relative w-full text-left py-4"
              >
                <div
                  className={`flex items-center ${
                    activeButton === title ? 'text-red ml-3' : 'ml-4'
                  }`}
                >
                  <div className="rounded-full w-10 h-10 mr-4 mt-1 bg-[#FFE2E2] flex items-center justify-center">
                    <img src={icon} alt={title} className="w-6 h-6" />
                  </div>
                  <div>
                    <h2 className="text-sm mb-0.5 font-semibold">{title}</h2>
                    <p
                      className={`text-xs mb-1 ${
                        activeButton === title ? 'text-red' : 'text-gray-500'
                      }`}
                    >
                      {subtitle}
                    </p>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Logout Confirmation Popup */}
      {showLogoutPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 sm:p-8 rounded-xl shadow-2xl w-11/12 max-w-xs sm:max-w-md text-center">
            <p className="text-lg sm:text-xl font-bold text-greytext mb-3 sm:mb-4">Confirm Logout</p>
            <p className="text-xs sm:text-sm text-greytext">Are you sure you want to log out?</p>
            <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row gap-3 sm:gap-4">
              <button
                className="bg-green text-white py-2 sm:py-3 px-4 sm:px-6 text-sm sm:text-base rounded-lg font-medium hover:bg-opacity-90 transition-all flex-1 sm:flex-none sm:w-1/2"
                onClick={() => {
                  setShowLogoutPopup(false);
                  handleClick('Logout');
                }}
              >
                Log Out
              </button>
              <button
                className="bg-greylight text-greytext py-2 sm:py-3 px-4 sm:px-6 text-sm sm:text-base rounded-lg font-medium hover:bg-gray-100 transition-all flex-1 sm:flex-none sm:w-1/2"
                onClick={() => setShowLogoutPopup(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
