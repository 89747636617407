import React from "react";

const PrivacyPolicy: React.FC = () => {
  const storedCode = localStorage.getItem("countryCode");
  return (
    <div className="text-greytext  p-6 lg:p-12 rounded-lg bg-white max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">Privacy Policy</h1>

      <section className="space-y-6">
        <div className="prose prose-lg mx-auto">
          <h2 className="text-2xl font-semibold font-sans mb-4">1. Introduction</h2>
          <p className="font-sans mb-4">This Privacy Policy outlines how <strong>AnyFeast Limited</strong>, a company registered at<strong>{storedCode === "IN" ? "Office No 1210, 12th Floor, Ghanshyam Enclave, Near Lalji Pada Police Station, New Link Road, Kandivali (W), Mumbai 400067" : "15 College Road, Harrow, England, HA1 1BA," }</strong> (“AnyFeast,” “we,” “us,” or “our”), collects, processes, stores, and protects your personal data when using our website and mobile applications (collectively, the “Platform”).</p>
          <p className="font-sans mb-4">By accessing or using AnyFeast, you agree to the practices described in this Privacy Policy. If you do not agree, please discontinue use immediately.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">2. Data We Collect</h2>
          <h3 className="text-xl font-semibold mb-2">2.1 Access Data</h3>
          <p className="font-sans mb-4">When our website is accessed, server log files are transmitted and stored on our server for a limited period of time (maximum six months). The following data is logged:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>IP address used</li>
            <li>Browser type and version</li>
            <li>Operating system</li>
            <li>Website visited</li>
            <li>Date and time of access</li>
            <li>Notification of successful access</li>
            <li>Amount of data sent in bytes</li>
            <li>Referrer URL (if access was made via a link)</li>
          </ul>
          <p className="font-sans mb-4">This information is used for statistical evaluations to enhance operation, security, and user experience. No personal evaluation is carried out unless there is a justified suspicion of unlawful use.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">3. Cookies and Tracking Technologies</h2>
          <h3 className="text-xl font-semibold mb-2">3.1 General Information and Consent</h3>
          <p className="font-sans mb-4">AnyFeast uses cookies and similar tracking technologies such as web beacons, pixels, and tags to enhance user experience.</p>
          <p className="font-sans mb-4">Session cookies are deleted at the end of a browser session.</p>
          <p className="font-sans mb-4">Persistent cookies remain on your device to improve site functionality.</p>
          <p className="font-sans mb-4">Cookies do not store personal identity but help personalize services.</p>
          <p className="font-sans mb-4">Under legal requirements, storing or accessing non-essential cookies requires user consent. However, cookies that are strictly necessary for website functionality do not require consent.</p>
          <p className="font-sans mb-4">You can manage cookie preferences in browser settings or through our website’s cookie management tool.</p>

          <h3 className="text-xl font-semibold mb-2">3.2 Types of Cookies Used</h3>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>Strictly Necessary Cookies</strong> – Essential for platform functionality (e.g., login, shopping cart).</li>
            <li><strong>Functional Cookies</strong> – Enhance user experience by remembering preferences.</li>
            <li><strong>Performance Cookies</strong> – Track website analytics and user behavior.</li>
            <li><strong>Social Media Cookies</strong> – Enable social sharing and personalized social content.</li>
            <li><strong>Targeting/Advertising Cookies</strong> – Used for remarketing and personalized advertising.</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">3.3 Third-Party Cookies</h3>
          <p className="font-sans mb-4">AnyFeast uses both first-party and third-party cookies. Third-party cookies may be used by advertising partners, analytics providers, and social media platforms. AnyFeast does not control these cookies. Please refer to the third party’s privacy policies for details.</p>

          <h3 className="text-xl font-semibold mb-2">3.4 How to Manage Cookies</h3>
          <p className="font-sans mb-4">You can refuse, block, or delete cookies via your browser settings:</p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>Google Chrome:</strong> Settings {">"} Privacy and Security {">"} Cookies and Other Site Data.</li>
            <li><strong>Safari:</strong> Preferences {">"} Privacy {">"} Remove All Website Data.</li>
            <li><strong>Firefox:</strong> Options {">"} Privacy & Security {">"} Cookies and Site Data.</li>
            <li><strong>Opera:</strong> Preferences {">"} Advanced {">"} Cookies.</li>
          </ul>

          <h2 className="text-2xl font-semibold font-sans mb-4">4. Data Processing and Use</h2>
          <h3 className="text-xl font-semibold mb-2">4.1 How We Use Your Information</h3>
          <p className="font-sans mb-4">We use collected data to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Authenticate users and prevent fraud</li>
            <li>Enhance platform security and prevent unauthorized access</li>
            <li>Improve website performance and user experience</li>
            <li>Conduct analytics and marketing activities (with consent)</li>
            <li>Comply with legal obligations</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">4.2 Third-Party Data Sharing</h3>
          <p className="font-sans mb-4">We do not sell your data. Data is only shared with:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Payment processors (to handle transactions securely)</li>
            <li>Third-party analytics providers (e.g., Google Analytics)</li>
            <li>Government authorities (where legally required)</li>
          </ul>

          <h2 className="text-2xl font-semibold font-sans mb-4">5. Google Services & Tracking</h2>
          <h3 className="text-xl font-semibold mb-2">5.1 Google Analytics</h3>
          <p className="font-sans mb-4">We use Google Analytics to analyze visitor interactions. If you do not want to be tracked, you can opt out by installing the Google Analytics Opt-Out Browser Add-on.</p>

          <h3 className="text-xl font-semibold mb-2">5.2 Google Chrome & Personalized Ads</h3>
          <p className="font-sans mb-4">If accessing AnyFeast via Google Chrome, Google may collect additional user data for advertising purposes. You can manage ad preferences via Google’s privacy settings.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">6. Social Media Data Handling</h2>
          <p className="font-sans mb-4">To protect your privacy, AnyFeast only shares data with social media networks when you actively engage with them (e.g., clicking a share button). No data is automatically transmitted.</p>
          <p className="font-sans mb-4">Refer to the privacy policies of respective platforms for further details:</p>
          <ul className="list-disc pl-6 mb-4">
            <li><a href="https://www.facebook.com/policy.php" className="text-blue-600">Facebook Privacy Policy</a></li>
            <li><a href="https://twitter.com/en/privacy" className="text-blue-600">Twitter Privacy Policy</a></li>
            <li><a href="https://help.instagram.com/519522125107875" className="text-blue-600">Instagram Privacy Policy</a></li>
          </ul>

          <h2 className="text-2xl font-semibold font-sans mb-4">7. Data Retention & Security</h2>
          <h3 className="text-xl font-semibold mb-2">7.1 How Long We Retain Your Data</h3>
          <p className="font-sans mb-4">We retain user data only as long as necessary for legitimate business or legal purposes. Data is securely deleted when no longer required.</p>

          <h3 className="text-xl font-semibold mb-2">7.2 Security Measures</h3>
          <p className="font-sans mb-4">We implement industry-standard security measures to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Protect user data from unauthorized access</li>
            <li>Encrypt sensitive transactions (e.g., payments)</li>
            <li>Restrict access to authorized personnel only</li>
          </ul>

          <h2 className="text-2xl font-semibold font-sans mb-4">8. User Rights & GDPR Compliance</h2>
          {storedCode === "UK" && <p className="font-sans mb-4">As a UK-based company, AnyFeast complies with the UK GDPR. Users have the right to:</p>}
          <ul className="list-disc pl-6 mb-4">
            <li>Request access to their personal data</li>
            <li>Request correction or deletion of incorrect data</li>
            <li>Withdraw consent for marketing communications</li>
            <li>Object to automated decision-making</li>
          </ul>
          <p className="font-sans mb-4">For GDPR-related requests, contact <a href="mailto:contact@anyfeast.com" className="text-blue-600">contact@anyfeast.com</a>.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">9. Changes to the Privacy Policy</h2>
          <p className="font-sans mb-4">AnyFeast reserves the right to update this policy at any time. Users should review updates periodically. Continued use after changes implies acceptance of the revised policy.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">10. Contact Information</h2>
          <p className="font-sans mb-4">For any privacy concerns, contact <a href="mailto:contact@anyfeast.com" className="text-blue-600">contact@anyfeast.com</a>.</p>
        </div>
      </section>

    </div>
  );
};

export default PrivacyPolicy;
