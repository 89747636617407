import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const Canonical: React.FC = () => {
  const location = useLocation();
  const baseUrl = "https://www.anyfeast.com";
  const canonicalUrl = `${baseUrl}${location.pathname}`;

  const hreflangs = [
    { lang: "en-IN", url: `${baseUrl}${location.pathname}` },
    { lang: "en-GB", url: `${baseUrl}${location.pathname}` },

  ];
  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />

      {hreflangs.map((hreflang, index) => (
        <link
          key={index}
          rel="alternate"
          hrefLang={hreflang.lang} 
          href={hreflang.url}
        />
      ))} 
    </Helmet>
  );
};

export default Canonical;
