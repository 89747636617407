import React from "react";
import blog2 from "../../../src/assets/images/b2.png";
import blog3 from "../../../src/assets/images/b3.png";
import blog4 from "../../../src/assets/images/b4.png";
import blog5 from "../../../src/assets/images/b5.png";
// import blog6 from "../../../src/assets/images/blog6.png";
// import blog7 from "../../../src/assets/images/blog7.png";
import blog6 from "../../assets/images/b6.png";
import blog7 from "../../assets/images/b7.png";
import { Link } from "react-router-dom";
import moment from "moment";
import placeholder from '../../../src/assets/images/placeholderImage.png';
import { truncateText } from "../../utils/common";

interface Blogs {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  date: string;
  time: string;
}

interface Props {
  blogs: any;
}
const BlogPost: React.FC<Props> = ({ blogs }) => {
  const convertDateWithMoment = (isoDate: string): string => {
    return moment(isoDate).format("MMMM D, YYYY");
  };
  const storedCountry = localStorage.getItem("selectedCountry");
  return (
    // <div className="pt-16">
    //   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 space-x-8 justify-items-center">
    //     {blogs.map((blog: any) => (
    //       <Link to={`/blogPost/${blog.id}`}>
    //         <div key={blog.id} className="bg-white rounded-lg border border-1 w-full">
    //           <img
    //             src={blog.featured_image_url}
    //             alt={blog.title}
    //             className="w-full h-[300px] object-cover rounded-t-lg mb-4"
    //           />

    //           <p className="px-3 mb-10">
    //             <div className="text-sm text-[#172B4D] my-2">
    //               <span>{convertDateWithMoment(blog.
    //                 post_date)
    //               }</span> | <span>{blog.reading_time_minutes
    //               } min read</span>
    //             </div>
    //             <h2 className="text-lg font-bold mb-5 line-clamp-2">{blog.title}</h2>
    //             <p className="text-sm">{blog.description}</p>
    //           </p>
    //         </div>
    //       </Link>
    //     ))}
    //   </div>
    // </div>
    <div className="pt-16">

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
        {blogs.map((blog: any) => (
          <Link to={`/${storedCountry}/blog/${blog.slug}`} key={blog.id}>
            <div className="bg-white rounded-lg border border-1 w-full h-full flex flex-col xl:w-[450px] lg:w-[300px]">
              <div className="relative h-[250px]">
                <img
                  src={blog?.featured_image_url && blog?.featured_image_url !== "NaN" ? blog?.featured_image_url : placeholder}
                  alt={blog.title}
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-t-lg"
                />
              </div>


              <div className="flex flex-col justify-between flex-grow p-3">
                <div className="text-sm text-[#172B4D] my-2">
                  <span>{convertDateWithMoment(blog.post_date)}</span> 
                  {/* | <span>{blog.reading_time_minutes} min read</span> */}
                </div>
                <h2 className="text-lg font-bold mb-4 line-clamp-2"> {blog.title?.replace(/!¬†¬†|¬†/g, '.')}</h2>
                <p className="text-sm line-clamp-3">{truncateText(blog?.content)}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogPost;
