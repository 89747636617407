import React from "react";

const ShippingPolicy: React.FC = () => {
  return (
    <div className="text-greytext  p-6 lg:p-12 rounded-lg bg-white max-w-7xl mx-auto">
      <section className="space-y-6">
        <div className="prose prose-lg mx-auto">

          <h2 className="text-2xl font-semibold font-sans mb-4 text-center">Shipping Policy</h2>

          <p className="font-sans mb-4">AnyFeast ("we" or "us") operates <a href="https://www.anyfeast.com" className="text-blue-600">https://www.anyfeast.com</a> ("Website") and our mobile applications ("App"). By placing an order through our Website or App, you agree to the terms outlined in this policy. These terms ensure transparency and set expectations regarding our shipping services.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">1. General Shipping Terms</h2>
          <p className="font-sans mb-4">All orders are subject to stock availability. While we strive to maintain accurate stock levels, discrepancies may occasionally occur.</p>

          <p className="font-sans mb-4">If an item is out of stock, we will dispatch available products immediately and notify you about the backordered item. You may choose to wait for restocking or request a refund for the unavailable product.</p>

          <p className="font-sans mb-4">AnyFeast partners with third-party couriers for delivery. While we strive to ensure timely deliveries, we are not liable for delays or errors caused by courier services.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">2. Shipping Costs</h2>
          <p className="font-sans mb-4">Shipping costs are calculated at checkout based on weight, dimensions, and delivery destination.</p>

          <p className="font-sans mb-4">Shipping charges will be collected along with your purchase and are non-refundable once shipping has commenced.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">3. Delivery Timeframes</h2>
          <p className="font-sans mb-4"><strong>Domestic Transit Time:</strong> Standard shipments typically take 2–3 business days.</p>

          <p className="font-sans mb-4"><strong>Next Day Delivery:</strong> Orders placed before 12 PM GMT will be dispatched on the next business day, subject to courier availability and serviceable locations.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">4. Address Changes</h2>
          <p className="font-sans mb-4">Customers may request a change of delivery address anytime before an order is dispatched. Once dispatched, address changes cannot be accommodated.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">5. Handling Out-of-Stock Items</h2>
          <p className="font-sans mb-4">If an item is unavailable, we will ship the in-stock items first and dispatch the remaining items once back in stock. We will contact you for further instructions if needed.</p>

          <p className="font-sans mb-4">For large orders, items may be shipped separately. Customers will receive tracking details for each shipment.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">6. Delayed Deliveries</h2>
          <p className="font-sans mb-4">If your order has not arrived within the estimated timeframe, please contact us immediately. We will investigate the issue and provide updates.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">7. Damaged or Lost Parcels</h2>
          <h3 className="text-xl font-semibold mb-2">Damaged Parcels</h3>
          <p className="font-sans mb-4">If your parcel arrives damaged, please reject the delivery (if possible) and contact our customer service team immediately.</p>

          <p className="font-sans mb-4">If the parcel is delivered while you are not present, report the damage within 24 hours to initiate a claim.</p>

          <h3 className="text-xl font-semibold mb-2">Lost Parcels</h3>
          <p className="font-sans mb-4">If your parcel is lost in transit, contact us as soon as possible. We will coordinate with the courier service to resolve the issue.</p>

          <p className="font-sans mb-4">If a parcel is confirmed as lost in transit, customers may choose between a refund or a replacement, subject to stock availability.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">8. Duties & Taxes</h2>
          <p className="font-sans mb-4">Sales tax is already included in the displayed price of goods on our Website.</p>

          <p className="font-sans mb-4">Any additional import duties or taxes (if applicable) are the responsibility of the customer.</p>

          <h2 className="text-2xl font-semibold font-sans mb-4">9. Customer Service</h2>
          <p className="font-sans mb-4">For any shipping-related inquiries, please contact our Customer Service Team at <a href="mailto:contact@anyfeast.com" className="text-blue-600">contact@anyfeast.com</a>.</p>

          <p className="font-sans mb-4">Our support team operates Monday to Friday, 9 AM - 6 PM GMT. Inquiries received outside these hours will be addressed on the next business day.</p>

          <p className="font-sans mb-4">We are happy to assist with any concerns regarding your delivery.</p>

        </div>
      </section>

    </div>
  );
};

export default ShippingPolicy;
