import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import placeholder from "../../../../src/assets/images/placeholderImage.png";
import snack from "../../../assets/images/snacks1.png";
import cross from "../../../assets/images/cross.png";
import emptyCart from "../../../assets/images/EmptyCart.png";
import axios from "axios";
import {
  addUpdateItemAsync,
  getItemsByIdAsync,
} from "../../../services/reducers/reciepeReducer";

interface MiniCartProps {
  isOpen: boolean;
  onClose: () => void;
}

const MiniCart: FC<MiniCartProps> = ({ isOpen, onClose }) => {
  const [cartTotalCount, setCartTotalCount] = useState(0);
  const navigate = useNavigate();
  const carts = useAppSelector((state) => state.receipe.itemsList);
  const [ipAddress, setIpAddress] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  // const cartIngredients = useAppSelector((state) => state.cart);itemsList
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  //const cartIngredients = useAppSelector((state) => state.receipe.itemsList);
  const [cartIngredients, setCartIngredients] = useState<any>([]);
  const [cartAddress, setCartAddress] = useState<any>([]);
  const [extra, setExtra] = useState<any>([]);
  const [cartTotal, setCartTotal] = useState<any>([]);
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("firstName") || "User";
  const [loading, setLoading] = useState(true);
  const [wmartCouponCode, setWmartCouponCode] = useState("");
  const storedCountry = localStorage.getItem("selectedCountry");
  const storedCode = localStorage.getItem("countryCode");
  const guestId = localStorage.getItem("guestId");
  const [detail, setDetail] = useState<any>([]);
  const [total, setTotal] = useState(0);
  let itemCount = 0;
  const keys = Object.keys(cartIngredients);
  const firstKey = keys[0];
  //const result = firstKey?.split("-")[0];
  //const ids = Number(firstKey?.split("-")[1]);
  if (keys.length > 0) {
    itemCount = cartIngredients[keys[0]].length;
  }
  const [selectedAddress, setSelectedAddress] = useState(null);
  const address = cartAddress.find(
    (addr: any) => addr.address_id === selectedAddress
  );
  const userOrGuestId = userId === null ? guestId : userId;

  // useEffect(() => {
  //   const country = storedCode ?? "";
  //   if (carts && carts.data.cart) {
  //     if (guestId) {
  //       dispatch(getItemsByIdAsync({ id: guestId, country })).then((res) => {
  //         if (res.payload) {
  //           setCartIngredients(res.payload?.data?.cart?.cart || {});
  //           setCartTotal(res.payload?.data?.cart || 0);
  //           setExtra(res.payload?.data?.extras || []);
  //         }
  //         // new Promise((resolve) => setTimeout(resolve, 2000));
  //         // setLoading(false);
  //       });
  //     }
  //   }
  // }, [guestId, carts]);

  useEffect(() => { }, [detail]);
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIpAddress();
  }, []);


  useEffect(() => {
    if (carts && carts.data.cart) {
      const cartCount = Object.values(carts.data.cart.lookup).flat().length;
      setCartTotalCount(cartCount);
      setCartIngredients(carts.data.cart.cart);
      setCartTotal(carts.data.cart);
      setExtra(carts.data.extras);
      // console.log(cartCount, "cartCount");
    }
  }, [carts]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  // useEffect(() => {
  //   setCartTotalCount(Object.keys(cartIngredients).length);
  // }, [cartIngredients]);

  const clearCart = () => {
    setCartIngredients({});
    setCartTotal({ cart_value: 0 });
    // console.log("Cart has been cleared");
  };
  const calculateTotal = () => {
    const discount =
      detail?.discount_type === "percent"
        ? -(cartTotal?.cart_value * detail.discount) / 100
        : -detail.discount;

    const extraCharges = extra.reduce(
      (acc: any, curr: any) => acc + curr.charge,
      0
    );

    return (cartTotal?.cart_value || 0) + (discount || 0) + extraCharges;
  };

  useEffect(() => {
    setTotal(calculateTotal().toFixed(2));
  }, [cartTotal, detail, extra]);

  const handleView = () => {
    navigate(`/${storedCountry}/cart`);
    onClose();
  };

  if (!isOpen) return null;
  const handleCartClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleUpdateItemQuantity = (
    dishKey: string,
    ingredient: any,
    change: number
  ) => {
    // if (!userId) {
    //   console.error("User ID is not available");
    //   return;
    // }
    const newQuantity = ingredient.selected + change;
    if (newQuantity < 0) return;

    const dishId = Number(dishKey.split("-")[1]);
    const dishName = dishKey.split("-")[0];
    // console.log("dishkey", dishKey);
    const request = {
      group: `${dishName}-${dishId}`,
      // group:`${groupName}-${groupId}`,
      // group:group,
      // group: `Shop- -1`,
      country: storedCode,
      items:
        newQuantity === 0
          ? [
            {
              ...ingredient,
              selected: 0,
            },
          ]
          : [
            {
              ...ingredient,
              selected: newQuantity,
            },
          ],
    };

    // console.log("req sent", request);
   
    if (userOrGuestId) {
      dispatch(addUpdateItemAsync({ id: userOrGuestId, request }))
        .then((res) => {
          if (res.payload && res.payload.success) {
            const updatedCart = { ...cartIngredients };
            // const updatedCart = res.payload.cart;
            // updatedCart[dishKey] = updatedCart[dishKey].map((item: any) =>
            //     item.id === ingredient.id ? { ...item, selected: newQuantity } : item
            // );
            // seCartIngredients(updatedCart);
            //const shopKey = `Shop- -${dishId}`;
            // console.log(shopKey);
            if (newQuantity === 0) {
              updatedCart[dishKey] = updatedCart[dishKey].filter(
                (item: any) => item.id !== ingredient.id
              );
              if (updatedCart[dishKey].length === 0) {
                delete updatedCart[dishKey];
              }
            } else {
              updatedCart[dishKey] = updatedCart[dishKey] || [];
              updatedCart[dishKey] = updatedCart[dishKey].map((item: any) =>
                item.id === ingredient.id
                  ? { ...item, selected: newQuantity }
                  : item
              );
            }
            for (const shopKey in updatedCart) {
              if (updatedCart[shopKey].length === 0) {
                delete updatedCart[shopKey];
              }
            }

            const country = storedCode ?? "";
            if (userOrGuestId) {
              dispatch(getItemsByIdAsync({ id: userOrGuestId, country })).then(
                (res) => {
                  if (res.payload) {
                    setCartIngredients(res.payload?.data?.cart?.cart || {});
                    setCartTotal(res.payload?.data?.cart || 0);
                    setExtra(res.payload?.data?.extras || []);
                  }
                }
              );
            }
          } else {
            console.error("failed tp update");
          }
        })
        .catch((err) => console.error("Error updating:", err));
    }
  };
  const handleShopView = () => {
    navigate(`/${storedCountry}/shop`);
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end z-50">
      <div
        className="xl:w-1/3 lg:w-1/2 md:w-3/4 bg-white h-full shadow-lg p-4 relative z-50 cursor-default"
        onClick={handleCartClick}
      >
        <div className="flex flex-col justify-between">
          <div className="flex flex-row items-start justify-between pb-2 border-b border-gray-300">
            <div className="flex flex-row">
              <h2 className="text-xl font-bold">My Cart</h2>
              <span className="ml-3 text-md text-greytext">
                {cartTotalCount} products
              </span>
            </div>
            <div className="flex items-end">
              <div className="border-b border-gray-300 my-2"></div>
              <div className="flex flex-col pr-10">
                <span className="text-sm text-greytext">Cart Total</span>
                {Object.keys(cartIngredients).length === 0 ? (
                  <span className="font-semibold">
                    {" "}
                    {storedCode === "IN" ? "₹" : "£"} 0
                  </span>
                ) : (
                  <span className="font-semibold">
                    {" "}
                    {storedCode === "IN" ? "₹" : "£"} {total}
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* Cart Items Container */}

          <div className="h-full p-4 relative z-100 cursor-default flex flex-col">
            {Object.keys(cartIngredients).length === 0 ? (
              <div>
                <center>
                  <div className="mt-20">
                    <div className="flex flex-col justify-center items-center h-full text-center">
                      <img
                        src={emptyCart}
                        alt="Main Banner"
                        className="w-3/4 sm:w-2/3 md:w-1/2 h-auto"
                      />
                    </div>
                    <button
                      className="px-10 py-2 bg-green text-white rounded-lg hover:bg-green-hover font-sans mt-6"
                      onClick={() => handleShopView()}
                    >
                      ADD ITEMS FOR YOUR FEAST
                    </button>
                  </div>
                </center>
              </div>
            ) : (
              <div className="flex-1 overflow-y-auto xl:max-h-[29rem] md:max-h-[52rem] lg:max-h-[95rem]">
                {Object.keys(cartIngredients).map((dishKey: string) => {
                  const dishName = dishKey.split("-")[0];
                  const ingredients = cartIngredients[dishKey];

                  return (
                    <div>
                      <div key={dishKey} className="mb-6">
                        <h3 className="font-semibold pb-3">
                          {ingredients.length} items for {dishName}
                        </h3>
                        <ul className="space-y-6">
                          {ingredients.map((ingredient: any, index: number) => (
                            <li
                              key={index}
                              className="flex items-center justify-between border p-4"
                            >
                              <div className="">
                                <img
                                  src={ingredient?.image && ingredient?.image !== "NaN" ? ingredient?.image : placeholder}
                                  alt={ingredient.name}
                                  className="w-10 lg:w-16 xl:w-16  md:w-16 h-full object-cover"
                                />
                              </div>

                              <div className="flex-1 pl-6">
                                <h3 className="text-sm font-semibold text-[#253D4E] pb-2">
                                  {ingredient.name}
                                </h3>
                                <p className="text-[#828282] font-sans pb-2 text-xs lg:text-md xl:text-md md:text-md">
                                  Pack of {ingredient.quantity}
                                </p>
                                <div className="flex items-center space-x-2">
                                  <span className="text-[#3BB77E] text-lg font-bold pb-2 font-sans">
                                    {storedCode === "IN" ? "₹" : "£"}
                                    {ingredient.price}
                                  </span>
                                  {ingredient.revised_price > 0.0 && (
                                    <span className="text-[#ADADAD] line-through pb-2 font-sans">
                                      {storedCode === "IN" ? "₹" : "£"}
                                      {ingredient.revised_price}
                                    </span>
                                  )}
                                </div>
                              </div>

                              {/* Quantity Selector */}
                              <div className="flex flex-row items-center">
                                <div className="flex items-center bg-[green] text-white rounded-lg px-2 py-1 font-sans">
                                  <button
                                    className="px-1 md:px-2 lg:px-2 py-1"
                                    aria-label="Decrease servings"
                                    onClick={() =>
                                      handleUpdateItemQuantity(
                                        dishKey,
                                        ingredient,
                                        -1
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  {/* <span className="px-4 md:px-1">{addedItems[ingredient.id] || 1}</span> */}
                                  <span className="px-4 md:px-1">
                                    {ingredient.selected}
                                  </span>
                                  <button
                                    className="px-1 md:px-2 lg:px-2 py-1"
                                    aria-label="Increase servings"
                                    onClick={() =>
                                      handleUpdateItemQuantity(
                                        dishKey,
                                        ingredient,
                                        1
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                                <button
                                  className="border border-green rounded-lg p-2 ml-2"
                                  onClick={() =>
                                    handleUpdateItemQuantity(
                                      dishKey,
                                      ingredient,
                                      -ingredient.selected
                                    )
                                  }
                                >
                                  <img
                                    src={cross}
                                    alt="cross"
                                    className="w-5 h-5"
                                  />
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {/* <div className="border-t mt-4 bg-pink px-4 items-center"> */}
          {Object.keys(cartIngredients).length === 0 ? (
            <div className="bg-white"></div>
          ) : (
            <>
              <div className="absolute bottom-0 left-0 w-full border-t border-gray-300 bg-green-100 flex justify-center p-4">
                <button
                  onClick={handleView}
                  className="px-12 py-2 bg-green hover:bg-green-hover text-white rounded-lg"
                >
                  VIEW FULL CART
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default MiniCart;
