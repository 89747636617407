import { useEffect, useState } from 'react';
import Detail from './Detail';
import RecipePage from './ReceipeDetail';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addUpdateItemAsync, getFestiveDishesAsync, getItemsByIdAsync, getRecipeByIdAsync, getRecipeByTimeAsync, getRecipeIngredientAsync } from '../../services/reducers/reciepeReducer';
import { getGuestUserId, Ingredient, Quantities } from '../../utils/common';

import { useSelector } from 'react-redux';
import LoginModal from '../Home/LoginModal';


const DetailIndex = () => {
  const { url } = useParams();
  // const { id } = useParams();
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<any>([]);
  const { recipeList, ingList, festiveList } = useAppSelector((state: any) => state.receipe);
  const [calories, setCalories] = useState('');
  const [ingredients, setIngredients] = useState('');
  // const ids = recipeList?.data?.filter((item: any) => item.id === id);
  const [ingredientsCount, setIngredientsCount] = useState(0);
  const [quantiti, setQuantiti] = useState<Quantities>({});
  const stapleCount = Object.keys(quantiti).length;
  const userId = localStorage.getItem("userId");
  const [itemCart, setItemCart] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const newpersonaDetails = location.state?.personaDetails;
  const newfestiveDetails = location.state?.festiveDetails;
  const newsearchDetails = location.state?.searchDetails;
  // const quantities = useAppSelector((state) => state.quantities.quantities);
  const { recipeDetail } = useAppSelector((state: any) => state.receipe);
  const ingredient: Ingredient[] = recipeDetail?.data?.ingredients || [];
  const filteredIngredients = ingredient.filter((item) => item.ingredient_type === 2);
  // const servingss = useAppSelector((state) => state.servings);
  const [quantity, setQuantity] = useState(1);
  const [exotiCart, setExoticCart] = useState<{ [key: number]: number }>({});

  const storedCountry = localStorage.getItem('selectedCountry');
  const storedCode = localStorage.getItem('countryCode');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [guestId, setGuestId] = useState<string>("");
  localStorage.setItem('guestId', guestId);

  useEffect(() => {
    if (userId === null) {
      setGuestId(getGuestUserId());
    }
    else {
      setGuestId(userId);
    }
  }, []);

  useEffect(() => {
    getDetails();
    getfestivalDetails();
  }, [url]);

  useEffect(() => {
    getItemsCart();
  }, [guestId]);

  useEffect(() => {
    const formattedRecipe = localStorage.getItem("formattedRecipe");
    const request = {
      time: formattedRecipe,
      country: storedCode,
    };
    dispatch(getRecipeByTimeAsync(request));
  }, [ingList]);

  const getDetails = () => {
    if (url) {
      const request = {
        url: url,
        country: storedCode,
        // id: id,
      };
      dispatch(getRecipeByIdAsync(request)).then((res) => {
        if (res.payload) {
          setDetails(res.payload?.data);
          setCalories(res.payload?.data?.recipe?.calories);
          setIngredients(res.payload?.data?.ingredients?.length);
          setIngredientsCount((res.payload?.data?.ingredients?.filter((item: any) => item.ingredient_type === 2))?.length);
          getingredientDetails(res.payload?.data.recipe.id);
        }
        else {
          navigate(`/${storedCountry}/404`); 
        }
        // dispatch(getRecipeIngredientAsync({ id, country: request.country }));
      }
      );
    }
  };

  const getingredientDetails = (id: string) => {

    const request = {
      country: storedCode,
    };
    dispatch(getRecipeIngredientAsync({ id, country: request.country }));
  };
  const getfestivalDetails = () => {
    const request = {
      country: storedCode,
    };
    dispatch(getFestiveDishesAsync(request))
  };


  // console.log('guestId', guestId);
  // console.log('FDETAILS', newfestiveDetails);
  // console.log('SearchDETAILS', newsearchDetails);
  const finalRecipeList =
    Array.isArray(newpersonaDetails?.data) && newpersonaDetails.data.length > 0
      ? newpersonaDetails.data
      : Array.isArray(newfestiveDetails?.data?.recipes) && newfestiveDetails.data?.recipes.length > 0
        ? newfestiveDetails.data?.recipes
        // : Array.isArray(newsearchDetails) && newsearchDetails?.length > 0
        : newsearchDetails?.url
          ? [newsearchDetails]
          : recipeList?.data;

  const ids = finalRecipeList?.filter((item: any) => String(item.url) === String(url));

  // console.log('FINAL LIST', finalRecipeList);
  // console.log('FINAL ID', ids);
  const [isCartInitialized, setIsCartInitialized] = useState(false);
  // const handleAddToCart = () => {

  //   navigate("/cart");
  // };
  const handleAddToCart = () => {
    // console.log('FINAL ID', ids);
    if (isCartInitialized) {
      navigate(`/${storedCountry}/cart`);
      return;
    }
    // if (!userId) {
    //   console.error("User ID is not available");
    //   setIsModalOpen(true);
    //   return;
    // }
    if (guestId) {
      const request = {
        group: `${ids[0]?.name}-${ids[0]?.id}`,
        // group:`Shop- -1`,
        country: storedCode,
        items: filteredIngredients.map(item => ({
          ...item,
          selected: 1,
        })),
      };

      dispatch(addUpdateItemAsync({ id: guestId, request })).then(() => {
        setExoticCart((prev) => {
          const newCart: any = {};
          filteredIngredients.forEach(item => {
            newCart[item.id] = 1;
          });
          return { ...prev, ...newCart };
        });
        setIsCartInitialized(true);
        navigate(`/${storedCountry}/cart`);
      });
    }
  };
  const getItemsCart = () => {
    const country = storedCode ?? '';
    if (guestId) {
      dispatch(getItemsByIdAsync({ id: guestId, country })).then((res) => {
        if (res.payload) {
          setItemCart(res.payload?.data?.cart?.cart || {});
        }
      });
    }
  };
  useEffect(() => {
    const country = storedCode ?? '';
    if (guestId) {
      dispatch(getItemsByIdAsync({ id: guestId, country })).then((res) => {
        if (res.payload) {
          const cartItems = res.payload.data.cart || {};
          const newAddedItems: { [key: number]: number } = {};

          Object.values(cartItems).forEach((items: any) => {
            if (Array.isArray(items)) {
              items.forEach((item: any) => {
                newAddedItems[item.id] = item.selected;
              });
            }
          });

          //  console.log(newAddedItems, "newAddedItems");
          setExoticCart(newAddedItems);
        }
      });
    }
  }, [guestId]);

  const handleIncrement = (ingredientId: number, ingredient: any) => {
    // if (!userId) {
    //   console.error("User ID is not available");
    //   setIsModalOpen(true);
    //   return;
    // }
    setExoticCart((prev) => {
      const newQuantity = (prev[ingredientId] || 1) + 1;

      const request = {
        group: `${ids[0]?.name}-${ids[0]?.id}`,
        // group:`Shop- -1`,
        country: storedCode,
        items: [
          {
            ...ingredient,
            selected: newQuantity,
          },
        ],
      };

      dispatch(addUpdateItemAsync({ id: guestId, request }));
      return {
        ...prev,
        [ingredientId]: newQuantity,
      };
    });
  };

  const handleDecrement = (ingredientId: number, ingredient: any) => {
    // if (!userId) {
    //   console.error("User ID is not available");
    //   setIsModalOpen(true);
    //   return;
    // }
    setExoticCart((prev) => {
      const newQuantity = Math.max(1, (prev[ingredientId] || 0) - 1);
      const request = {
        group: `${ids[0]?.name}-${ids[0]?.id}`,
        // group: `Shop- -1`,
        country: storedCode,
        items: [
          {
            ...ingredient,
            selected: newQuantity,
          },
        ],
      };
      dispatch(addUpdateItemAsync({ id: guestId, request }));

      return {
        ...prev,
        [ingredientId]: newQuantity,
      };
    });
  };

  // console.log(exotiCart, "exotiCart")
  return (
    <>
      <Detail recipeList={details} calories={calories} id={url} ingredients={ingredients} guestId={guestId} ingredientsCount={ingredientsCount} stapleCount={stapleCount} handleAddToCart={handleAddToCart} itemCart={itemCart} quantity={quantity} handleIncrement={handleIncrement} handleDecrement={handleDecrement} exotiCart={exotiCart} personaDetails={finalRecipeList} festiveDetails={finalRecipeList} searchDetails={finalRecipeList} />
      <RecipePage details={details} guestId={guestId} ingList={ingList} mainIngrediant={ids} id={ids && ids[0]?.id} quantity={exotiCart} handleIncrement={handleIncrement} handleDecrement={handleDecrement} />
      {isModalOpen && (
        <LoginModal
          onClose={() => {
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>

  )
}
export default DetailIndex;


