import React, { useState, useRef, useEffect } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';
//import Pagination from './pagination';
import { useAppDispatch } from '../../hooks';
import { addUpdateItemAsync, getCatCousineAsync, getItemsByIdAsync, getProductsAsync, getSubCatByCousineCatAsync } from '../../services/reducers/reciepeReducer';
import { useNavigate, useParams } from 'react-router-dom';
import LoginModal from '../Home/LoginModal';
import { capitalizeWords } from '../../utils/common';
import placeholder from '../../../src/assets/images/placeholderImage.png';

const IngredientShop: React.FC = () => {
	const cuisines = ["indian", "italian", "korean", "mexican", "thai", "japanese", "american", "british", "general"
	];
	const { cuisine } = useParams();
	const dispatch = useAppDispatch();
	const [categoriesDetail, setCategoriesDetail] = useState([]);
	const [selectedCuisine, setSelectedCuisine] = useState(
		cuisine ? cuisine.toLowerCase() : cuisines[0].toLowerCase()
	);
	const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
	const [subCategory, setSubCategory] = useState([]);
	const [selectedSubcategory, setSelectedSubcategory] = useState<string | undefined>();
	const [products, setProducts] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
	const navigate = useNavigate();
	const [isSmallScreen, setIsSmallScreen] = useState(false);
	//const [currentPage, setCurrentPage] = useState<number>(1);
	//const totalPages = 32;
	const cuisineSliderRef = useRef<HTMLDivElement>(null);
	const categorySliderRef = useRef<HTMLDivElement>(null);
	const productGridRef = useRef<HTMLDivElement>(null);
	const userId = localStorage.getItem("userId");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const guestId = localStorage.getItem("guestId");
	const storedCountry = localStorage.getItem('selectedCountry');
	const [popupOpen, setPopupOpen] = useState(false);
	const [addedProducts, setAddedProducts] = useState<Set<number>>(new Set());


	useEffect(() => {
		const updateScreenSize = () => {
			setIsSmallScreen(window.innerWidth < 768);
		};
		updateScreenSize();
		window.addEventListener('resize', updateScreenSize);
		return () => window.removeEventListener('resize', updateScreenSize);
	}, []);


	useEffect(() => {
			if (cuisine && !cuisines.includes(cuisine.toLowerCase())) {
			navigate(`/${storedCountry}/404`); 
		} else {
			handleCuisineChange(selectedCuisine);
		}
	}, [selectedCuisine]);

	useEffect(() => {
		if (selectedCategory) {
			handleCategoryChange(selectedCategory);
		}
	}, [selectedCategory]);

	useEffect(() => {
		if (selectedSubcategory) {
			getProducts();
		}
	}, [selectedSubcategory]);

	const handleCuisineChange = (cuisineItem: string) => {
		setSelectedCuisine(cuisineItem.toLowerCase());
		setCategoriesDetail([]);
		setSubCategory([]);
		setProducts([]);
		setSelectedCategory(undefined);
		setSelectedSubcategory(undefined);

		const country = storedCode;
		dispatch(getCatCousineAsync({ cuisine: cuisineItem.toLowerCase(), country })).then((res) => {
			if (res.payload) {
				const categories = res.payload.data;
				setCategoriesDetail(categories);
				if (categories.length > 0) {
					const firstCategory = categories[0].category;
					setSelectedCategory(firstCategory);

					dispatch(
						getSubCatByCousineCatAsync({
							cuisine: cuisineItem.toLowerCase(),
							country,
							category: firstCategory,
						})
					).then((res) => {
						if (res.payload) {
							const subcategories = res.payload.data;
							setSubCategory(subcategories);
							if (subcategories.length > 0) {
								setSelectedSubcategory(subcategories[0].sub_category);
							}
						}
					});
				}
			}
		});
	};


	// const handlePageChange = (page: number) => {
	// 	if (page >= 1 && page <= totalPages) {
	// 		setCurrentPage(page);
	// 	}
	// };


	const scroll = (ref: React.RefObject<HTMLDivElement>, direction: 'left' | 'right') => {
		if (ref.current) {
			const scrollAmount = 200;
			const newScrollLeft = direction === 'left'
				? ref.current.scrollLeft - scrollAmount
				: ref.current.scrollLeft + scrollAmount;
			ref.current.scrollTo({
				left: newScrollLeft,
				behavior: 'smooth'
			});
		}
	};

	//const storedCountry = localStorage.getItem('selectedCountry');
	const storedCode = localStorage.getItem('countryCode');


	useEffect(() => {
		getCategory();
	}, [selectedCuisine]);

	const getCategory = () => {
		const country = storedCode;
		setProducts([]);
		if (selectedCuisine) {
			dispatch(getCatCousineAsync({ cuisine: selectedCuisine, country })).then((res) => {
				if (res.payload.data && res.payload.data.length > 0) {
					setSelectedCategory(res.payload.data[0].category);
					setCategoriesDetail(res.payload.data);
				}
			});
		}
	};

	useEffect(() => {
		getSubCategory();
	}, [selectedCategory]);

	const getSubCategory = () => {
		const country = storedCode;
		setProducts([]);
		dispatch(getSubCatByCousineCatAsync({ cuisine: selectedCuisine, country, category: selectedCategory })).then((res) => {
			if (res.payload) {
				setSubCategory(res.payload.data);

				if (res.payload.data && res.payload.data.length > 0) {
					setSelectedSubcategory(res.payload.data[0].sub_category);

					getProducts();
				}
			}
		});

	};
	useEffect(() => {
		// if(selectedSubcategory && selectedCuisine && selectedCategory){
		getProducts();
		// }
	}, [selectedSubcategory]);

	const getProducts = () => {
		const country = storedCode ?? '';
		if (selectedSubcategory !== "undefined") {
			const request = {
				sub: selectedSubcategory,
				cuisine: selectedCuisine,
				category: selectedCategory,
				page: 1,
				pageSize: 500,
			};
			dispatch(getProductsAsync({ country: country, request })).then((res) => {
				if (res.payload) {
					setProducts(res.payload.data);
				}
			});
		}
	};


	const handleCategoryChange = (category: any) => {
		setSelectedCategory(category);
		setSelectedSubcategory(undefined);
	};
	const handleView = (cuisine: string, url: string) => {
		navigate(`/${storedCountry}/shop/cuisine/${cuisine}/${url}`);
	};

	// useEffect(() => {
	// 	if (guestId && storedCode) {
	// 	dispatch(getItemsByIdAsync({ id: guestId, country: storedCode }))
	// 	}
	// }, []);
	const handleAdd = (productId: number, ingredient: any) => {
		// if (!userId) {
		// 	console.error("User ID is not available");
		// 	setIsModalOpen(true);
		// 	return;
		// }
		if (guestId && storedCode) {
			//const newQuantity = (prev[productId] || 0) + 1;
			const request = {
				// group: `Shop- -1`,
				group: `Shop-0`,
				country: storedCode,
				items: [
					{
						...ingredient,
						selected: 1,
					},
				],
			};

			dispatch(addUpdateItemAsync({ id: guestId, request }))
				.then(() => {
					dispatch(getItemsByIdAsync({ id: guestId, country: storedCode })).then((response) => {
						console.log("response", response);
						const cartItems = response?.payload.data?.cart?.cart?.['Shop-0'] || [];
						const addedIds = new Set<number>(cartItems.map((item: any) => item.id));
						setAddedProducts(addedIds);
					});
					setPopupOpen(true);
				});

			//navigate(`/${storedCountry}/cart`);
			// });
		}

	};

	const handleCategoryClick = (name: string) => {
		setSelectedCuisine(name.toLowerCase())
		navigate(`/${storedCountry}/shop/cuisine/${name.toLowerCase()}`, { state: { cuisineName: name.toLowerCase() } });
	};
	console.log("products", products);
	return (
		<div>
			<div className={`flex flex-col ${isSmallScreen ? 'h-screen' : ''} w-screen mt-0 bg-[#F9F9F9] overflow-hidden`}>

				<div className={`flex-grow p-4 sm:p-6 lg:p-8 ${isSmallScreen ? 'overflow-hidden' : 'overflow-auto'}`}>
					<div className="mb-4">
						<h2 className="text-xl sm:text-2xl font-semibold text-black">Shop Unique Ingredients</h2>
					</div>

					<div className="flex justify-between mb-4 items-center">
						<h2 className="text-lg sm:text-xl font-medium text-black">Categories</h2>
						{/*		<div className="flex items-center text-red-500 cursor-pointer">
							<span className="mr-1 underline decoration-red-500" style={{ textUnderlineOffset: '3px' }}>
								View All
							</span>
							<ChevronRight size={20} />
						</div> */}
					</div>

					{/* Cuisines section */}
					<div className={`mb-4 relative bg-white rounded-lg ${isSmallScreen ? 'py-1' : 'py-3'} ${isSmallScreen ? 'w-full' : ''} ${isSmallScreen ? 'sticky top-0 z-10' : ''}`}> {/* Reduced py-2 for small screens */}
						<div
							ref={cuisineSliderRef}
							className={`flex overflow-x-auto py-1 justify-between  ${isSmallScreen ? 'space-x-1' : 'space-x-2 px-6'} bg-white scrollbar-hide`} >
							{cuisines.map((cuisineItem) => (
								<button
									key={cuisineItem}
									className={`px-3 py-1 text-transform: capitalize ${isSmallScreen ? 'text-sm font-medium' : 'text-md font-medium'} mx-1  rounded-full whitespace-nowrap transition-all ${selectedCuisine === cuisineItem.toLowerCase()
										? 'bg-[#FFC4C4] text-red '
										: 'bg-white text-black'
										}`}
									onClick={() => handleCategoryClick(cuisineItem)}
								>
									{cuisineItem}
								</button>
							))}
						</div>
						{!isSmallScreen && (
							<>
								<button
									onClick={() => scroll(cuisineSliderRef, 'left')}
									className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full p-1 ml-1 shadow-md"
								>
									<ChevronLeft size={15} />
								</button>
								<button
									onClick={() => scroll(cuisineSliderRef, 'right')}
									className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full p-1 mr-1 shadow-md"
								>
									<ChevronRight size={15} />
								</button>
							</>
						)}
					</div>


					{/* Categories section */}

					<div className={`mb-4 relative bg-white rounded-lg ${isSmallScreen ? 'py-1' : 'py-1 md:py-2'} ${isSmallScreen ? 'w-full sticky top-20 z-10' : ''}`}>
						<div
							ref={categorySliderRef}
							className="flex overflow-x-auto px-2 scrollbar-hide"
						>
							<div className={`flex ${isSmallScreen ? 'space-x-2 pr-3' : 'space-x-4 p-4 md:p-4'} bg-white rounded-lg`}> {/* Reduced height for larger screens */}
								{categoriesDetail && categoriesDetail.map((category: any) => (
									<button
										key={category.category}
										className={`flex flex-col items-center ${isSmallScreen ? 'p-1' : 'p-2'} rounded-lg transition-all mx-2 ${selectedCategory === category.category
											? 'border-2 border-red  shadow-md'
											: 'border-2 border-transparent hover:border-red'
											}`}
										// onClick={() => setSelectedCategory(category.category)}
										onClick={() => handleCategoryChange(category.category)}
									>
										<div className={`${isSmallScreen ? 'w-16 h-16' : 'w-20 h-20'} bg-greytext rounded-lg mb-2 flex items-center justify-center overflow-hidden`}> {/* Adjusted size for small screens */}
											<img src={category?.image && category?.image !== "NaN" ? category?.image : placeholder} alt={category.category} className="w-full h-full object-cover" />
										</div>
										<span className="text-xs text-center line-clamp-2">
											{/* {category.category.charAt(0).toUpperCase() + category.category.slice(1)} */}
											{capitalizeWords(category.category)}
										</span>
									</button>
								))}
							</div>
						</div>
						{!isSmallScreen && (
							<>
								<button
									onClick={() => scroll(categorySliderRef, 'left')}
									className="absolute right-auto top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full mr-14 ml-1 shadow-md"
								>
									<ChevronLeft size={20} />
								</button>
								<button
									onClick={() => scroll(categorySliderRef, 'right')}
									className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full ml-14 mr-1 shadow-md"
								>
									<ChevronRight size={20} />
								</button>
							</>
						)}
					</div>

					{/* Main content area */}

					<div className={`flex flex-row gap-3 ${isSmallScreen ? '' : 'overflow-y-auto'}`} style={{ height: isSmallScreen ? 'calc(100vh - 250px)' : 'auto' }}>

						{/* Subcategories sidebar */}
						<div className={`${isSmallScreen ? 'w-1/6' : 'w-46'} ${isSmallScreen ? 'sticky top-44 self-start' : ''}`}>
							<div
								className="bg-white p-1 rounded-lg shadow-md overflow-y-auto"
								style={{
									maxHeight: isSmallScreen ? 'calc(100vh - 310px)' : 'calc(100vh - 85px)',
								}}
							// style={{
							//   height: isSmallScreen ? '650px' : productGridRef.current ? `${productGridRef.current.clientHeight}px` : 'auto',
							//   maxHeight: isSmallScreen ? '400px' : 'calc(100vh - 250px)'
							// }}
							>
								<div className={`flex flex-col gap-y-5 space-y-2 rounded-lg ${isSmallScreen ? 'bg-white' : 'bg-white mx-auto'}`}>
									{subCategory.map((subCategory: any) => (
										<button
											key={subCategory.sub_category} // Use sub_category for unique keys
											className={`flex flex-col items-center transition-all rounded-lg text-sm ${selectedSubcategory === subCategory.sub_category
												? 'border-2 border-red  shadow-md'
												: 'border-2 border-transparent hover:border-red'
												}`}
											style={{
												margin: '1px',
												padding: '2px',
												borderRadius: '2px',
											}}
											onClick={() => setSelectedSubcategory(subCategory.sub_category)}
										>
											<div className={`${isSmallScreen ? 'w-18 h-18' : 'w-24 h-24 p-2'} bg-greylight rounded-lg mb-2 overflow-hidden`}>
												<img src={subCategory?.image && subCategory?.image !== "NaN" ? subCategory?.image : placeholder} alt={subCategory.sub_category} className="w-full h-full object-contain" />
											</div>
											<span className="text-xs text-center text-wrap">
												{/* {subCategory.sub_category.charAt(0).toUpperCase() + subCategory.sub_category.slice(1)} */}
												{capitalizeWords(subCategory.sub_category)}
											</span> {/* Display the sub_category name */}
										</button>
									))}
								</div>
							</div>
						</div>

						{/* Products grid */}
						<div ref={productGridRef} className={`${isSmallScreen ? 'w-5/6' : 'w-full'}`}>
							<div
								className="bg-white py-3 p-2 rounded-lg shadow-md overflow-y-auto"
								style={{
									maxHeight: isSmallScreen ? 'calc(100vh - 310px)' : 'calc(100vh - 85px)',
								}}>
								<div className={`grid ${isSmallScreen ? 'grid-cols-2 sm:grid-cols-3' : 'grid-cols-2 md:grid-cols-3 lg:grid-cols-5'} gap-4 rounded-lg bg-white px-1 py-2 overflow-y-auto`} style={{ height: isSmallScreen ? 'calc(100vh - 250px)' : 'auto', maxHeight: isSmallScreen ? 'calc(100vh - 250px)' : 'none' }}>
									{/* {products.length > 0 ? ( */}
									{products.map((product: any, index: number) => (
										<div key={index} className={`md:max-h-[275px] md:max-w-[240px] max-h-[250px] relative bg-white rounded-lg border cursor-pointer transition-all ${selectedProduct === product.name && product.status === "1.0" ? 'border-2 border-red-500 shadow-md' : 'border-2 border-[#EDEDED] hover:border-red'}`} onClick={() => {
											if (product.status === "1.0") {
												handleView(product.cuisine, product.url); setSelectedProduct(product.name);
											}

										}}>
											{/* {product.discount && (
                                           <div className={`absolute top-0 right-0 bg-[#54B22C] py-1 px-3 rounded-tr-l rounded-bl-2xl flex items-center justify-center ${isSmallScreen ? 'text-xs' : 'text-sm'}`}>
                                           <span className="font-semibold text-center text-[#FFFFFF]">{product.discount}% <br /> OFF</span>
                                         </div>)} */}
											<div className="w-full lg:w-full h-[120px] lg:h-[150px] md:h-[160px] bg-[#00000] rounded-lg mb-3 overflow-hidden px-4 py-1">
												<img src={product?.image && product?.image !== "NaN" ? product?.image : placeholder} alt={product.name} className="w-full h-full object-cover" />
											</div>
											<div className="border-t border-[#EDEDED] mx-2 mb-2"></div>
											<div>
												<h4 className="font-medium text-xs lg:text-sm md:text-sm mb-1 mx-3 text-[#000000] line-clamp-1 lg:line-clamp-1">
													{/* {product.name.charAt(0).toUpperCase() + product.name.slice(1)} */}
													{capitalizeWords(product.name)}
												</h4>
												{product.weight && <p className="text-xs text-[#222222] mx-3 mb-1">{product.weight}</p>}
												{/* {product.free && <p className="text-sm mx-3 text-[#54B22C] mb-1">{product.free}</p>} */}
												<div className="border-t border-[#EDEDED] mx-3 mt-2"></div>
												<div className="md:flex flex flex-row items-center justify-between">
													<div>
														<span className="font-bold text-xs lg:text-sm ml-3 text-[#3BB77E]">{storedCode === "IN" ? "₹" : "£"}{product.price}</span>
														{/* {product.originalPrice && <span className="text-[#606060] line-through text-xs ml-1 mb-2">₹{product.originalPrice}</span>} */}
													</div>
													<div className="flex justify-center">
														{product.status === "1.0" ? (
															// <button className="border border-green text-green hover:bg-green hover:text-white transition md:mx-4 py-1 my-2 rounded text-xs lg:text-sm mr-2 w-full max-w-xs px-2 md:px-4" onClick={(e) => {
															// 	e.stopPropagation();
															// 	handleAdd(product.id, product);
															// }}>Add</button>
															!addedProducts.has(product.id) ? (
																<button
																	className="border border-green text-green hover:bg-green hover:text-white transition md:mx-4 py-1 my-2 rounded text-xs lg:text-sm mr-2 w-full max-w-xs px-2 md:px-4"
																	onClick={(e) => {
																		e.stopPropagation();
																		handleAdd(product.id, product);
																	}}
																>
																	Add
																</button>
															) : (
																<button
																	className="bg-green text-white cursor-not-allowed opacity-50 md:mx-2 py-1 mx-1 my-2 rounded text-xs lg:text-xs mr-2 w-full max-w-xs lg:px-2 md:px-2 px-1"
																	disabled
																>
																	Added
																</button>
															)
														) : <button className="bg-gray-300 cursor-not-allowed opacity-50 md:mx-2 py-1 mx-1 my-2 rounded text-xs lg:text-xs mr-2 w-full max-w-xs lg:px-2 md:px-2 px-1" onClick={(e) => {

														}}>Out Of Stock</button>}

													</div>
												</div>


											</div>
										</div>
									))
										// ) : (
										//   <p>No products available for this subcategory.</p>
									}
								</div>
							</div>
						</div>

					</div>

				</div>


				{/* {!isSmallScreen && (
          <div className="flex justify-center mt-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )} */}
			</div>
			{popupOpen && (
				<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
					<div className="bg-white p-4 sm:p-8 rounded-xl shadow-2xl w-11/12 max-w-xs sm:max-w-md text-center">
						{/* SVG Icon (Check Mark with Circle) */}
						<div className="flex items-center justify-center mb-4">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="64"
								height="64"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								{/* Circle */}
								<circle cx="12" cy="12" r="10" stroke="#4CAF50" strokeWidth="2" fill="none" />
								{/* Tick */}
								<path d="M7 13l3 3 7-7" stroke="#4CAF50" strokeWidth="2" fill="none" />
							</svg>
						</div>
						<p className="text-lg sm:text-xl font-bold text-greytext mb-3 sm:mb-4">Item Added</p>
						<p className="text-xs sm:text-sm text-greytext mb-4">Item Added into Cart Successfully</p>
						{/* Centered OK Button */}
						<div className="flex justify-center mt-6 sm:mt-8">
							<button
								className="border border-green text-green hover:bg-green hover:text-white py-2 sm:py-3 px-4 sm:px-6 text-sm sm:text-base rounded-lg font-medium  transition-all"
								onClick={() => {
									setPopupOpen(false);
								}}
							>
								OK
							</button>
						</div>
					</div>
				</div>
			)}
			{isModalOpen && (
				<LoginModal
					onClose={() => {
						setIsModalOpen(false);
					}}
					isOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
				/>
			)}
		</div>
	);
}

export default IngredientShop;