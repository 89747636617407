import React, { useState } from "react";
import CuisineFilter from "./CuisineFilter";
import SortFilter from "./SortFilter";

interface ModalProps {
  setSelectedCuisines: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCuisines: string[];
  selectedOption: any;
  setSelectedOption: any;
  getPersonaRecipeDetails: any;
  setIsVeg: React.Dispatch<React.SetStateAction<boolean>>;
  isVeg: boolean;
  persona: any;
}
const FilterSection: React.FC<ModalProps> = ({ selectedCuisines, setSelectedCuisines, selectedOption, setSelectedOption, getPersonaRecipeDetails, setIsVeg, isVeg, persona }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);

  const handleClick = () => {
    setIsVeg(prevState => !prevState);
  };
  return (
    <div className="flex space-x-4 lg:pt-8 md:pt-8 pt-0 px-5 lg:px-10 md:px-8 lg:pb-12 md:pb-12 pb-5 bg-graybg">
      {/* Sort Button */}
      <button className="flex items-center hover:border-green-hover border border-[#525252] rounded-lg lg:px-6 md:px-6 px-2 lg:py-2 md:py-2 py-1 lg:text-lg md:text-lg text-md" onClick={() => setIsSortOpen(true)}>
        <span className="font-sans text-black pr-2">Sort</span>
        <button >
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7273 4.27686H19"
              stroke="#313131"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 4.27686H12.4545"
              stroke="#313131"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.18188 14.095H19.0001"
              stroke="#313131"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 14.095H5.90909"
              stroke="#313131"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.4546 1.82227V6.73136"
              stroke="#313131"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.90918 11.6404V16.5495"
              stroke="#313131"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </button>

      {/* Cuisines Button */}
      <button className="flex items-center hover:border-green-hover border border-[#525252]  rounded-lg lg:px-6 md:px-6 px-2 lg:py-2 md:py-2 py-1 lg:text-lg md:text-lg text-md" onClick={() => setIsModalOpen(true)}>
        <span className="font-sans text-black pr-2">Cuisines</span>
        <button >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 19.1858C14.9706 19.1858 19 15.1564 19 10.1858C19 5.21523 14.9706 1.18579 10 1.18579C5.02944 1.18579 1 5.21523 1 10.1858C1 15.1564 5.02944 19.1858 10 19.1858Z"
              stroke="#313131"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.25 8.68579L10 12.4358L13.75 8.68579"
              stroke="#313131"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </button>

      {/* Pure Veg Button */}

      <button
        onClick={handleClick}
        className={`flex items-center border border-[#525252] hover:border-green-hover rounded-lg lg:px-6 md:px-6 px-2 lg:py-2 md:py-2 py-1 lg:text-lg md:text-lg text-md  ${isVeg ? 'bg-[#28A745] text-white' : 'bg-white text-black'}`}
      >
        Pure Veg
      </button>
      {isModalOpen && (
        <CuisineFilter onClose={() => { setIsModalOpen(false) }} persona={persona} isOpen={isModalOpen} setSelectedCuisines={setSelectedCuisines} selectedCuisines={selectedCuisines} />
      )}
      {isSortOpen && (
        <SortFilter onClose={() => { setIsSortOpen(false) }} isOpen={isSortOpen} selectedOption={selectedOption} setSelectedOption={setSelectedOption} getPersonaRecipeDetails={getPersonaRecipeDetails} />
      )}
    </div>
  );
};

export default FilterSection;
