import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import edit from "../../../assets/images/pencilEdit.svg";
import { format, addDays, isSameDay } from "date-fns";
import im1 from "../../../assets/images/cross.png";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  createOrderAsync,
  deleteCartAsync,
  getAdressByUserIdAsync,
  getItemsByIdAsync,
  getSubscriptionInfoAsync,
  updateAddressAsync,
} from "../../../services/reducers/reciepeReducer";
import { loadStripe } from "@stripe/stripe-js";
import { Constants } from "../../../services/sharedService/constants";
import {
  createCod,
  sendOTP,
} from "../../../services/sharedService/AuthService";
import AddressModal from "../../CheckOut/AddressModal";
import tap from "../../../assets/images/tap.png";
import { ChevronDown } from "lucide-react";
import axios from "axios";
import OTPForm from "../../Home/OtpModal";
import RazorpayPayment from "../../CheckOut/CheckOutWithRozarpay";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../CheckOut/CheckOut";
import logo from "../../../assets/images/logo3.png";

interface CheckoutProps { }

const CartCheckoutPage: FC<CheckoutProps> = ({ }) => {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(1);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [isAddressConfirmed, setIsAddressConfirmed] = useState(false);
  const [isTimeSlotConfirmed, setIsTimeSlotConfirmed] = useState(false);
  const isPayOnDeliveryEnabled = isAddressConfirmed && isTimeSlotConfirmed;
  const [isAddressOpen, setIsAddressOpen] = useState(false);
  const [isTimeSlotOpen, setIsTimeSlotOpen] = useState(true);
  const [lastConfirmed, setLastConfirmed] = useState(" ");
  const navigate = useNavigate();
  const storedCountry = localStorage.getItem("selectedCountry");
  const [cartIngredients, setCartIngredients] = useState<any>([]);
  const [cartAddress, setCartAddress] = useState<any>([]);
  const storedCode = localStorage.getItem("countryCode");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("firstName") || "User";
  const stripePromise = loadStripe(Constants?.loadStripe);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  const guestId = localStorage.getItem("guestId");
  const [extra, setExtra] = useState<any>([]);
  const [cartTotal, setCartTotal] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [showLeftSection, setShowLeftSection] = useState(false);
  const [cartID, setCartID] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [addressId, setAddressId] = useState(null);

  const handleView = () => {
    navigate(`/${storedCountry}/checkout`);
  };

  const tomorrow = addDays(new Date(), 1);
  const [selectedDate, setSelectedDate] = useState<Date>(tomorrow);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("12 pm to 6 pm");
  const [isTimeSlotMinimized, setIsTimeSlotMinimized] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const itemsToShow = 7;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
  const isTimeSlotSelected = selectedSlot !== null && selectedDate !== null;
  const [selectedDateIndex, setSelectedDateIndex] = useState<number | null>(
    null
  );
  const [ipAddress, setIpAddress] = useState<string | null>(null);
  const [cartTotalCount, setCartTotalCount] = useState(0);
  const carts = useAppSelector((state) => state.receipe.itemsList);
  const [showTimeSlots, setShowTimeSlots] = useState<number | null>(null);
  const [EditAddressData, setEditAddressData] = useState(null);

  const getNextTwoDates = () => {
    const today = new Date();
    return [addDays(today, 2), addDays(today, 3)];
  };

  const [nextDates] = useState(getNextTwoDates());
  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
    setIsPopupOpen(false);
  };

  {
    nextDates.map((date, index) => (
      <div
        key={index}
        onClick={() => handleDateSelect(date)} // Call the handler on date click
      >
        {format(date, "EEEE, MMMM d, yyyy")}
      </div>
    ));
  }

  const formatDateTime = (date: Date, timeSlot: string) => {
    const formattedDate = date.toISOString().split("T")[0];
    const hours = timeSlot.startsWith("9") ? "09" : "12";
    return `${formattedDate}T${hours}:00:00Z`;
  };

  const formattedDateTime = formatDateTime(selectedDate, selectedTimeSlot);
  localStorage.setItem("expectedDelivery", formattedDateTime);

  useEffect(() => {
    const country = storedCode ?? "";
    if (guestId) {
      dispatch(getItemsByIdAsync({ id: guestId, country })).then((res) => {
        if (res.payload) {
          setCartIngredients(res.payload?.data?.cart?.cart || {});
          setCartTotal(res.payload?.data?.cart || 0);
          setExtra(res.payload?.data?.extras || []);
        }
        new Promise((resolve) => setTimeout(resolve, 2000));
        setLoading(false);
      });
    }
  }, [guestId]);

  const clearCart = () => {
    setCartIngredients({});
    setCartTotal({ cart_value: 0 });
    // console.log("Cart has been cleared");
  };
  const calculateTotal = () => {
    const discount =
      detail?.discount_type === "percent"
        ? -(cartTotal?.cart_value * detail.discount) / 100
        : -detail.discount;

    const extraCharges = extra.reduce(
      (acc: any, curr: any) => acc + curr.charge,
      0
    )+ (user === 0 ? 0 : user === 1 ? (storedCode === "UK" ? 10 : 149) : user === 2 ? (storedCode === "UK" ? 20 : 599) : 0);

    return (cartTotal?.cart_value || 0) + (discount || 0) + extraCharges;
  };

  useEffect(() => {
    setTotal(calculateTotal().toFixed(2));
  }, [cartTotal, detail, extra]);

  // useEffect(() => {}, [detail]);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIpAddress();
  }, []);

  useEffect(() => {
    if (carts && carts.data.cart) {
      const cartCount = Object.values(carts.data.cart.lookup).flat().length;
      setCartTotalCount(cartCount);
      // console.log(cartCount, "cartCount");
    }
  }, [carts]);
  const user = useAppSelector((state) => state.receipe.user);

  // useEffect(() => {
  //   if (userId) {
  //     dispatch(getSubscriptionInfoAsync(userId)).then((res) => {
  //       if (res.payload) {
  //         const subscriptionDateTimestamp = new Date(
  //           res.payload.data.subscription_date
  //         ).getTime();
  //         const currentDateTimestamp = res.payload.data.current_date;
  //         //const status = res.payload.data.subscription_status;
  //         // console.log(subscriptionDateTimestamp, "subscriptionDateTimestamp");
  //         // Calculate the difference in days
  //         const differenceInTime =
  //           currentDateTimestamp - subscriptionDateTimestamp;
  //         const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  //         if (differenceInDays > 3650) {
  //           // console.log("Difference in days is greater than 3650.");
  //           navigate(`/${storedCountry}/subscription`);
  //         }

  //         // if (subscriptionDateTimestamp > currentDateTimestamp) {
  //         //     console.log("Subscription date is greater than current date.");
  //         //   } else if (status === 0 && subscriptionDateTimestamp < currentDateTimestamp) {
  //         //     console.log("Current date is greater than subscription date.");
  //         //     navigate(`/subscription`);
  //         //   }
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    getAddress();
  }, []);

  const getAddress = () => {
    if (userId) {
      dispatch(getAdressByUserIdAsync(userId)).then((res) => {
        if (res.payload) {
          setCartAddress(res.payload?.data);
          console.log("ADDR", res);
          if (
            !selectedAddress &&
            res.payload?.data &&
            res.payload?.data.length > 0
          ) {
            const country = storedCode == "UK" ? "GB" : "IN";
            const addresses = res.payload?.data.filter(
              (addr: any) => addr.country == country
            );
            if (addresses.length > 0) {
              handleAddressSelect(addresses[0].address_id);
            }
          }
        }
      });
    }
  };
  console.log("ADDRESS", cartAddress);

  const address = cartAddress.find(
    (addr: any) => addr.address_id === selectedAddress
  );
  const handleAddressSelect = (address: any) => {
    setSelectedAddress(address);
  }

  const [currentModal, setCurrentModal] = useState<"main" | "otp">("main");
  const userPhone = localStorage.getItem("userPhone") || "";
  const handleContinueClick = () => {
    setCurrentModal("otp");
    sendOTP(userPhone, "web").then(() => {
      // toast.success("OTP sent successfully.");
      setCurrentModal("otp");
    });
  };

  const handleOTPModalClose = () => {
    setCurrentModal("main");
  };

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [getPhone, setGetPhone] = useState(false);
  const handlePhoneChange = (value: string | undefined) => {
    if (value && value.length > 13) {
      setPhoneError("Phone number cannot exceed 10 digits.");
      setPhone(value.substring(0, 13));
      return;
    }
    setPhone(value || "");
    setPhoneError(null);
  };

  const handleAddressTransfer = () => {
    if (!userId) {
      console.error("User ID is not available");
      setIsModalOpen(true);
      return;
    }

    getAddress();
    setAddressModalOpen(!isAddressModalOpen);
  };
  const [showMessage, setShowMessage] = useState(false);
  const [emptyCartError, setEmptyCartError] = useState(false);
  const [addressesLoading, setAddressesLoading] = useState(true);
  const [cartLoading, setCartLoading] = useState(true);

  useEffect(() => {
    // console.log("OTP Success state updated:", otpSuccess);
    if (otpSuccess) {
      createCodAsync();
    }
  }, [otpSuccess]);

  useEffect(() => {
    setLoading(addressesLoading || cartLoading);
  }, [addressesLoading, cartLoading]);

  const createCodAsync = async () => {
    if (isPayOnDeliveryEnabled) {
      if (!selectedAddress) {
        // console.log("Please select an address.");
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 3000);
        return;
      }

      // console.log(selectedAddress);
      handleContinueClick();
      // console.log("OTP ", otpSuccess);
      if (otpSuccess == true) {
        if (
          !cartIngredients ||
          Object.keys(cartIngredients).length === 0 ||
          cartTotal.cart_value === 0
        ) {
          // console.log("CART IS EMPTY");
          setEmptyCartError(true);
          setTimeout(() => setEmptyCartError(false), 3000);
          return;
        }
        if (selectedAddress) {
          const products = Object.keys(cartIngredients).flatMap(
            (dishKey: string) => {
              const dishId = Number(dishKey.split("-")[1]);
              const ingredients = cartIngredients[dishKey];

              return ingredients.map((ingredient: any) => ({
                ingredient_id: ingredient.id,
                name: ingredient.name,
                recipe_id: dishId,
                quantity: ingredient.selected,
              }));
            }
          );

          const requestBody = {
            order: {
              order_date: new Date().toISOString(),
              paid_date: new Date().toISOString(),
              phone: "",
              name: userName ?? "",
              status: "pending",
              currency: storedCode == "IN" ? "INR" : "GBP",
              ip_address: ipAddress,
              user_agent: "web",
              user_id: userId,
              shipping_address: address?.street_address,
              shipping_postcode: address?.postal_code,
              shipping_city: address?.city,
              shipping_state: "",
              shipping_country: address?.country,
              shipping_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
              order_subtotal: parseFloat(cartTotal?.cart_value.toFixed(2)),
              order_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
              discount_total: detail.discount,
              coupon_id: detail.id,
              expected_delivery: formattedDateTime,
            },
            products: products,
          };

          const createOrderResponse = await dispatch(
            createOrderAsync(JSON.stringify(requestBody))
          ).unwrap();

          if (
            createOrderResponse.success &&
            createOrderResponse.data &&
            createOrderResponse.data.orderId
          ) {
            const cartOrderId = createOrderResponse.data.orderId;
            // console.log("Order created successfully. Order ID:", cartOrderId);

            const codResult = createCod(cartOrderId);

            if (userId) {
              const reqBody = {
                country: storedCode,
              };
              const deleteResponse = dispatch(
                deleteCartAsync({ id: userId, reqBody })
              ).unwrap();
              //window.location.reload();
            }
            clearCart();
            setCartID(cartOrderId);

            // handleConfirm(cartOrderId);
            if (cartOrderId) {
              handleConfirm(cartOrderId);
            }
          }
        } else {
          setShowMessage(true);
          setTimeout(() => setShowMessage(false), 3000);
          return;
        }
      } else {
        // console.log("OTP not verified");
      }
    }
  };
  const handleConfirm = (id: string) => {
    navigate(`/${storedCountry}/OrderConfirm/${id}`);
    window.scrollTo(0, 0);
  };
  const handleEdit = (id: any) => {
    setAddressModalOpen(true);
    setAddressId(id);
    console.log("edit id:", id);

    if (userId) {
      const selectedAddress = cartAddress.find(
        (address: any) => address.address_id === id
      );
      if (!selectedAddress) return;

      const reqBody = {
        street_address: selectedAddress.street_address,
        city: selectedAddress.city,
        state_province: selectedAddress.state_province,
        postal_code: selectedAddress.postal_code,
        country: selectedAddress.country,
      };

      dispatch(updateAddressAsync({ id, reqBody })).then((res) => {
        if (res.payload?.success) {
          setEditAddressData(res.payload.data);
          // setAddressModalOpen(false);
        }
      });
    }
  };
  const handleAddClick = () => {
    openAddressModal();
  };
  const openAddressModal = (addressId = null, addressData = null) => {
    setAddressId(addressId);
    setEditAddressData(addressData);
    setAddressModalOpen(true);
  };
  const stripeMessage = () => {
    setIsModalOpen(true);
  };

  const handleAddNewAddress = () => {
    setSelectedAddress(0);
    handleAddressTransfer();
  };

  const handleEditAddress = (id: any) => {
    setSelectedAddress(id);
    handleEdit(id);
  };

  return (
    <>
      {loading && (
        <div className="min-h-screen flex justify-center items-center">
          <div role="status">
            <svg
              aria-hidden="true"
              className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="red"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="gray"
              />
            </svg>
          </div>
          {/* <div>
          <p className="font-medium mt-6 font-sans text-xl">Loading...</p>
          </div> */}
        </div>
      )}
      <div className={`{loading ? 'hidden' : ''} flex justify-between items-center shadow-md border-b border-gray-300 py-2 px-4`}>
        <div>
          <Link to="/">
            <img src={logo} alt="logo" height={70} width={150}></img>
          </Link>
        </div>
        <span>
          Signed in as {userName} ({userPhone})
        </span>
      </div>
      <div className="lg:p-10 p-6">
        <span className="text-xl font-bold">Checkout</span>
        <div className="flex">
          {/* Left Side -  Cart Items */}
          <div className="lg:w-[70%] lg:p-4 p-0 lg:mr-4 w-full md:w-full">
            {/* Address Container */}
            <div className="border p-4 rounded-lg">
              <div className="flex justify-between items-center">
                <div className="flex flex-row gap-x-2 items-center">
                  <div className="rounded-full items-center flex bg-green-100 py-1 px-3 text-sm font-medium text-[#28A745]">
                    1
                  </div>
                  <span className="font-medium">Select a Delivey Address</span>
                </div>

                {!isAddressOpen && (
                  <button
                    className="text-[#28A745] font-medium text-sm"
                    onClick={() => {
                      setIsAddressOpen(true);
                      // setIsTimeSlotOpen(false);
                    }}
                  >
                    {address?.street_address ? (
                      <span>Change</span>
                    ) : (
                      <span>Add Address</span>
                    )}

                  </button>
                )}
              </div>
              {!isAddressOpen && (

                <div className="mt-4 text-gray-600 p-4 border flex flex-row items-center text-sm font-medium">
                  {address?.street_address ? (
                    <>
                      <span className="">
                        Your selected home delivery address is{" "}
                        <span className="lg:hidden font-semibold">
                          {address?.street_address}, {address?.city},{" "}
                          {address?.postal_code}, {address?.country}
                        </span>
                      </span>
                      <div className="hidden lg:block ml-1 font-semibold">
                        {address?.street_address},{address?.city},
                        {address?.postal_code},{address?.country}
                      </div>
                    </>
                  ) : (<span>Please click on Add address</span>)}
                </div>

              )}
              {isAddressOpen && (
                <>
                  <div className="mt-4 bg-greylight p-3 shadow-md flex flex-row items-center justify-between">
                    <span className="font-medium">Saved Address</span>
                    <span
                      className="font-semibold text-[#28A745] cursor-pointer"
                      onClick={handleAddClick}
                    >
                      + Add new Address
                    </span>
                  </div>
                  {isAddressModalOpen && (
                    <AddressModal
                      type={"cart"}
                      isOpen={isAddressModalOpen}
                      onClose={() => setAddressModalOpen(false)}
                      getAddress={getAddress}
                      // addressId={addressId}
                      cartAddress={getAddress}
                      // address={EditAddressData}
                      addressId={addressId ? addressId : undefined}
                      address={addressId ? EditAddressData : undefined}
                    />
                  )}
                  {/* Address cards */}
                  <div className="lg:p-8 p-2">
                    {cartAddress
                      .filter((address: any) => {
                        const countryMappings: { [key: string]: string[] } = {
                          IN: ["IN", "India"],
                          GB: ["GB", "UK", "United Kingdom"],
                        };

                        if (storedCode) {
                          const normalizedSelectedCountry = Object.keys(
                            countryMappings
                          ).find((key) =>
                            countryMappings[key].includes(storedCode)
                          );
                          console.log("Stored Country Code:", storedCode);
                          console.log(
                            "Normalized Selected Country:",
                            normalizedSelectedCountry
                          );

                          return (
                            normalizedSelectedCountry &&
                            countryMappings[normalizedSelectedCountry].includes(
                              address.country
                            )
                          );
                        }
                      })
                      .map((address: any, index: number) => (
                        <div
                          key={address.address_id}
                          className={`border p-3 mt-4 flex items-center justify-between mb-3 rounded-md ${selectedAddress === address.address_id
                            ? "border-green-500"
                            : "border-gray-400"
                            }`}
                        >
                          <input
                            type="radio"
                            name="address"
                            className="mr-2 w-5 h-5 accent-[#28A745]"
                            // checked={selectedAddress === address.address_id}
                            checked={
                              selectedAddress === address.address_id ||
                              (index === 0 && !selectedAddress)
                            }
                            onChange={() =>
                              handleAddressSelect(address.address_id)
                            }
                          />
                          <div className="flex flex-col pl-2">
                            <span className="font-medium text-md">
                              {userName}
                            </span>
                            <span className="text-gray-500 text-sm">
                              {address.flat_no ? `${address.flat_no}, ` : ""}
                              {address.street_address
                                ? `${address.street_address}, `
                                : ""}
                              {address.city}, {address.state_province},{" "}
                              {address.postal_code}, {address.country}
                            </span>
                          </div>
                          {/* Edit Button */}
                          <button
                            onClick={() => {
                              if (selectedAddress === address.address_id) {
                                handleEditAddress(address.address_id);
                              }
                            }}
                            className={`ml-auto text-sm font-semibold ${selectedAddress === address.address_id
                              ? "text-green-500 cursor-pointer"
                              : "text-gray-500"
                              }`}
                          >
                            <div className="flex flex-row items-center gap-x-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill={edit ? "currentColor" : "none"}
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M20.7,5.2a1.024,1.024,0,0,1,0,1.448L18.074,9.276l-3.35-3.35L17.35,3.3a1.024,1.024,0,0,1,1.448,0Zm-4.166,5.614-3.35-3.35L4.675,15.975,3,21l5.025-1.675Z"
                                />
                              </svg>
                              Edit
                            </div>
                          </button>
                        </div>
                      ))}
                  </div>

                  <div className="flex flex-row justify-end mt-4 border-t-2">
                    <button
                      className="mt-4 px-5 py-3 bg-green hover:bg-green-hover text-white rounded-lg text-sm"
                      onClick={() => {
                        setIsAddressConfirmed(true);
                        setIsAddressOpen(false);
                        setLastConfirmed("address");

                        // if (!isTimeSlotConfirmed) {
                        //   setIsTimeSlotOpen(true);
                        // }
                      }}
                    >
                      CONFIRM ADDRESS
                    </button>
                  </div>
                </>
              )}
            </div>

            {/* Time Slot Container */}
            <div className="border p-4 mt-4">
              <div className="flex justify-between items-center">
                <div className="flex flex-row gap-x-2 items-center">
                  <div className="rounded-full items-center flex bg-green-100 py-1 px-3 text-sm font-medium text-[#28A745]">
                    2
                  </div>
                  <span className="font-medium">Select a time slot</span>
                </div>

                {isTimeSlotConfirmed && !isTimeSlotOpen && (
                  <button
                    className="text-[#28A745] font-medium text-sm"
                    onClick={() => {
                      setIsTimeSlotOpen(true);
                      setIsAddressOpen(false);
                    }}
                  >
                    Change
                  </button>
                )}
              </div>
              {isTimeSlotConfirmed && !isTimeSlotOpen && (
                <div className="mt-4 text-greytext p-4 border flex flex-row items-center text-sm font-medium">
                  <span className="font-semibold">
                    {`${format(
                      selectedDate,
                      "EEEE, MMMM d, yyyy"
                    )} - ${selectedSlot}`}
                  </span>
                </div>
              )}

              {isTimeSlotOpen && (
                <>
                  {/* Time slot selection */}
                  <div className="lg:p-4 p-2 mt-4 flex flex-row justify-between">
                    <button
                      className={`lg:px-5 px-2 py-2 ${selectedSlot && selectedDate
                        ? "text-sm font-medium text-greytext"
                        : "border bg-green-100 border-green-500 border-dashed"
                        }`}
                      onClick={() => setIsPopupOpen(true)}
                    >
                      {selectedSlot && selectedDate ? (
                        `${format(
                          selectedDate,
                          "EEEE, MMMM d, yyyy"
                        )} - ${selectedSlot}`
                      ) : (
                        <div className="flex flex-row items-center">
                          <img src={tap} alt="tap" className="w-6 h-6 mr-2" />
                          Tap to Select
                        </div>
                      )}
                    </button>
                    {selectedSlot && selectedDate && (
                      <button
                        className="text-[#28A745] font-medium text-sm"
                        onClick={() => setIsPopupOpen(true)}
                      >
                        Change
                      </button>
                    )}
                  </div>

                  <div className="flex flex-row justify-end mt-4 border-t-2">
                    <button
                      className={`mt-4 px-5 py-3 ${isTimeSlotSelected
                        ? "bg-green hover:bg-green-hover"
                        : "bg-gray-400 cursor-not-allowed"
                        } text-white rounded-lg text-sm`}
                      onClick={() => {
                        setIsTimeSlotConfirmed(true);
                        setIsTimeSlotOpen(false);
                        setIsAddressConfirmed(true);
                        setIsAddressOpen(false);
                        setLastConfirmed("address");
                        setIsPopupOpen(false);
                        setIsTimeSlotMinimized(true);

                        const formattedDateTime = formatDateTime(
                          selectedDate,
                          selectedSlot || ""
                        );
                        localStorage.setItem(
                          "expectedDelivery",
                          formattedDateTime
                        );
                        console.log(formattedDateTime);
                      }}
                    >
                      CONFIRM TIME SLOT
                    </button>
                  </div>
                </>
              )}
              {/*  pop up */}
              {isPopupOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center overflow-hidden">
                  <div className="bg-white rounded-lg px-8 pb-8 lg:w-1/3 md:w-1/3 xl:w-1/3 w-3/4 overflow-hidden">
                    <div className="flex justify-between items-center -mt-6 p-2 bg-[#FAFCFE] -ml-8 -mr-8 ">
                      <h2 className="lg:text-xl font-bold mt-8 ml-2">
                        Select a Time Slot
                      </h2>
                      <button
                        onClick={() => {
                          setIsPopupOpen(false);
                        }}
                      >
                        <img
                          src={im1}
                          alt="Close"
                          className="w-4 h-4 mt-8 mr-2"
                        />
                      </button>
                    </div>

                    <div className="flex flex-col gap-2 pt-5">
                      {nextDates.map((date, index) => (
                        <div
                          key={index}
                          className={`flex flex-col border p-3 rounded-md cursor-pointer transition-colors ${showTimeSlots === index
                            ? "border-green-500"
                            : "border-gray-300"
                            }`}
                        >
                          <div
                            className="flex justify-between items-center"
                            onClick={() => {
                              setSelectedDateIndex(index);
                              setShowTimeSlots(
                                showTimeSlots === index ? null : index
                              );
                            }}
                          >
                            <span className="lg:text-md text-xs">
                              {format(date, "EEEE, MMMM d, yyyy")}
                            </span>
                            <span className="ml-2">
                              {/* Chevron Icon */}
                              <ChevronDown size={16} className="" />
                            </span>
                          </div>
                          {showTimeSlots === index && (
                            <div className="flex flex-row mt-2 gap-2 lg:text-sm text-xs font-medium text-greytext ">
                              <button
                                className="lg:px-10 px-2 py-2 border rounded-md hover:bg-green-100"
                                onClick={() => {
                                  setSelectedSlot("9 AM - 12 PM");
                                  setSelectedDate(
                                    nextDates[selectedDateIndex || 0]
                                  );
                                  setIsPopupOpen(false);
                                }}
                              >
                                9 AM - 12 PM
                              </button>
                              <button
                                className="lg:px-10 px-2 py-2 border rounded-md hover:bg-green-100"
                                onClick={() => {
                                  setSelectedSlot("12 PM - 6 PM");
                                  setSelectedDate(
                                    nextDates[selectedDateIndex || 0]
                                  );
                                  setIsPopupOpen(false);
                                }}
                              >
                                12 PM - 6 PM
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <button
                      className="mt-4 px-5 py-2 bg-red-500 text-white rounded-lg"
                      onClick={() => setIsPopupOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="lg:hidden flex flex-col my-10">
              {storedCode === "UK" && (
                <button
                  className={`w-full py-2 ${isPayOnDeliveryEnabled
                    ? "border-green hover:bg-green-hover hover:text-white text-green"
                    : "border-gray-400 text-gray-400 cursor-not-allowed"
                    } border rounded-lg text-sm font-semibold`}
                  onClick={stripeMessage}
                >
                  PAY NOW (PREPAID)
                </button>
              )}

              {storedCode === "IN" && (
                <RazorpayPayment
                  cartTotal={cartTotal}
                  price={total}
                  cartIngredients={cartIngredients}
                  ipAddress={ipAddress}
                  address={address}
                  selectedAddress={selectedAddress}
                  detail={detail}
                  countryCode={storedCode}
                  isPaymentEnabled={isPayOnDeliveryEnabled}
                />
              )}
              {storedCode == "UK" && (
                <Elements stripe={stripePromise}>
                  {isModalOpen && (
                    <CheckoutForm
                      isOpen={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      cartTotal={cartTotal}
                      price={cartTotal?.cart_value}
                      cartIngredients={cartIngredients}
                      ipAddress={ipAddress}
                      address={address}
                      selectedAddress={selectedAddress}
                      detail={detail}
                      countryCode={storedCode}
                    />
                  )}
                </Elements>
              )}
              {user === 0 && (<>
                <div className="flex items-center gap-2 py-3">
                  <div className="flex-1 border-t-2 border-gray-300"></div>
                  <span className="text-sm text-gray-300 font-semibold">OR</span>
                  <div className="flex-1 border-t-2 border-gray-300"></div>
                </div>
                <div>
                  <button
                    className={`w-full py-2 ${isPayOnDeliveryEnabled
                      ? "bg-green hover:bg-green-hover"
                      : "bg-gray-400 cursor-not-allowed"
                      } text-white rounded-lg text-sm font-semibold`}
                    onClick={() => {
                      createCodAsync();
                    }}
                    disabled={!isPayOnDeliveryEnabled}
                  >
                    PAY ON DELIVERY (COD)
                  </button>
                  {showMessage && (
                    <div
                      style={{
                        position: "fixed",
                        bottom: "20px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: "red",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        zIndex: 1000,
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      Please select an address.
                    </div>
                  )}
                </div>
              </>)}
              {currentModal === "otp" && (
                <OTPForm
                  type="cart"
                  email=""
                  setOtpSuccess={setOtpSuccess}
                  cartID={cartID}
                  onClose={handleOTPModalClose}
                  isOpen={true}
                  phone={userPhone}
                  setIsModalOpen={setIsModalOpen}
                  handlePhoneChange={handlePhoneChange}
                />
              )}
            </div>
          </div>

          {/* Right Side - Order Summary */}
          <div className="w-[30%] p-4 ml-6 hidden md:hidden lg:block">
            <div className="flex flex-col gap-y-4">
              {/* Price Summary */}
              <div className="flex flex-col border rounded-lg">
                <div className="p-4 items-center border-b">
                  <span className="text-lg font-medium">Price Summary</span>
                  <span className="text-xs text-gray-400 ml-2">
                    {cartTotalCount} products
                  </span>
                </div>

                <div className="flex flex-col p-4">
                  <div className="flex flex-row justify-between">
                    <span className="text-md text-greytext">Cart Total</span>
                    <span className="font-semibold">
                      {" "}
                      {storedCode === "IN" ? "₹" : "£"} {total}
                    </span>
                  </div>

                  {extra.map((extra: any, index: number) => (
                    <div
                      key={index}
                      className="flex flex-row justify-between py-4 border-t"
                    >
                      <span className="text-md text-greytext">
                        {extra.name}
                      </span>
                      <span className="font-semibold text-red">
                        {storedCode === "IN" ? "₹" : "£"}
                        {extra.charge}
                      </span>
                    </div>
                  ))}

                  <div className="flex flex-row justify-between border-t pt-4">
                    <span className="text-md text-greytext">Subscription Charges</span>
                    <span className="font-semibold text-[#28A745]">{storedCode === "IN" ? "₹" : "£"}
                      {user === 0 ? 0 : user === 1 ? (storedCode === "UK" ? 10 : 149) : user === 2 ? (storedCode === "UK" ? 20 : 599): null}</span>
                  </div>
                </div>
                {/* Tax info */}
                {/* <div className="bg-blue-100 p-2">
                <span className="text-xs text-greytext">
                  Tax of <span className="font-semibold"> ₹ 29.14</span> has
                  been included in the total amount
                </span>
              </div> */}
              </div>

              {/* Checkout Button */}
              <div className="flex flex-col">
                {storedCode === "UK" && (
                  <button
                    className={`w-full py-2 ${isPayOnDeliveryEnabled
                      ? "border-green hover:bg-green-hover hover:text-white text-green"
                      : "border-gray-400 text-gray-400 cursor-not-allowed"
                      } border rounded-lg text-sm font-semibold`}
                    onClick={stripeMessage}
                  >
                    PAY NOW (PREPAID)
                  </button>
                )}

                {storedCode === "IN" && (
                  <RazorpayPayment
                    cartTotal={cartTotal}
                    price={total}
                    cartIngredients={cartIngredients}
                    ipAddress={ipAddress}
                    address={address}
                    selectedAddress={selectedAddress}
                    detail={detail}
                    countryCode={storedCode}
                    isPaymentEnabled={isPayOnDeliveryEnabled}
                  />
                )}
                {storedCode == "UK" && (
                  <Elements stripe={stripePromise}>
                    {isModalOpen && (
                      <CheckoutForm
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        cartTotal={cartTotal}
                        price={cartTotal?.cart_value}
                        cartIngredients={cartIngredients}
                        ipAddress={ipAddress}
                        address={address}
                        selectedAddress={selectedAddress}
                        detail={detail}
                        countryCode={storedCode}
                      />
                    )}
                  </Elements>
                )}

                {user === 0 && (<>
                  <div className="flex items-center gap-2 py-3">
                    <div className="flex-1 border-t-2 border-gray-300"></div>
                    <span className="text-sm text-gray-300 font-semibold">
                      OR
                    </span>
                    <div className="flex-1 border-t-2 border-gray-300"></div>
                  </div>
                  <div>
                    <button
                      className={`w-full py-2 ${isPayOnDeliveryEnabled
                        ? "bg-green hover:bg-green-hover"
                        : "bg-gray-400 cursor-not-allowed"
                        } text-white rounded-lg text-sm font-semibold`}
                      onClick={() => createCodAsync()}
                    // disabled={!isPayOnDeliveryEnabled}
                    >
                      PAY ON DELIVERY (COD)
                    </button>
                    {showMessage && (
                      <div
                        style={{
                          position: "fixed",
                          bottom: "20px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          backgroundColor: "red",
                          color: "white",
                          padding: "10px 20px",
                          borderRadius: "5px",
                          zIndex: 1000,
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        Please select an address.
                      </div>
                    )}
                  </div>
                </>)}
                {currentModal === "otp" && (
                  <OTPForm
                    type="cart"
                    email=""
                    setOtpSuccess={setOtpSuccess}
                    cartID={cartID}
                    onClose={handleOTPModalClose}
                    isOpen={true}
                    phone={userPhone}
                    setIsModalOpen={setIsModalOpen}
                    handlePhoneChange={handlePhoneChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartCheckoutPage;
