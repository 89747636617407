import React from "react";

const CancellationRefunds: React.FC = () => {
  return (
    <div className=" min-h-screen py-8 px-4  bg-white sm:px-8">
      <div className="max-w-6xl mx-auto  rounded-lg p-6">
        <div className="text-gray-700 space-y-6">
          <section className="space-y-6">
            <div className="prose prose-lg mx-auto">

              <h2 className="text-2xl font-semibold font-sans mb-4 text-center">Cancellation & Refund Policy</h2>

              <p className="font-sans mb-4">AnyFeast (“we” or “us”) operates <a href="https://www.anyfeast.com" className="text-blue-600">https://www.anyfeast.com</a> (“Website”) and our mobile applications (“App”). By placing an order through our Website or App, you agree to the terms outlined in this policy. These terms are designed to protect both our customers and AnyFeast while ensuring transparency in cancellations and refunds.</p>

              <h2 className="text-2xl font-semibold font-sans mb-4">1. Cancellation Policy</h2>
              <p className="font-sans mb-4">We understand that circumstances may change. While cancellations are generally not permitted after order confirmation, we encourage you to contact our Customer Service Team as soon as possible. We will assess cancellation requests on a case-by-case basis and accommodate them within reasonable limits.</p>

              <p className="font-sans mb-4">If an item in your order is unavailable, we will notify you using the contact details provided at the time of placing the order. In such cases, you may choose to cancel the entire order and will be entitled to a refund as per our Refund Policy.</p>

              <p className="font-sans mb-4">AnyFeast reserves the right to cancel an order under the following circumstances:</p>
              <ul className="list-disc pl-5 mb-4">
                <li>The designated delivery address falls outside our serviceable zone.</li>
                <li>We are unable to contact you via phone or email to confirm order details.</li>
                <li>Delivery cannot be completed due to insufficient information, direction, or authorization from you.</li>
                <li>All items in your order are unavailable at the time of booking.</li>
                <li>Delays or failures due to circumstances beyond our control, including but not limited to natural disasters, strikes, supply chain disruptions, government actions, or technical failures.</li>
                <li>Vendor-related issues, such as vendor cancellations, pricing errors, or inventory shortages.</li>
              </ul>

              <p className="font-sans mb-4"><strong>Legal Disclaimer:</strong> AnyFeast's decision on cancellations shall be final and binding, except where applicable consumer laws provide otherwise.</p>

              <h2 className="text-2xl font-semibold font-sans mb-4">2. Refund Policy</h2>
              <p className="font-sans mb-4">At AnyFeast, we prioritize customer satisfaction while ensuring compliance with consumer protection laws. In the event of significant delays, incorrect orders, or service issues, we will aim to resolve concerns promptly. Refunds, where applicable, will be processed within 5-7 business days to your original payment method. However, bank processing times may affect the actual crediting of the refund.</p>

              <h3 className="text-xl font-semibold mb-2">Eligibility for a Refund</h3>
              <p className="font-sans mb-4">You may be eligible for a full or partial refund if:</p>
              <ul className="list-disc pl-5 mb-4">
                <li>Your order packaging has been tampered with or damaged at the time of delivery.</li>
                <li>Your delivery location falls outside our designated service areas.</li>
                <li>We are unable to reach you to confirm your order and delivery cannot be completed.</li>
                <li>Your order is canceled at the time of confirmation due to item unavailability.</li>
                <li>Delivery cannot be completed due to insufficient information, direction, or authorization from you.</li>
                <li>The items in your order are out of stock at the time of dispatch.</li>
              </ul>

              <h3 className="text-xl font-semibold mb-2">Exclusions from Refunds</h3>
              <p className="font-sans mb-4">Refunds will not be issued in the following cases:</p>
              <ul className="list-disc pl-5 mb-4">
                <li>Change of mind or discretionary customer preference after order confirmation.</li>
                <li>Partial dissatisfaction with the taste, portion size, or preference unless a quality issue is verified by our team.</li>
                <li>Failure to store perishable items correctly after delivery, resulting in spoilage.</li>
                <li>Orders where a customer has provided an incorrect address or was unavailable to receive the delivery.</li>
                <li>Orders that fall under non-refundable promotional offers or discounts explicitly marked as non-refundable.</li>
              </ul>

              <h3 className="text-xl font-semibold mb-2">How to Request a Refund</h3>
              <p className="font-sans mb-4">To request a refund, customers must:</p>
              <ul className="list-disc pl-5 mb-4">
                <li>Contact our Customer Service Team within 24 hours of receiving the order at <a href="mailto:contact@anyfeast.com" className="text-blue-600 underline">contact@anyfeast.com</a>.</li>
                <li>Provide photo or video evidence of any damage, incorrect items, or quality concerns.</li>
                <li>Retain the original packaging until the issue is resolved.</li>
              </ul>

              <p className="font-sans mb-4">Refund requests are reviewed on a case-by-case basis, and AnyFeast reserves the right to issue refunds at its discretion in line with applicable consumer laws.</p>

              <h2 className="text-2xl font-semibold font-sans mb-4">3. Vendor & Third-Party Responsibilities</h2>
              <p className="font-sans mb-4">AnyFeast operates as a platform connecting customers with vendors. Vendors are responsible for product quality, fulfillment, and compliance with food safety standards. Any disputes regarding product quality, incorrect orders, or missing items must be first addressed directly with the vendor. AnyFeast will facilitate communication but cannot guarantee refunds for vendor-related issues unless explicitly covered under this policy.</p>

              <p className="font-sans mb-4">If a vendor is found to have consistently failed to meet quality standards, AnyFeast reserves the right to discontinue the vendor’s services on our platform.</p>

              <h2 className="text-2xl font-semibold font-sans mb-4">4. Customer Responsibilities</h2>
              <p className="font-sans mb-4">To ensure a smooth and hassle-free experience, we request customers to:</p>
              <ul className="list-disc pl-5 mb-4">
                <li>Inspect the parcel upon receipt and notify us immediately if there are issues.</li>
                <li>Store fresh and frozen items correctly to maintain product quality and avoid spoilage.</li>
                <li>Report any quality concerns within 24 hours to be eligible for refunds or replacements.</li>
                <li>Provide accurate address and contact details to avoid delivery failures.</li>
                <li>Accept deliveries in person or authorize someone to receive them on their behalf.</li>
              </ul>

              <p className="font-sans mb-4">By placing an order with AnyFeast, customers acknowledge and accept the terms of this Cancellation & Refund Policy.</p>

              <h2 className="text-2xl font-semibold font-sans mb-4">5. Contact Us</h2>
              <p className="font-sans mb-4">For any cancellation, refund, or service inquiries, please reach out to our Customer Service Team:</p>
              <p className="font-sans mb-4">
              <strong>Email:</strong> <a href="mailto:contact@anyfeast.com" className="text-blue-600 underline">contact@anyfeast.com</a>
              </p>
              <p className="font-sans mb-4"><strong>Support Hours: </strong>Monday to Friday, 9 AM - 6 PM GMT</p>
              <p className="font-sans mb-4">Response time: Within <strong>24-48 hours</strong> on business days.</p>

              <p className="font-sans mb-4">We appreciate your cooperation and look forward to serving you with the best possible experience!</p>
            </div>
          </section>

        </div>
      </div>
    </div>
  );
};

export default CancellationRefunds;
