import React, { useEffect, useState } from "react";
import Container from "../Container";
import logo from "../../../src/assets/images/logo3.png";
import cart from "../../../src/assets/images/cart.png";
import cart2 from "../../../src/assets/images/CartLogo.png";
import hamburger from "../../../src/assets/images/List.png";
import LoginModal from "./LoginModal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { getItemsByIdAsync } from "../../services/reducers/reciepeReducer";
import { Link } from "react-router-dom";
import SearchBar from "./SeachBar";
import CountryToggle from "./CountryToggle";
import MiniCart from "../ReceipeDetail/Cart_2/MiniCart";

interface HeaderProps {
  // carts: any;
}
const Header: React.FC<HeaderProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lookup, setLookup] = useState<any>({});
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const dispatch = useAppDispatch();
  const [cartTotalCount, setCartTotalCount] = useState(0);
  const accessToken = localStorage.getItem("accessToken");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const carts = useAppSelector((state) => state.receipe.itemsList);
  const location = useLocation();
  const guestId = localStorage.getItem("guestId");
  const storedCountry = localStorage.getItem("selectedCountry");

  const handleView = () => {
    navigate(`/${storedCountry}/cart`);
  };

  const [isCartOpen, setIsCartOpen] = useState(false);

  const handleViewMiniCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const handleMenu = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    getItemsCart();
  }, [guestId]);

  const storedCode = localStorage.getItem('countryCode');

  const getItemsCart = () => {
    const country = storedCode ?? '';
    if (guestId) {
      dispatch(getItemsByIdAsync({ id: guestId, country })).then((res) => {
        if (res.payload) {
          setLookup(res.payload?.data?.cart?.lookup || {});
        }
      });
    }
  };

  useEffect(() => {
    if (carts && carts.data.cart) {
      const cartCount = Object.values(carts.data.cart.lookup).flat().length;
      setCartTotalCount(cartCount);
      // console.log(cartCount, "cartCount");
    }
  }, [carts]);

  return (
    <header>
      {/* <Container> */}
      {/* <div className="flex justify-between items-center shadow-md border-b bg-white border-gray-300 p-2 fixed top-10 left-0 w-full z-50">*/}
      <div className="flex justify-between items-center shadow-md border-b  border-gray-300 p-2">
        <div className="flex justify-start items-center md:pl-5 lg:pl-5">
          <div>
            <Link to="/">

              <img src={logo} alt="logo" height={70} width={150}></img>
            </Link>
          </div>
          <div className="hidden lg:flex md:flex h-14 w-[0.1675rem] rounded-lg bg-graybg md:mx-2 mx-6"></div>
          <ul className="hidden md:block lg:block md:ml-0 ml-6 bg-graybg rounded-lg md:p-1 p-2">
            <li className="inline-block ">
              <li className="inline-block ">
                <Link
                  to={`/${storedCountry}/persona/united-family`}
                  className="inline-block px-2.5 font-sans font-medium"
                >
                  {" "}
                  Recipe{" "}
                </Link>
              </li>
            </li>
            <li className="inline-block ">
              <Link
                to={`/${storedCountry}/shop`}
                className="inline-block px-2.5 font-sans font-medium"
              >
                {" "}
                Shop{" "}
              </Link>
            </li>

            <li className="inline-block ">
              <Link
                to={`/${storedCountry}/blog`}
                className="inline-block px-2.5 font-sans font-medium"
              >
                {" "}
                Blog{" "}
              </Link>
            </li>
            <li className="inline-block ">
              <Link
                to={`/${storedCountry}/OurStory`}
                className="inline-block px-2.5 font-sans font-medium"
              >
                {" "}
                Our Story{" "}
              </Link>
            </li>

          </ul>
        </div>
        <div className="hidden md:flex lg:flex justify-end items-center md:gap-5 gap-10 mr-20 md:mr-5">
          <div className="">
            {/* <a href="#">
                <img src={search}></img>
              </a> */}
            <SearchBar />
          </div>
          <div>
            {accessToken !== null || userId ? (
              <Link to={`/${storedCountry}/profile`}>
                <span className="inline-block size-[42px] bg-gray-100 rounded-full overflow-hidden mt-1">
                  <svg
                    className="size-full text-gray-300"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.62854"
                      y="0.359985"
                      width="15"
                      height="15"
                      rx="7.5"
                      fill="white"
                    ></rect>
                    <path
                      d="M8.12421 7.20374C9.21151 7.20374 10.093 6.32229 10.093 5.23499C10.093 4.14767 9.21151 3.26624 8.12421 3.26624C7.0369 3.26624 6.15546 4.14767 6.15546 5.23499C6.15546 6.32229 7.0369 7.20374 8.12421 7.20374Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M11.818 10.5975C10.2992 12.6412 7.42106 13.0631 5.37731 11.5537C5.01171 11.2818 4.69296 10.9631 4.42107 10.5975C4.28982 10.4006 4.27107 10.1475 4.37419 9.94123L4.51482 9.65059C4.84296 8.95684 5.53671 8.51624 6.30546 8.51624H9.95231C10.7023 8.51624 11.3867 8.94749 11.7242 9.62249L11.8742 9.93184C11.968 10.1475 11.9586 10.4006 11.818 10.5975Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </Link>
            ) : (
              <button
                className="font-sans font-medium"
                onClick={() => setIsModalOpen(true)}
              >
                Login
              </button>
            )}
          </div>

          {/* <div className="relative py-2 cursor-pointer" onClick={() => handleView()}>
            <div className="absolute top-3 right-0 transform translate-x-1/3 -translate-y-1/3">
              <p className="flex items-center justify-center rounded-full bg-white border border-red-800 w-4 h-4 text-[8px] font-bold text-black md:w-5 md:h-5 md:text-xs"> */}
          {/* {accessToken !== null ? cartTotalCount : 0} {cartTotalCount} */}
          {/* </p>
            </div>


            <img src={cart} alt="cart" className="object-contain w-5 h-5 max-[640px]:w-5 max-[640px]:h-5 md:w-10 md:h-10 lg:w-10 lg:h-10 "
            ></img>
          </div> */}

          {/* rework Cart Page */}

          <div className="relative py-2 cursor-pointer" onClick={() => handleViewMiniCart()}>
            <div className="absolute top-3 right-0 transform translate-x-1/3 -translate-y-1/3">
              <p className="flex items-center justify-center rounded-full bg-white border border-red-800 w-4 h-4 text-[8px] font-bold text-black md:w-5 md:h-5 md:text-xs">
                {/* {accessToken !== null ? cartTotalCount : 0} */} {cartTotalCount}
              </p>
            </div>


            <img src={cart} alt="cart" className="object-contain w-5 h-5 max-[640px]:w-5 max-[640px]:h-5 md:w-10 md:h-10 lg:w-10 lg:h-10 "
            ></img>
            <MiniCart isOpen={isCartOpen}
              onClose={() => { setIsCartOpen(false) }}
            />
          </div>

          {location.pathname === "/" && (
            <div className="ml-2">
              <CountryToggle />
            </div>
          )}

        </div>
        {/* mobile version */}
        <div className="lg:hidden md:hidden flex justify-end items-center space-x-2">
          {/* <div className="flex items-center">
              <button onClick={() => handleView()}>
                <img src={cart} className="pr-2"></img>
              </button>
              <span className="text-black text-lg font-medium">
                {cartTotalCount}
              </span>
            </div> */}

          <div className="mr-1">
            <div className="relative py-2" onClick={() => handleView()}>
              <div className="absolute top-2 right-0 transform translate-x-1/3 -translate-y-1/3">
                <p className="flex items-center justify-center rounded-full bg-white border border-red-800 w-5 h-5 text-[8px] font-bold text-black md:w-6 md:h-6 md:text-xs">
                  {cartTotalCount}
                </p>
              </div>

              <img src={cart} alt="cart" className="max-[640px]:w-8 max-[640px]:h-8 sm:h-8 sm:w-8 "></img>
            </div>
            {/* <div><CountryToggle/></div> */}
          </div>
          <div className="mr-1">
            {accessToken !== null ? (
              <Link to={`/${storedCountry}/profile`}>
                <span className="lg:hidden md:hidden inline-block size-[42px] bg-gray-100 rounded-full overflow-hidden mt-1">
                  <svg
                    className="size-full text-gray-300"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.62854"
                      y="0.359985"
                      width="15"
                      height="15"
                      rx="7.5"
                      fill="white"
                    ></rect>
                    <path
                      d="M8.12421 7.20374C9.21151 7.20374 10.093 6.32229 10.093 5.23499C10.093 4.14767 9.21151 3.26624 8.12421 3.26624C7.0369 3.26624 6.15546 4.14767 6.15546 5.23499C6.15546 6.32229 7.0369 7.20374 8.12421 7.20374Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M11.818 10.5975C10.2992 12.6412 7.42106 13.0631 5.37731 11.5537C5.01171 11.2818 4.69296 10.9631 4.42107 10.5975C4.28982 10.4006 4.27107 10.1475 4.37419 9.94123L4.51482 9.65059C4.84296 8.95684 5.53671 8.51624 6.30546 8.51624H9.95231C10.7023 8.51624 11.3867 8.94749 11.7242 9.62249L11.8742 9.93184C11.968 10.1475 11.9586 10.4006 11.818 10.5975Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </Link>
            ) : (
              <button
                className="font-sans font-medium"
                onClick={() => setIsModalOpen(true)}
              >
                Login
              </button>
            )}
          </div>
          {location.pathname === "/" && (
            <div className="">
              <CountryToggle />
            </div>
          )}
          <div className="cursor-pointer">
            <span onClick={() => handleMenu()}>
              <img src={hamburger} alt="menu" width={30}></img>
            </span>
          </div>
        </div>
        {isModalOpen && (
          <LoginModal
            onClose={() => {
              setIsModalOpen(false);
            }}
            isOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
        {isDrawerOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex flex-col-2 justify-between bg-white z-50 transition-transform duration-300 ease-in-out transform translate-y-0 p-4 shadow-lg border-b border-red">
            <div className="">
              <Link to="/" onClick={handleMenu}>
                <img src={logo} alt="logo" height={50} width={150}></img>
              </Link>
              <ul className="space-y-4 mt-5 ml-5">
                <li>
                  <Link
                    to={`/${storedCountry}/persona/united-family`}
                    className="block text-lg"
                    onClick={handleMenu}
                  >
                    Recipe
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${storedCountry}/shop`}
                    className="block text-lg"
                    onClick={handleMenu}
                  >
                    Shop
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${storedCountry}/blog`}
                    className="block text-lg"
                    onClick={handleMenu}
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <a href={`/${storedCountry}/OurStory`} className="block text-lg" onClick={handleMenu}>
                    Our Story
                  </a>
                </li>
              </ul>
            </div>
            <button
              className="self-start m-2 text-right"
              onClick={handleMenu}
            >
              <span className="text-2xl font-bold">x</span>
            </button>
          </div>
        )}
      </div>
      {!isDrawerOpen && (
        <div className="lg:hidden md:hidden relative z-50 block w-full p-4">
          <SearchBar />
        </div>
      )}
      {/* </div>*/}
      {/* </Container> */}
    </header>
  );
};

export default Header;
