import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import im1 from "../../../assets/images/cross.png";
import { getOrderByUserIdAsync } from "../../../services/reducers/reciepeReducer";
import { useAppDispatch } from "../../../hooks";

interface ConfirmRefundPopupProps {
  onClose: () => void;
  onConfirm: () => void;
  total: number | null;
}

const ConfirmRefundPopup: React.FC<ConfirmRefundPopupProps> = ({
  onClose,
  onConfirm,
  total,
}) => {
  const navigate = useNavigate();
  const storedCode = localStorage.getItem("countryCode");
  const { id } = useParams();

  const [getOrders, setGetOrders] = useState<any>([]);
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId");

 useEffect(() => {
    if (userId) {
      dispatch(getOrderByUserIdAsync(userId)).then((res) => {
        if (res.payload) {
          setGetOrders(res.payload?.data);
        }
      });
    }
  }, [userId]);

  const storedCountry = localStorage.getItem("selectedCountry");


  const handleConfirm = () => {
    onConfirm();
    // navigate('/profile');
    navigate(`/${storedCountry}/OrderCancelled/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 px-4">
      <div className="bg-white p-6 rounded-2xl shadow-lg w-full max-w-md relative">
        <div className="flex justify-between items-center -ml-6 -mr-6 -mt-6 p-3 bg-[#FAFCFE] rounded-lg">
          <h2 className="text-xl font-bold font-sans text-black ml-4">
            Confirm Refund
          </h2>
          <button onClick={onClose}>
            <img src={im1} alt="Close" className="w-4 h-4 mr-2" />
          </button>
        </div>
        <p className="text-greytext font-dm-sans mb-6 text-sm md:text-base text-center">
          You will receive a refund of{" "}

          {getOrders
                .filter((order: any) => order.id === parseInt(id ?? "", 10))
                .map((order: any) =>

          <span className="text-green">
          {order?.currency === "INR" ? "₹" : "£"}{order?.order_total}
          </span>
          )}
          {" "}within five business days via the same UPI method used for your
          original payment.
        </p>

        <div className="flex flex-col md:flex-row justify-between space-y-3 md:space-y-0 md:space-x-4">
          <button
            className="border border-green text-green hover:bg-green hover:text-white w-full md:flex-1 px-4 py-2 rounded-lg text-sm md:text-base font-dm-sans font-medium"
            onClick={onClose}
            aria-label="Cancel"
          >
            Cancel
          </button>
          <button
            className="bg-green hover:bg-green-hover text-white w-full md:flex-1 px-4 py-2 rounded-lg text-sm md:text-base font-dm-sans font-medium"
            onClick={handleConfirm}
            aria-label="Confirm Refund"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRefundPopup;
