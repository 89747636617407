import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ShopCuisineRedirect = () => {
  const { countryCode, cuisine } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!cuisine) {
      navigate(`/${countryCode}/shop/cuisine/indian`, { replace: true });
    }
  }, [cuisine, countryCode, navigate]);

  return null;
};

export default ShopCuisineRedirect;
