import React, { useEffect, useState } from "react";
import ReceipeKit from "../Home/ReceipeKit";
import { getRecipeByCuisineAsync } from "../../services/reducers/reciepeReducer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import CuisineSlider from "../Home/SwiperSlider";

const RecipeCuisine = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [cuisineDetails, setCuisineDetails] = useState<any>([]);
  const [displayedIds, setDisplayedIds] = useState<string[]>([]);
  const storedCode = localStorage.getItem("countryCode");
  const storedCountry = localStorage.getItem("selectedCountry");
  const cuisine = location.state?.cuisineName;


  useEffect(() => {
    if (cuisine) {
      getCuisineRecipes();
    } else {
      navigate(`/${storedCountry}/404`); 
    }
  }, [cuisine, navigate]);


  const getCuisineRecipes = () => {
    const request = {
      country: storedCode,
      cuisine: cuisine,
      page: 1,
      pageSize: 500,
    };
    dispatch(getRecipeByCuisineAsync(request)).then((res) => {
      // console.log("Cuisine ", res.payload);
      if (res.payload) {
        setCuisineDetails(res.payload);
        if (Array.isArray(res.payload?.data)) {
          const ids = res.payload?.data.map((item: any) => item.id);
          setDisplayedIds(ids);
        } else {
          console.error(
            "Recipes array is missing or not an array:",
            res.payload?.data
          );
        }
      }
      else {
        navigate(`/${storedCountry}/404`); 
      }

    });
  };
 //  console.log("Cuisine Recipe", cuisineDetails);

  return (
    <div className="overflow-y bg-graybg">
      
      <div>
        <CuisineSlider/>
      </div>

      <div className="px-5 md:px-10 lg:px-10 text-3xl font-semibold">
      {cuisine?.charAt(0).toUpperCase() + cuisine?.slice(1)} Recipe Kits
      </div>

     <div className="pt-4">
     <ReceipeKit
        type={"Cuisine"}
        cuisineDetails={cuisineDetails}
        displayedIds={displayedIds}
      />
     </div>
    </div>
  );
};

export default RecipeCuisine;
