import axios from "axios";
import { API_URL } from "./constants";

interface VerifyOtpResponse {
  data: {
    token: string;
    id: string;
    phone: string;
    email: string;
  };
}
// interface VerifyUserResponse {
//   message: string;
// }
// interface VerifyUserParams {
//   emailAddress?: string;
//   phoneNumber?: string;
// }

export const sendOTP = async (phone: string, os: string): Promise<void> => {
  const response = await axios.post(
    `${API_URL.INTEGRATION_API_URL}auth/gen-otp`,
    {
      phone,
      os
    }
  );
  return response.data;
};

export const verifyOtp = async (
  phone: string,
  otp: string
): Promise<VerifyOtpResponse> => {
  try {
    const response = await axios.post(
      `${API_URL.INTEGRATION_API_URL}auth/verify-otp`,
      {
        phone,
        otp,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying OTP:", error);
    throw error;
  }
};

export const emailConfig = async (
  email: string,
  name: string
): Promise<VerifyOtpResponse> => {
  try {
    const response = await axios.post(
      `${API_URL.INTEGRATION_API_URL}auth/social-login`,
      {
        email,
        name,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error emailConfig:", error);
    throw error;
  }
};

export const sendOTPEmail = async (email: string): Promise<void> => {
  const response = await axios.post(
    `${API_URL.INTEGRATION_API_URL}auth/gen-email-otp`,
    {
      email,
    }
  );
  return response.data;
};

export const verifyOtpEmail = async (
  email: string,
  otp: string
): Promise<VerifyOtpResponse> => {
  try {
    const response = await axios.post(
      `${API_URL.INTEGRATION_API_URL}auth/verify-email-otp`,
      {
        email,
        otp,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying OTP:", error);
    throw error;
  }
};
export const createCod = async (orderId: string): Promise<void> => {
  const response = await axios.post(
    `${API_URL.INTEGRATION_API_URL}order/cod`,
    {
      orderId
    }
  );
  return response.data;
};


